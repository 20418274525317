function ProgressBar({ progress }) {
    const Parentdiv = {
        height: '8px',
        width: '100%',
        backgroundColor: '#e9ebec',
        borderRadius: 12,
    }

    const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: '#6FDA44',
        borderRadius: 12,
        textAlign: 'right',
    }

    const progresstext = {
        padding: 10,
        color: 'black',
        fontWeight: 900,
    }

    return (
        <div style={Parentdiv}>
            <div style={Childdiv}>
                <span style={progresstext} />
            </div>
        </div>
    )
}

export default ProgressBar
