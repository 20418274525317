export const inputStyle = {
    borderRadius: '7px',
    Input: {
        '&::placeholder': {
            textOverflow: 'ellipsis !important',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '21px',
            color: '#AAAAAA',
        },
    },
    backgroundColor: '#F6F6F6',
}

export const required = {
    value: true,
    message: 'This field is required',
}

export const buttonStyle = {
    borderRadius: '7px',
    transition: '1s',
    fontSize: { xs: '14px', sm: '14px' },
    fontWeight: 600,
    letterSpacing: '0.2px',
    textTransform: 'none',
    border: '1px solid #2A3538',
    color: '#192022',
    backgroundColor: 'white',
    '&:hover': {
        backgroundColor: '#6FDA44',
    },
    width: '100%',
    maxWidth: '200px',
}

export const sorting = [
    'Price (low to high)',
    'Price (high to low)',
    'Date (newest)',
    'Date (oldest)',
]

export const filterOptions = [
    { label: 'All items', value: 'all' },
    { label: 'Video', value: 'video' },
    { label: 'Audio', value: 'audio' },
    { label: 'Original', value: 'original' },
    { label: 'Resale', value: 'resale' },
]
