import { useCallback, useContext, useEffect, useState } from 'react'
import { AppContext } from 'src/Context'

import { get } from '../utils/fetchApis'

function useUserList({ pagination }) {
    const [userList, setUserList] = useState([])
    const [count, setCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const { setAlertState } = useContext(AppContext)

    const getUserList = useCallback(async () => {
        const { page, size, search } = pagination

        setIsLoading(true)
        const { data, success, message } = await get('users', {
            page,
            size,
            search,
        })

        if (!success) {
            setAlertState({
                open: true,
                message,
                serverity: 'error',
            })
            return
        }

        setCount(data.totalResults)
        setUserList(data.items)
        setIsLoading(false)
    }, [])

    useEffect(() => {
        getUserList()
    }, [])

    const refetch = () => {
        getUserList()
    }

    return { userList, count, refetch, isLoading }
}

export default useUserList
