/* eslint-disable camelcase */
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Box, Container, Grid, Hidden, Stack, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logoimg from '../assets/logoimg.png'
import CustomInput from '../components/CustomInput'
import { GreenButton } from '../components/Styled'
import useDocumentTitle from '../useDocumentTitle'
import { secureToken, DEV_MODE } from '../utils/constant'
import { post } from '../utils/fetchApis'
import { setStorage, getStorage } from '../utils/helper'
import { AppContext } from '../Context'

export default function SignUp() {
    const matches = useMediaQuery('(max-width:600px)')
    useDocumentTitle(
        'View exclusive NFT protected content | Find files from upcoming artists'
    )
    const navigate = useNavigate()
    const { setAlertState } = useContext(AppContext)

    const [signupData, setSignupData] = useState({
        fname: '',
        lname: '',
        bname: '',
        email: '',
        phone: '',
        address: '',
        password: '',
        cpassword: '',
    })

    const handleSignUpChange = useCallback(
        (event) => {
            setSignupData({
                ...signupData,
                [event.target.name]: event.target.value,
            })
        },
        [signupData]
    )

    const handleBack = useCallback(() => {
        navigate('/')
    }, [navigate])

    const alertFunction = (data) => {
        if (data?.status === '200') {
            return setAlertState({
                open: true,
                message: data?.message,
                severity: 'success',
            })
        } else {
            return setAlertState({
                open: true,
                message: data?.error,
                severity: 'error',
            })
        }
    }

    const handleSignUpSubmit = useCallback(
        async (e) => {
            try {
                e.preventDefault()
                if (
                    signupData?.password !== signupData?.cpassword ||
                    signupData?.password.length < 6
                )
                    return setAlertState({
                        open: true,
                        message:
                            signupData?.password.length < 6
                                ? 'password should be greater than 5 digits'
                                : 'Password does not matched',
                        severity: 'error',
                    })

                const { success, data, message } = await post('auth/register', {
                    f_name: signupData?.fname,
                    l_name: signupData?.lname,
                    email: signupData?.email,
                    phone: signupData?.phone,
                    address: signupData?.address,
                    password: signupData?.password,
                    business_name: signupData?.bname,
                    role: 'user',
                })

                if (!success) {
                    setAlertState({
                        open: false,
                        message,
                        severity: 'error',
                    })
                }

                setStorage('token', data.access_token)
                setSignupData({
                    fname: '',
                    lname: '',
                    bname: '',
                    email: '',
                    phone: '',
                    address: '',
                    password: '',
                    cpassword: '',
                })
                navigate('/login')
            } catch (e) {
                setAlertState({
                    open: true,
                    message: 'Something wrong happened while signing up!',
                    severity: 'error',
                })
                console.error(e)
            }
        },
        [signupData]
    )

    return (
        <Container>
            <Box
                sx={{
                    '& .css-k3rfoq-MuiPaper-root-MuiDialog-paper': {
                        borderRadius: '15px',
                    },
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        justifyContent: 'center',
                        display: 'flex',
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: '100%', md: '50%' },
                            m: { xs: 0, md: 1 },
                        }}
                    >
                        <Hidden mdUp>
                            <Box
                                display="flex"
                                mt={1}
                                sx={{ cursor: 'pointer' }}
                                onClick={handleBack}
                            >
                                <ChevronLeftIcon />
                                <Typography fontWeight="bold">back</Typography>
                            </Box>
                        </Hidden>
                        <Box
                            mx={4}
                            mt={4}
                            mb={3}
                            component="form"
                            onSubmit={handleSignUpSubmit}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={logoimg}
                                alt="logoimg"
                                srcSet=""
                                style={{
                                    width: '100%',
                                    maxWidth: matches ? '100px' : '150px',
                                }}
                            />
                            <Typography
                                variant="h5"
                                fontWeight="600"
                                align="center"
                            >
                                Create Account
                            </Typography>
                            <Typography
                                align="center"
                                fontWeight={'400 !important'}
                            >
                                Connect with your audience through NFT`s!
                            </Typography>
                            <Grid container spacing={2} mt={2}>
                                <Grid item xs={12} sm={6}>
                                    <CustomInput
                                        onChange={(e) => handleSignUpChange(e)}
                                        name="fname"
                                        placeholder="First Name*"
                                        type="text"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomInput
                                        onChange={(e) => handleSignUpChange(e)}
                                        name="lname"
                                        placeholder="Last Name*"
                                        type="text"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <CustomInput
                                        onChange={(e) => handleSignUpChange(e)}
                                        name="bname"
                                        placeholder="Business Name*"
                                        required
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomInput
                                        onChange={(e) => handleSignUpChange(e)}
                                        name="email"
                                        placeholder="Email*"
                                        required
                                        type="email"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomInput
                                        onChange={(e) => handleSignUpChange(e)}
                                        name="phone"
                                        placeholder="Phone*"
                                        required
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <CustomInput
                                        onChange={(e) => handleSignUpChange(e)}
                                        name="address"
                                        placeholder="Address*"
                                        required
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomInput
                                        onChange={(e) => handleSignUpChange(e)}
                                        name="password"
                                        placeholder="Password*"
                                        required
                                        type="password"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomInput
                                        onChange={(e) => handleSignUpChange(e)}
                                        name="cpassword"
                                        placeholder="Confirm Password*"
                                        required
                                        type="password"
                                    />
                                </Grid>
                            </Grid>
                            <GreenButton
                                type="submit"
                                sx={{ width: '100%', my: 3, maxWidth: '100%' }}
                            >
                                join us
                            </GreenButton>

                            <Typography
                                align="center"
                                fontWeight={'400 !important'}
                            >
                                Already have an account?
                            </Typography>
                            <Typography
                                color="primary"
                                align="center"
                                sx={{
                                    cursor: 'pointer',
                                    fontWeight: '400 !important',
                                }}
                                onClick={() => navigate('/login')}
                            >
                                Login
                            </Typography>
                        </Box>
                    </Box>
                </Stack>
            </Box>
        </Container>
    )
}
