import { useCallback, useContext, useEffect, useState } from 'react'

import { mintContractAddr } from '../connectivityAssets/mintContract/addresses'
import {
    DEV_MODE,
    NFT_ROYALTY_PERCENT,
    NFT_STATUS,
    ORDER_STATUS,
} from '../utils/constant'
import { get, post } from '../utils/fetchApis'

function useSaleNfts(pagination, walletId) {
    const [saleNfts, setSaleNfts] = useState<any>([])
    const [loading, setLoading] = useState(true)
    const [hasMore, setHasMore] = useState(false)

    const getSaleNfts = useCallback(async () => {
        const {
            page,
            size,
            keyword,
            filterBy,
            sortBy,
            orderBy,
            priceFrom,
            priceTo,
        } = pagination

        const { success, data, message } = await get('nfts', {
            status: NFT_STATUS.SALE,
            contract_address: mintContractAddr,
            page,
            size,
            search: keyword ?? '',
            filterBy: filterBy ?? '',
            sortBy: sortBy ?? '',
            orderBy: orderBy ?? '',
            priceFrom: priceFrom ?? '',
            priceTo: priceTo ?? '',
            walletId,
        })

        if (!success) {
            return
        }

        if (data.items.length) {
            const nfts = data.items.map((nft) => {
                const soldOrders =
                    nft.orders &&
                    nft.orders.filter((it) => it.status === ORDER_STATUS.SOLD)
                return {
                    ...nft,
                    order: nft.orders[0],
                    ...(soldOrders &&
                        soldOrders.length && {
                            royalty: soldOrders.reduce(
                                (a, b) =>
                                    Number(a) +
                                    Number(b.price) * NFT_ROYALTY_PERCENT,
                                0
                            ),
                        }),
                }
            })

            const nftList = page === 1 ? nfts : [...saleNfts, ...nfts]
            setSaleNfts(nftList)
            setHasMore(data.hasMore)
            setLoading(false)
        } else {
            setSaleNfts([])
            setHasMore(false)
            setLoading(false)
        }
    }, [pagination, walletId])

    useEffect(() => {
        getSaleNfts()
    }, [pagination, walletId])

    const refetch = () => {
        getSaleNfts()
    }

    return { saleNfts, hasMore, loading, execute: refetch }
}

export default useSaleNfts
