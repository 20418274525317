import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, SvgIcon, Typography } from '@mui/material'
import { useContext } from 'react'
import Modal from 'react-modal'

import { AppContext } from '../../Context'

const WalletConnectAlertModal = ({ open, onCheck }) => {
    const { isMobile, isAlertModal, setAlertModal } = useContext(AppContext)

    const modalStyles = {
        overlay: {
            backgroundColor: 'transparent',
            zIndex: 100,
        },
        content: {
            top: 'auto',
            left: 'auto',
            right: isMobile ? '0%' : '10px',
            bottom: isMobile ? '0%' : '10px',
            margin: isMobile ? '0 10px 10px 10px' : '',
            // transform: "translate(-50%, -50%)",
            maxWidth: '600px',
            backgroundColor: '#ffffff',
        },
    }

    const walletIconStyle = {
        position: 'absolute',
        borderRadius: '100%',
        borderWidth: 2,
        backgroundColor: 'white',
    }

    Modal.setAppElement('#root')

    return (
        <Modal
            isOpen={isAlertModal}
            onRequestClose={(_) => setAlertModal(false)}
            style={modalStyles}
            shouldCloseOnOverlayClick={false}
        >
            <Box className="modal-notify__body">
                <Box
                    onClick={(_) => setAlertModal(false)}
                    borderRadius="100px"
                    sx={{
                        position: 'absolute',
                        cursor: 'pointer',
                        right: 0,
                        top: 0,
                    }}
                >
                    <CloseIcon />
                </Box>
                <Box position="relative" height={40}>
                    <Box sx={{ ...walletIconStyle, zIndex: 3 }}>
                        <SvgIcon style={{ fontSize: 40 }}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="50"
                                height="48"
                                fill="none"
                                viewBox="0 0 50 48"
                                id="metamask"
                            >
                                <path
                                    fill="#E2761B"
                                    stroke="#E2761B"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M46.6094 2L27.88 15.9106L31.3435 7.70353L46.6094 2Z"
                                ></path>
                                <path
                                    fill="#E4761B"
                                    stroke="#E4761B"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M3.37177 2L21.9506 16.0424 18.6565 7.70353 3.37177 2zM39.8706 34.2447L34.8824 41.887 45.5553 44.8235 48.6235 34.4141 39.8706 34.2447zM1.39529 34.4141L4.44471 44.8235 15.1176 41.887 10.1294 34.2447 1.39529 34.4141z"
                                ></path>
                                <path
                                    fill="#E4761B"
                                    stroke="#E4761B"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M14.5153 21.3318L11.5412 25.8306 22.1388 26.3012 21.7624 14.913 14.5153 21.3318zM35.4659 21.3317L28.1247 14.7812 27.88 26.3012 38.4588 25.8306 35.4659 21.3317zM15.1176 41.8871L21.48 38.7812 15.9835 34.4894 15.1176 41.8871zM28.5012 38.7812L34.8824 41.8871 33.9976 34.4894 28.5012 38.7812z"
                                ></path>
                                <path
                                    fill="#D7C1B3"
                                    stroke="#D7C1B3"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M34.8823 41.8871L28.5012 38.7812 29.0094 42.9412 28.9529 44.6918 34.8823 41.8871zM15.1176 41.887L21.0471 44.6917 21.0094 42.9412 21.48 38.7812 15.1176 41.887z"
                                ></path>
                                <path
                                    fill="#233447"
                                    stroke="#233447"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M21.1412 31.7412L15.8329 30.1788 19.5788 28.4659 21.1412 31.7412zM28.84 31.7412L30.4024 28.4659 34.1671 30.1788 28.84 31.7412z"
                                ></path>
                                <path
                                    fill="#CD6116"
                                    stroke="#CD6116"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15.1176 41.8872L16.0212 34.2448 10.1294 34.4143 15.1176 41.8872zM33.9788 34.2448L34.8824 41.8872 39.8706 34.4143 33.9788 34.2448zM38.4588 25.8307L27.88 26.3013 28.8588 31.7413 30.4212 28.466 34.1859 30.1789 38.4588 25.8307zM15.8329 30.1789L19.5977 28.466 21.1412 31.7413 22.1388 26.3013 11.5412 25.8307 15.8329 30.1789z"
                                ></path>
                                <path
                                    fill="#E4751F"
                                    stroke="#E4751F"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M11.5412 25.8306L15.9835 34.4895 15.8329 30.1789 11.5412 25.8306zM34.1859 30.1789L33.9976 34.4895 38.4588 25.8307 34.1859 30.1789zM22.1388 26.3013L21.1412 31.7413 22.3835 38.1601 22.6659 29.7083 22.1388 26.3013zM27.88 26.3013L27.3717 29.6895 27.5976 38.1601 28.8588 31.7413 27.88 26.3013z"
                                ></path>
                                <path
                                    fill="#F6851B"
                                    stroke="#F6851B"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M28.8588 31.7412L27.5976 38.16 28.5012 38.7812 33.9976 34.4895 34.1859 30.1789 28.8588 31.7412zM15.8329 30.1789L15.9835 34.4895 21.48 38.7812 22.3835 38.16 21.1412 31.7412 15.8329 30.1789z"
                                ></path>
                                <path
                                    fill="#C0AD9E"
                                    stroke="#C0AD9E"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M28.9529 44.6918L29.0094 42.9412L28.5388 42.5271H21.4424L21.0094 42.9412L21.0471 44.6918L15.1176 41.8871L17.1882 43.5812L21.3859 46.4988H28.5953L32.8118 43.5812L34.8824 41.8871L28.9529 44.6918Z"
                                ></path>
                                <path
                                    fill="#161616"
                                    stroke="#161616"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M28.5012 38.7812L27.5977 38.16H22.3835L21.48 38.7812L21.0094 42.9412L21.4424 42.5271H28.5388L29.0094 42.9412L28.5012 38.7812Z"
                                ></path>
                                <path
                                    fill="#763D16"
                                    stroke="#763D16"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M47.4 16.8141L49 9.13412 46.6094 2 28.5012 15.44 35.4659 21.3318 45.3106 24.2118 47.4941 21.6706 46.5529 20.9929 48.0588 19.6188 46.8918 18.7153 48.3976 17.5671 47.4 16.8141zM1 9.13412L2.6 16.8141 1.58353 17.5671 3.08941 18.7153 1.94118 19.6188 3.44706 20.9929 2.50588 21.6706 4.67059 24.2118 14.5153 21.3318 21.48 15.44 3.37177 2 1 9.13412z"
                                ></path>
                                <path
                                    fill="#F6851B"
                                    stroke="#F6851B"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M45.3106 24.2118L35.4659 21.3318 38.4588 25.8306 33.9977 34.4894 39.8706 34.4141H48.6235L45.3106 24.2118zM14.5153 21.3318L4.6706 24.2118 1.39531 34.4141H10.1294L15.9835 34.4894 11.5412 25.8306 14.5153 21.3318zM27.88 26.3011L28.5012 15.44 31.3623 7.70349H18.6565L21.48 15.44 22.1388 26.3011 22.3647 29.727 22.3835 38.16H27.5976L27.6353 29.727 27.88 26.3011z"
                                ></path>
                            </svg>
                        </SvgIcon>
                    </Box>
                    <Box sx={{ ...walletIconStyle, zIndex: 2, left: 30 }}>
                        <SvgIcon sx={{ fontSize: 40 }}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="48"
                                height="48"
                                fill="none"
                                viewBox="0 0 48 48"
                                id="coinbase"
                            >
                                <path
                                    fill="#0052FF"
                                    d="M0 11.0769C0 4.95931 4.95931 0 11.0769 0H36.9231C43.0407 0 48 4.95931 48 11.0769V36.9231C48 43.0407 43.0407 48 36.9231 48H11.0769C4.95931 48 0 43.0407 0 36.9231V11.0769Z"
                                ></path>
                                <path
                                    fill="#fff"
                                    d="M23.9548 33C22.2558 32.9657 20.601 32.4534 19.181 31.5223C17.761 30.5911 16.6335 29.2788 15.9283 27.7365C15.2232 26.1943 14.969 24.4847 15.195 22.8047C15.4211 21.1246 16.1182 19.5424 17.2061 18.2402C18.294 16.938 19.7285 15.9687 21.3444 15.444C22.9602 14.9192 24.6918 14.8603 26.3398 15.2741C27.9878 15.6879 29.4851 16.5574 30.6594 17.7827C31.8337 19.008 32.6372 20.5392 32.9774 22.2H42C41.5371 17.6053 39.3215 13.3637 35.8114 10.3527C32.3014 7.34165 27.7655 5.79141 23.1418 6.02259C18.5181 6.25377 14.1604 8.2487 10.9698 11.5948C7.77927 14.9409 6 19.3821 6 24C6 28.6179 7.77927 33.0591 10.9698 36.4052C14.1604 39.7513 18.5181 41.7462 23.1418 41.9774C27.7655 42.2086 32.3014 40.6584 35.8114 37.6473C39.3215 34.6363 41.5371 30.3947 42 25.8H32.9774C32.9774 29.4 27.5638 33 23.9548 33Z"
                                ></path>
                            </svg>
                        </SvgIcon>
                    </Box>
                    <Box sx={{ ...walletIconStyle, zIndex: 1, left: 60 }}>
                        <SvgIcon sx={{ fontSize: 40 }}>
                            <svg
                                height="227"
                                viewBox="0 0 227 227"
                                width="227"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="m49.795 77.044v72.556l63.48 36.278 63.346-36.278v-72.556l-63.346-36.233zm57.955 74.114-11.816 10.95h-9.973l-25.023-43.088 18.329-13.531 16.219 10.238v18.339l12.265 11.661v5.431zm-8.087-18.206 1.842-17.226-5.975-15.446h35.491l-5.841 15.446 1.707 17.182zm40.882 29.023h-9.839l-11.815-10.772-.045-5.519 12.221-11.618v-18.339l16.037-10.373 18.285 13.666zm6.559-65.879-67.612-.045 6.289-27.598h54.72z" />
                                <path d="m113.5 0c-62.684 0-113.5 50.816-113.5 113.5s50.816 113.5 113.5 113.5 113.5-50.816 113.5-113.5-50.816-113.5-113.5-113.5zm69.5 153.25-69.725 39.75-69.275-39.75v-79.5l69.275-39.75 69.725 39.75z" />
                            </svg>
                        </SvgIcon>
                    </Box>
                </Box>
                <Typography
                    variant="h3"
                    my={isMobile ? 1 : 3}
                    style={{
                        fontSize: isMobile ? '20px' : '24px',
                    }}
                >
                    Connect Wallet
                </Typography>

                <Typography
                    variant="body1"
                    style={{
                        fontSize: isMobile ? '14px' : '16px',
                    }}
                >
                    If you are viewing this site on a mobile device, please open
                    the app and access the website through the wallet browser.
                    If you are using a desktop, ensure that the wallet is
                    installed and connected to your Chrome browser.
                </Typography>
                <Button
                    style={{
                        color: '#2A3538',
                        fontSize: '14px',
                        fontWeight: 500,
                        marginTop: isMobile ? '12px' : '24px',
                        borderColor: '#2A3538',
                        width: isMobile ? '100%' : '',
                    }}
                    variant="outlined"
                    onClick={() => {}}
                >
                    Learn more
                </Button>
            </Box>
        </Modal>
    )
}

export default WalletConnectAlertModal
