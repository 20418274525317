import React, { useCallback, useEffect, useState } from 'react'

import {
    Box,
    Button,
    Paper,
    styled,
    Typography,
    useMediaQuery,
} from '@mui/material'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { ethers } from 'ethers'

import mintABI from '../connectivityAssets/mintContract/mintABI.json'

import icon from '../assets/Ticon.png'
import { useAccount, useSigner } from 'wagmi'
import { mintContractAddr } from '../connectivityAssets/mintContract/addresses'
import { getEthersProvider, getEthersSigner } from '../utils/ether'
import { config } from '../utils/wagiConfig'
import { GC_BUCKET_URL } from '../utils/constant'

const MyButton = styled(Button)({
    backgroundColor: '#6FDA44',
    color: 'white',
    borderRadius: '0px 0px 10px 10px',
    width: '100%',
    '&:hover': {
        backgroundColor: '#6FDA44',
    },
    fontSize: '16px',
    fontWeight: '700',
})

const NftCard = ({ nft, hot }) => {
    const { isConnected, address } = useAccount()
    const [nftOwner, setNftOwner] = useState()
    const matches = useMediaQuery('(max-width:900px)')

    const getNftOwner = useCallback(async () => {
        try {
            const provider = await getEthersProvider(config)
            const contract = new ethers.Contract(
                mintContractAddr,
                mintABI,
                provider
            )
            const nftInfo = await contract.getNFtInfo(nft?.tokenId)
            setNftOwner(nftInfo._owner)
        } catch (error) {
            // console.log(error);
        }
    }, [nft?.tokenId])

    useEffect(() => {
        if (address) {
            getNftOwner()
        }
    }, [getNftOwner, address])

    return (
        <Paper
            elevation={3}
            sx={{
                borderRadius: '10px',
                background: '#FFFFF',
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            {hot && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: -30,
                        background: '#6FDA44',

                        width: '120px',
                        transform: 'rotate(40deg)',
                        zIndex: 1000,
                        py: '3px',
                    }}
                >
                    {' '}
                    <Typography
                        textAlign={'center'}
                        ml="12px"
                        color={'white'}
                        fontWeight={'700'}
                    >
                        HOT
                    </Typography>{' '}
                </Box>
            )}

            <img
                src={`${GC_BUCKET_URL}${nft?.image}`}
                alt="nft"
                style={{ width: '100%', height: matches ? '150px' : '250px' }}
            />

            <Box p={{ xs: '10px', md: '20px' }}>
                <Typography
                    fontSize={{
                        xs: '15px',
                        md: '20px',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                    }}
                    fontWeight={'600'}
                >
                    {nft?.title}
                </Typography>
                <Box mt="10px" display="flex" alignItems={'center'}>
                    <img src={icon} width="35px" alt="seen icon" />
                    <Typography
                        fontSize={{ xs: '14px', md: '18px' }}
                        fontWeight={'600'}
                        px={'10px'}
                    >
                        {nft?.price}
                    </Typography>
                    <Typography fontSize={{ xs: '12px', md: '16px' }}>
                        USDT
                    </Typography>
                </Box>
                <Box display="flex" gap={2} mt={2}>
                    <Typography>Type:</Typography>
                    <Typography fontWeight="bold">{nft?.fileType}</Typography>
                </Box>
                {/* <Box mt="10px" display={"flex"} columnGap="10px" alignItems={"center"}>
          {catagories.map((item, i) => (
            <Box
              key={i}
              sx={{
                background: "#F6F6F6",
                borderRadius: "5px",
                width: "91px",
                py: "8px",
                textAlign: "center",
                transition: "0.5s ease-in-out",
                "&:hover": {
                  background: "#6FDA44",
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "14px", md: "16px" },
                  color: "black",
                }}
              >
                {item}
              </Typography>
            </Box>
          ))}
        </Box> */}
            </Box>

            {isConnected ? (
                <>
                    <>
                        {nftOwner ? (
                            <>
                                {address === nftOwner ? (
                                    <MyButton my={2}>You own this NFT</MyButton>
                                ) : (
                                    <MyButton
                                        my={2}
                                        fontSize={{ xs: '13px', md: '18px' }}
                                    >
                                        Buy Now{' '}
                                        <AddShoppingCartIcon
                                            sx={{ color: 'white', ml: '5px' }}
                                        />
                                    </MyButton>
                                )}
                            </>
                        ) : (
                            <>
                                {' '}
                                <MyButton
                                    my={2}
                                    fontSize={{ xs: '13px', md: '18px' }}
                                >
                                    Buy Now{' '}
                                    <AddShoppingCartIcon
                                        sx={{ color: 'white', ml: '5px' }}
                                    />
                                </MyButton>{' '}
                            </>
                        )}
                    </>
                </>
            ) : (
                <MyButton my={2} fontSize={{ xs: '13px', md: '18px' }}>
                    Buy Now{' '}
                    <AddShoppingCartIcon sx={{ color: 'white', ml: '5px' }} />
                </MyButton>
            )}
        </Paper>
    )
}

export default NftCard
