import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import { Box, Button, Container, Typography } from '@mui/material'
import NftAdvertiseImage2 from '../assets/investor-with-laptop.jpg'
import NftAdvertiseImage1 from '../assets/manage-money-concept-illustration.jpg'
import NftAdvertiseImage from '../assets/sponsor-concept-illustration.jpg'
import NftAdvertiseImage3 from '../assets/youtube-tutorial-concept-illustration.jpg'
import { useNavigate } from 'react-router-dom'

const ContentData = [
    {
        title: 'Receive greater share of profits',
        description:
            'When someone purchases one of the NFT that shows your content, you will get a large percentage of the profits than from any streaming service. 90% of all profits are kept by you. You do not need to have a crypto wallet to receive payment as we can send payment to a valid PayPal account. ',
        imageUrl: NftAdvertiseImage1,
    },
    {
        title: 'Receive commission from whenever your content is sold',
        description:
            'Even after you have sold an NFT, you can still get commission. This will happen whenever your NFT is resold again. So even if your content is sold 100 times you will receive commission, or a royalty paid to you. ',
        imageUrl: NftAdvertiseImage2,
    },
    {
        title: 'Use our service in addition to existing websites',
        description:
            'Continue to upload content to mainstream sites like YouTube and Spotify. This will ensure that you get maximum exposure and brand recognition. However, upload exclusive content to our site that can only be purchased by people that have purchased your content linked NFT. ',
        imageUrl: NftAdvertiseImage3,
    },
]

function NftAdvertise() {
    const navigate = useNavigate()

    return (
        <>
            <Box sx={{ backgroundColor: '#6FDA44' }}>
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexDirection: { xs: 'column-reverse', md: 'row' },
                            paddingY: { xs: '39px', sm: '63px' },
                        }}
                    >
                        <Box sx={{ maxWidth: '477px' }}>
                            <Typography
                                variant="h2"
                                sx={{
                                    mb: '20px',
                                    fontSize: '32px !important',
                                    color: '#2A3538',
                                    textTransform: 'uppercase',
                                }}
                            >
                                Why sell your content on with us
                            </Typography>
                            <Typography
                                sx={{
                                    mb: '31px',
                                    fontWeight: '400 !important',
                                }}
                            >
                                Sniser works different to other streaming
                                service. You do not gain revenue from receiving
                                a percentage of advertising revenue; instead,
                                you a percentage of the revenue that comes when
                                users purchase your user linked NFT. You do not
                                need to be tech savvy to use our service.
                                Simply, upload your content and we will do the
                                rest, meaning paying you 90% of all earnings.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}
                            >
                                <Button
                                    sx={{
                                        background: '#2A3538',
                                        fontWeight: 700,
                                        color: '#ffffff',
                                        textTransform: 'capitalize',
                                        padding: '10px 40px',
                                    }}
                                    onClick={() => navigate('/upload')}
                                >
                                    Upload
                                </Button>
                                <ArrowOutwardIcon
                                    sx={{ color: '#2A3538', fontSize: '24px' }}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: { xs: '345px', md: '600px' },
                                height: { xs: 'auto', md: '400px' },
                                mb: { xs: '23px', md: 0 },
                            }}
                        >
                            <img
                                src={NftAdvertiseImage}
                                alt=""
                                style={{ objectFit: 'cover' }}
                            />
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box sx={{ paddingBottom: { xs: '63px', md: '99px' } }}>
                {ContentData?.map((item, index) => (
                    <Box
                        key={index.toString()}
                        sx={{
                            paddingY: { xs: '23px', md: '52px' },
                            display: 'flex',
                            gap: '62px',
                            flexDirection: {
                                xs: 'column',
                                md: index % 2 === 0 ? 'row' : 'row-reverse',
                            },
                            backgroundColor:
                                index % 2 === 0
                                    ? 'transparent'
                                    : 'rgba(2, 3, 3, 0.05)',
                        }}
                    >
                        <Container>
                            <Box
                                key={index.toString()}
                                sx={{
                                    paddingY: { xs: '23px', md: '40px' },
                                    display: 'flex',
                                    gap: '62px',
                                    flexDirection: {
                                        xs: 'column',
                                        md:
                                            index % 2 === 0
                                                ? 'row'
                                                : 'row-reverse',
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        width: { xs: '345px', md: '450px' },
                                        height: 'auto',
                                        mb: { xs: '23px', md: 0 },
                                        flexShrink: 0,
                                    }}
                                >
                                    <img
                                        src={item.imageUrl}
                                        alt=""
                                        style={{ objectFit: 'cover' }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: '64px',
                                            color: '#6FDA44',
                                        }}
                                    >
                                        {index + 1}.
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '32px',
                                            color: '#2A3538',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        {item.title}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: '400 !important',
                                            color: '#2A3538',
                                        }}
                                    >
                                        {item.description}
                                    </Typography>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                ))}
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            height: { xs: '269px', sm: '272px' },
                            backgroundColor: '#2A3538',
                            borderRadius: '12px',
                            textTransform: 'uppercase',
                            color: '#fff',
                            fontSize: '32px',
                            fontWeight: 700,
                        }}
                    >
                        <Typography
                            variant="h2"
                            sx={{
                                mb: { xs: '24px', sm: '30px' },
                                fontSize: {
                                    xs: '24px !important',
                                    sm: '32px !important',
                                },
                                textAlign: 'center',
                                color: '#fff',
                            }}
                        >
                            UPLOAD YOUR CONTENT
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                mb: { xs: '24px', sm: '45px' },
                                maxWidth: '680px',
                                fontSize: { xs: '14px', sm: '20px' },
                                color: '#fff',
                                fontWeight: '400 !important',
                                textTransform: 'initial',
                            }}
                        >
                            It takes less than 5 minutes to upload content on to
                            our site. Complete it three simple stages. No
                            knowledge of blockchain technology is needed.
                        </Typography>
                        <Button
                            sx={{
                                background: '#6FDA44',
                                fontWeight: 700,
                                color: '#2A3538',
                                textTransform: 'capitalize',
                                padding: '10px 40px',
                                fontSize: { xs: '14px', sm: '16px' },
                            }}
                            onClick={() => navigate('/upload')}
                        >
                            Upload
                        </Button>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default NftAdvertise
