import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import { Box, Button, Container, Typography } from '@mui/material'
import PurchaseAnNftImage1 from '../assets/concept-credit-card-payment-landing-page.jpg'
import PurchaseAnNftImage2 from '../assets/security-concept-illustration.jpg'
import PurchaseAnNftImage3 from '../assets/dark-analytics-concept-illustration.jpg'
import PurchaseAnNftImage from '../assets/concept-illustration.jpg'
import { useNavigate } from 'react-router-dom'

const ContentData = [
    {
        title: 'Use a crypto wallet',
        description:
            'Crypto wallets are a way to hold crypto assets – whether currency or NFTs. In order to purchase one of our NFTS you need to have either a Metamask (open source) or Coinbase (proprietary) wallet',
        imageUrl: PurchaseAnNftImage1,
    },
    {
        title: 'Deposit currency into your wallet',
        description:
            'Our NFTs can be purchased with the stablecoin Tether. Tether is available on various networks. We use the chain Polygon which is a layer two network. As a result, the transaction fees are significantly lower than on other networks like Ethereum. ',
        imageUrl: PurchaseAnNftImage2,
    },
    {
        title: 'List your NFT for sale',
        description:
            'You can sell your NFT on once you no longer wish to own it or alternatively, if its selling for a higher price than you paid and you want to make a profit. Once you own an NFT it will show up in your marketplace with the option to list – meaning that it will be available for other users to purchase at a price that you set',
        imageUrl: PurchaseAnNftImage3,
    },
]

function PurchaseAnNft() {
    const navigate = useNavigate()

    return (
        <>
            <Box sx={{ backgroundColor: '#6FDA44' }}>
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexDirection: { xs: 'column-reverse', md: 'row' },
                            paddingY: { xs: '39px', sm: '63px' },
                        }}
                    >
                        <Box sx={{ maxWidth: '477px' }}>
                            <Typography
                                variant="h2"
                                sx={{
                                    mb: '20px',
                                    fontSize: '32px !important',
                                    color: '#2A3538',
                                    textTransform: 'uppercase',
                                }}
                            >
                                How to purchase or sell an NFT.
                            </Typography>
                            <Typography
                                sx={{
                                    mb: '31px',
                                    fontWeight: '400 !important',
                                }}
                            >
                                Purchasing an NFT is simpler than most people
                                think. With Sniser all NFTS are bought and sold
                                with the Tether stablecoin on the Polygon
                                network. This means that gas (transfer fees) is
                                much less than transactions on the Ethereum
                                network. Feel free to use a proprietary wallet
                                like Coinbase or an open source one like
                                Metamask
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}
                            >
                                <Button
                                    sx={{
                                        background: '#2A3538',
                                        fontWeight: 700,
                                        color: '#ffffff',
                                        textTransform: 'capitalize',
                                        padding: '10px 40px',
                                    }}
                                    // onClick={() => navigate("/shop")}
                                >
                                    Buy Now
                                </Button>
                                <ArrowOutwardIcon
                                    sx={{ color: '#2A3538', fontSize: '24px' }}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: { xs: '345px', md: '600px' },
                                height: { xs: 'auto', md: '400px' },
                                mb: { xs: '23px', md: 0 },
                            }}
                        >
                            <img
                                src={PurchaseAnNftImage}
                                alt=""
                                style={{ objectFit: 'cover' }}
                            />
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box sx={{ paddingBottom: { xs: '63px', md: '99px' } }}>
                {ContentData?.map((item, index) => (
                    <Box
                        key={index.toString()}
                        sx={{
                            paddingY: { xs: '23px', md: '52px' },
                            display: 'flex',
                            gap: '62px',
                            flexDirection: {
                                xs: 'column',
                                md: index % 2 === 0 ? 'row' : 'row-reverse',
                            },
                            backgroundColor:
                                index % 2 === 0
                                    ? 'transparent'
                                    : 'rgba(2, 3, 3, 0.05)',
                        }}
                    >
                        <Container>
                            <Box
                                key={index.toString()}
                                sx={{
                                    paddingY: { xs: '23px', md: '40px' },
                                    display: 'flex',
                                    gap: '62px',
                                    flexDirection: {
                                        xs: 'column',
                                        md:
                                            index % 2 === 0
                                                ? 'row'
                                                : 'row-reverse',
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        width: { xs: '345px', md: '450px' },
                                        height: 'auto',
                                        mb: { xs: '23px', md: 0 },
                                        flexShrink: 0,
                                    }}
                                >
                                    <img
                                        src={item.imageUrl}
                                        alt=""
                                        style={{ objectFit: 'cover' }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: '64px',
                                            color: '#6FDA44',
                                        }}
                                    >
                                        {index + 1}.
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '32px',
                                            color: '#2A3538',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        {item.title}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: '400 !important',
                                            color: '#2A3538',
                                        }}
                                    >
                                        {item.description}
                                    </Typography>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                ))}
            </Box>
        </>
    )
}

export default PurchaseAnNft
