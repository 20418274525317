import { Box, Button, styled, SvgIcon, Typography } from '@mui/material'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppContext } from '../Context'
import { GC_BUCKET_URL, ORDER_STATUS } from '../utils/constant'
import { audioSVG, playSVG, resaleSVG, startSVG, videoSVG } from './SVG'
import Video from './Video'

const PlayButton = styled(Button)({
    backgroundColor: '#fff',
    borderRadius: '100%',
    opacity: 0.6,
    padding: '18px 4px 18px 6px',
    '&:hover': {
        backgroundColor: '#90E26F',
    },
})

const MediaItemCard = ({ nft, preview, size }) => {
    const navigate = useNavigate()
    const [play, setPlay] = useState(false)
    const { isMobile } = useContext(AppContext)

    const onPlay = () => {}
    const onPause = () => {}

    const getHeight = () => {
        if (size === 'small') {
            return isMobile ? '150px' : '180px'
        } else {
            return isMobile ? '400px' : '550px'
        }
    }

    const getWidth = () => {
        if (size === 'small') {
            return isMobile ? '35%' : '100%'
        } else {
            return '100%'
        }
    }

    return (
        <Box position="relative" sx={{}}>
            {!play && (
                <Box
                    position="absolute"
                    left={8}
                    top={8}
                    zIndex={100}
                    sx={{
                        background: 'white',
                        opacity: 0.6,
                        padding: '6px 12px',
                        borderRadius: '10px',
                        alignItems: 'center',
                    }}
                >
                    <Typography>{nft.title}</Typography>
                </Box>
            )}

            {!play && (
                <Box position="relative">
                    <img
                        src={`${GC_BUCKET_URL}${nft?.image}`}
                        alt="nft"
                        style={{
                            borderRadius: '8px',
                            width: getWidth(),
                            height: getHeight(),
                        }}
                    />

                    {size !== 'small' && (
                        <Box
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            left={0}
                            right={0}
                            top={0}
                            bottom={0}
                        >
                            <PlayButton onClick={(_) => setPlay(true)}>
                                <SvgIcon>{playSVG()}</SvgIcon>
                            </PlayButton>
                        </Box>
                    )}
                </Box>
            )}

            {play && (
                <Box position="relative">
                    <Box
                        position="absolute"
                        left={0}
                        right={0}
                        top={0}
                        bottom={0}
                    >
                        <img
                            src={`${GC_BUCKET_URL}${nft?.image}`}
                            alt="nft"
                            style={{
                                borderRadius: '8px',
                                width: getWidth(),
                                height: getHeight(),
                            }}
                        />
                    </Box>
                    <Video
                        url={`${GC_BUCKET_URL}${nft?.preview}`}
                        image={
                            nft.fileType === 'audio'
                                ? `${GC_BUCKET_URL}${nft?.image}`
                                : ''
                        }
                        className={`media-player ${nft?.fileType === 'audio' && 'audio'}`}
                        handleOnPlayMedia={onPlay}
                        handleOnPauseMedia={onPause}
                    />
                </Box>
            )}
        </Box>
    )
}

export default MediaItemCard
