import './styles.scss'

import useDocumentTitle from '../../useDocumentTitle'

function TermsAndConditions() {
    useDocumentTitle('Sniser Term and Conditions')

    return (
        <div className="wrapper-content">
            <h1>
                <span style={{ fontSize: '20pt' }}>
                    Terms and Conditions (&quot;Terms&quot;)
                </span>
            </h1>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Our Terms and Conditions were last updated on 07/12/2023
                </span>
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Please read these terms and conditions carefully before
                    using Our Service.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h2>
                <span style={{ fontSize: '16pt' }}>
                    Interpretation and Definitions
                </span>
            </h2>
            <br />
            <h3>
                <span
                    style={{
                        color: '#434343',
                        fontSize: '13.999999999999998pt',
                    }}
                >
                    Interpretation
                </span>
            </h3>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                </span>
            </p>
            <br />
            <h3>
                <span
                    style={{
                        color: '#434343',
                        fontSize: '13.999999999999998pt',
                    }}
                >
                    Definitions
                </span>
            </h3>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    For the purposes of these Terms and Conditions:
                </span>
            </p>
            <p>
                <br />
            </p>
            <ul style={{ paddingLeft: '40px' }}>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>&ldquo;</span>
                        <strong>
                            <span style={{ fontSize: '11pt' }}>Affiliate</span>
                        </strong>
                        <span style={{ fontSize: '11pt' }}>
                            &rdquo; means an entity that controls, is controlled
                            by or is under common control with a party, where
                            &quot;control&quot; means ownership of 50% or more
                            of the shares, equity interest or other securities
                            entitled to vote for election of directors or other
                            managing authority.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>&ldquo;</span>
                        <strong>
                            <span style={{ fontSize: '11pt' }}>Account</span>
                        </strong>
                        <span style={{ fontSize: '11pt' }}>
                            &rdquo; means a unique account created for You to
                            access our Service or parts of our Service.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>&ldquo;</span>
                        <strong>
                            <span style={{ fontSize: '11pt' }}>Company</span>
                        </strong>
                        <span style={{ fontSize: '11pt' }}>
                            &rdquo; (referred to as either &quot;the
                            Company&quot;, &quot;We&quot;, &quot;Us&quot; or
                            &quot;Our&quot; in this Agreement) refers to Noak
                            Industries Ltd.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>&ldquo;</span>
                        <strong>
                            <span style={{ fontSize: '11pt' }}>Country</span>
                        </strong>
                        <span style={{ fontSize: '11pt' }}>
                            &rdquo; refers to UNITED KINGDON.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>&ldquo;</span>
                        <strong>
                            <span style={{ fontSize: '11pt' }}>Content</span>
                        </strong>
                        <span style={{ fontSize: '11pt' }}>
                            &rdquo; refers to content such as text, images, or
                            other information that can be posted, uploaded,
                            linked to or otherwise made available by You,
                            regardless of the form of that content.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>&ldquo;</span>
                        <strong>
                            <span style={{ fontSize: '11pt' }}>Device</span>
                        </strong>
                        <span style={{ fontSize: '11pt' }}>
                            &rdquo; means any device that can access the Service
                            such as a computer, a cellphone or a digital tablet.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>&ldquo;</span>
                        <strong>
                            <span style={{ fontSize: '11pt' }}>Feedback</span>
                        </strong>
                        <span style={{ fontSize: '11pt' }}>
                            &rdquo; means feedback, innovations or suggestions
                            sent by You regarding the attributes, performance or
                            features of our Service.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>&ldquo;</span>
                        <strong>
                            <span style={{ fontSize: '11pt' }}>Products</span>
                        </strong>
                        <span style={{ fontSize: '11pt' }}>
                            &rdquo; refer to the products or items offered for
                            sale on the Service.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>&ldquo;</span>
                        <strong>
                            <span style={{ fontSize: '11pt' }}>Orders</span>
                        </strong>
                        <span style={{ fontSize: '11pt' }}>
                            &rdquo; mean a request by You to purchase Products
                            from Us.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>&ldquo;</span>
                        <strong>
                            <span style={{ fontSize: '11pt' }}>Promotions</span>
                        </strong>
                        <span style={{ fontSize: '11pt' }}>
                            &rdquo; refer to contests, sweepstakes or other
                            promotions offered through the Service.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>&ldquo;</span>
                        <strong>
                            <span style={{ fontSize: '11pt' }}>Service</span>
                        </strong>
                        <span style={{ fontSize: '11pt' }}>
                            &rdquo; refers to the Website.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>&ldquo;</span>
                        <strong>
                            <span style={{ fontSize: '11pt' }}>
                                Terms and Conditions
                            </span>
                        </strong>
                        <span style={{ fontSize: '11pt' }}>
                            &rdquo; (also referred as &quot;
                        </span>
                        <strong>
                            <span style={{ fontSize: '11pt' }}>Terms</span>
                        </strong>
                        <span style={{ fontSize: '11pt' }}>
                            &quot;) mean these Terms and Conditions that form
                            the entire agreement between You and the Company
                            regarding the use of the Service. This Terms and
                            Conditions Agreement was generated by&nbsp;
                        </span>
                        <a href="https://www.termsfeed.com/terms-conditions-generator/">
                            <span style={{ fontSize: '11pt' }}>
                                Terms Feed Terms and Conditions Generator
                            </span>
                        </a>
                        <span style={{ fontSize: '11pt' }}>.</span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>&ldquo;</span>
                        <strong>
                            <span style={{ fontSize: '11pt' }}>
                                Third-party Social Media Service
                            </span>
                        </strong>
                        <span style={{ fontSize: '11pt' }}>
                            &rdquo; means any services or content (including
                            data, information, products or services) provided by
                            a third-party that may be displayed, included or
                            made available by the Service.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>&ldquo;</span>
                        <strong>
                            <span style={{ fontSize: '11pt' }}>Website</span>
                        </strong>
                        <span style={{ fontSize: '11pt' }}>
                            &rdquo; refers to&nbsp;
                        </span>
                        <a href="http://www.sniser.com">
                            <u>
                                <span
                                    style={{
                                        color: '#0000ff',
                                        fontSize: '11pt',
                                    }}
                                >
                                    SNISER.COM
                                </span>
                            </u>
                        </a>
                        <span style={{ fontSize: '11pt' }}>,&nbsp;</span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>&ldquo;</span>
                        <strong>
                            <span style={{ fontSize: '11pt' }}>You</span>
                        </strong>
                        <span style={{ fontSize: '11pt' }}>
                            &rdquo; means the individual accessing or using the
                            Service, or the company, or other legal entity on
                            behalf of which such individual is accessing or
                            using the Service, as applicable.
                        </span>
                    </p>
                </li>
            </ul>
            <br />
            <h2>
                <span style={{ fontSize: '16pt' }}>Acknowledgment</span>
            </h2>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    These are the Terms and Conditions governing the use of this
                    Service and the agreement that operates between You and the
                    Company. These Terms and Conditions set out the rights and
                    obligations of all users regarding the use of the Service.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Your access to and use of the Service is conditioned on Your
                    acceptance of and compliance with these Terms and
                    Conditions. These Terms and Conditions apply to all
                    visitors, users and others who access or use the Service.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    By accessing or using the Service You agree to be bound by
                    these Terms and Conditions. If You disagree with any part of
                    these Terms and Conditions then You may not access the
                    Service.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    You represent that you are over the age of 18. The Company
                    does not permit those under 18 to use the Service.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Your access to and use of the Service is also conditioned on
                    Your acceptance of and compliance with the Privacy Policy of
                    the Company. Our Privacy Policy describes Our policies and
                    procedures on the collection, use and disclosure of Your
                    personal information when You use the Application or the
                    Website and tells You about Your privacy rights and how the
                    law protects You. Please read Our Privacy Policy carefully
                    before using Our Service.
                </span>
            </p>
            <br />
            <h2>
                <span style={{ fontSize: '16pt' }}>User Accounts</span>
            </h2>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    When You create an account with Us, You must provide Us
                    information that is accurate, complete, and current at all
                    times. Failure to do so constitutes a br/each of the Terms,
                    which may result in immediate termination of Your account on
                    Our Service.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    You are responsible for safeguarding the password that You
                    use to access the Service and for any activities or actions
                    under Your password, whether Your password is with Our
                    Service or a Third-Party Social Media Service.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    You agree not to disclose Your password to any third party.
                    You must notify Us immediately upon becoming aware of any
                    br/each of security or unauthorized use of Your account.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    You may not use as a username the name of another person or
                    entity or that is not lawfully available for use, a name or
                    trademark that is subject to any rights of another person or
                    entity other than You without appropriate authorization, or
                    a name that is otherwise offensive, vulgar or obscene.
                </span>
            </p>
            <br />
            <h2>
                <span style={{ fontSize: '16pt' }}>Content</span>
            </h2>
            <br />
            <h3>
                <span
                    style={{
                        color: '#434343',
                        fontSize: '13.999999999999998pt',
                    }}
                >
                    Your Right to Post Content
                </span>
            </h3>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Our Service allows You to post Content. You are responsible
                    for the Content that You post to the Service, including its
                    legality, reliability, and appropriateness.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    By posting Content to the Service, You grant Us the right
                    and license to use, modify, publicly perform, publicly
                    display, reproduce, and distribute such Content on and
                    through the Service. You retain any and all of Your rights
                    to any Content You submit, post or display on or through the
                    Service and You are responsible for protecting those rights.
                    You agree that this license includes the right for Us to
                    make Your Content available to other users of the Service,
                    who may also use Your Content subject to these Terms.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    You represent and warrant that: (i) the Content is Yours
                    (You own it) or You have the right to use it and grant Us
                    the rights and license as provided in these Terms, and (ii)
                    the posting of Your Content on or through the Service does
                    not violate the privacy rights, publicity rights,
                    copyrights, contract rights or any other rights of any
                    person.
                </span>
            </p>
            <br />
            <h3>
                <span
                    style={{
                        color: '#434343',
                        fontSize: '13.999999999999998pt',
                    }}
                >
                    Content Restrictions
                </span>
            </h3>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    The Company is not responsible for the content of the
                    Service&apos;s users. You expressly understand and agree
                    that You are solely responsible for the Content and for all
                    activity that occurs under your account, whether done so by
                    You or any third person using Your account.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    You may not transmit any Content that is unlawful,
                    offensive, upsetting, intended to disgust, threatening,
                    libelous, defamatory, obscene or otherwise objectionable.
                    Examples of such objectionable Content include, but are not
                    limited to, the following:
                </span>
            </p>
            <p>
                <br />
            </p>
            <ul style={{ paddingLeft: '40px' }}>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            Unlawful or promoting unlawful activity.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            Defamatory, discriminatory, or mean-spirited
                            content, including references or commentary about
                            religion, race, sexual orientation, gender,
                            national/ethnic origin, or other targeted groups.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            Spam, machine &ndash; or randomly &ndash; generated,
                            constituting unauthorized or unsolicited
                            advertising, chain letters, any other form of
                            unauthorized solicitation, or any form of lottery or
                            gambling.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            Containing or installing any viruses, worms,
                            malware, trojan horses, or other content that is
                            designed or intended to disrupt, damage, or limit
                            the functioning of any software, hardware or
                            telecommunications equipment or to damage or obtain
                            unauthorized access to any data or other information
                            of a third person.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            Infringing on any proprietary rights of any party,
                            including patent, trademark, trade secret,
                            copyright, right of publicity or other rights.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            Impersonating any person or entity including the
                            Company and its employees or representatives.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            Violating the privacy of any third person.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            False information and features.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    The Company reserves the right, but not the obligation, to,
                    in its sole discretion, determine whether or not any Content
                    is appropriate and complies with this Terms, refuse or
                    remove this Content. The Company further reserves the right
                    to make formatting and edits and change the manner of any
                    Content. The Company can also limit or revoke the use of the
                    Service if You post such objectionable Content. As the
                    Company cannot control all content posted by users and/or
                    third parties on the Service, you agree to use the Service
                    at your own risk. You understand that by using the Service
                    You may be exposed to content that You may find offensive,
                    indecent, incorrect or objectionable, and You agree that
                    under no circumstances will the Company be liable in any way
                    for any content, including any errors or omissions in any
                    content, or any loss or damage of any kind incurred as a
                    result of your use of any content.
                </span>
            </p>
            <br />
            <h3>
                <span
                    style={{
                        color: '#434343',
                        fontSize: '13.999999999999998pt',
                    }}
                >
                    Content Backups
                </span>
            </h3>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Although regular backups of Content are performed, the
                    Company does not guarantee there will be no loss or
                    corruption of data.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Corrupt or invalid backup points may be caused by, without
                    limitation, Content that is corrupted prior to being backed
                    up or that changes during the time a backup is performed.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    The Company will provide support and attempt to troubleshoot
                    any known or discovered issues that may affect the backups
                    of Content. But You acknowledge that the Company has no
                    liability related to the integrity of Content or the failure
                    to successfully restore Content to a usable state.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    You agree to maintain a complete and accurate copy of any
                    Content in a location independent of the Service.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h2>
                <span style={{ fontSize: '16pt' }}>Copyright Policy</span>
            </h2>
            <br />
            <h3>
                <span
                    style={{
                        color: '#434343',
                        fontSize: '13.999999999999998pt',
                    }}
                >
                    Intellectual Property Infringement
                </span>
            </h3>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    We respect the intellectual property rights of others. It is
                    Our policy to respond to any claim that Content posted on
                    the Service infringes a copyright or other intellectual
                    property infringement of any person.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    If You are a copyright owner, or authorized on behalf of
                    one, and You believe that the copyrighted work has been
                    copied in a way that constitutes copyright infringement that
                    is taking place through the Service, You must submit Your
                    notice in writing to the attention of our copyright agent
                    via email&nbsp;
                </span>
                <a href="mailto:SUPPORT@SNISER.COM">
                    <u>
                        <span style={{ color: '#0000ff', fontSize: '11pt' }}>
                            SUPPORT@SNISER.COM
                        </span>
                    </u>
                </a>
                <span style={{ fontSize: '11pt' }}>
                    &nbsp;and include in Your notice a detailed description of
                    the alleged infringement.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    You may be held accountable for damages (including costs and
                    attorneys&apos; fees) for misrepresenting that any Content
                    is infringing Your copyright.
                </span>
            </p>
            <br />
            <h3>
                <span
                    style={{
                        color: '#434343',
                        fontSize: '13.999999999999998pt',
                    }}
                >
                    DMCA Notice and DMCA Procedure for Copyright Infringement
                    Claims
                </span>
            </h3>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    You may submit a notification pursuant to the Digital
                    Millennium Copyright Act (DMCA) by providing our Copyright
                    Agent with the following information in writing (see 17
                    U.S.C 512(c)(3) for further detail):
                </span>
            </p>
            <p>
                <br />
            </p>
            <ul style={{ paddingLeft: '40px' }}>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            An electronic or physical signature of the person
                            authorized to act on behalf of the owner of the
                            copyright&apos;s interest.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            A description of the copyrighted work that You claim
                            has been infringed, including the URL (i.e., web
                            page address) of the location where the copyrighted
                            work exists or a copy of the copyrighted work.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            Identification of the URL or other specific location
                            on the Service where the material that You claim is
                            infringing is located.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            Your address, telephone number, and email address.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            A statement by You that You have a good faith belief
                            that the disputed use is not authorized by the
                            copyright owner, its agent, or the law.
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            A statement by You, made under penalty of perjury,
                            that the above information in Your notice is
                            accurate and that You are the copyright owner or
                            authorized to act on the copyright owner&apos;s
                            behalf.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    You can contact our copyright agent via email&nbsp;
                </span>
                <a href="mailto:SUPPORT@SNISER.COM">
                    <u>
                        <span style={{ color: '#0000ff', fontSize: '11pt' }}>
                            SUPPORT@SNISER.COM
                        </span>
                    </u>
                </a>
                <span style={{ fontSize: '11pt' }}>
                    . Upon receipt of a notification, the Company will take
                    whatever action, in its sole discretion, it deems
                    appropriate, including removal of the challenged content
                    from the Service.
                </span>
            </p>
            <br />
            <h2>
                <span style={{ fontSize: '16pt' }}>
                    Placing Orders for Products
                </span>
            </h2>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    By placing an Order for Products through the Service, You
                    warrant that You are legally capable of entering into
                    binding contracts.
                </span>
            </p>
            <br />
            <h3>
                <span
                    style={{
                        color: '#434343',
                        fontSize: '13.999999999999998pt',
                    }}
                >
                    Your Information
                </span>
            </h3>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    If You wish to place an Order for Products available on the
                    Service, You may be asked to supply certain information
                    relevant to Your Order including, without limitation, Your
                    name, Your email, Your phone number, Your credit card
                    number, the expiration date of Your credit card, Your
                    billing address, and Your shipping information.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    You represent and warrant that: (i) You have the legal right
                    to use any credit or debit card(s) or other payment
                    method(s) in connection with any Order; and that (ii) the
                    information You supply to us is true, correct and complete.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    By submitting such information, You grant us the right to
                    provide the information to payment processing third parties
                    for purposes of facilitating the completion of Your Order.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h3>
                <span
                    style={{
                        color: '#434343',
                        fontSize: '13.999999999999998pt',
                    }}
                >
                    Order Cancellation
                </span>
            </h3>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    We reserve the right to refuse or cancel Your Order at any
                    time for certain reasons including but not limited to:
                </span>
            </p>
            <p>
                <br />
            </p>
            <ul style={{ paddingLeft: '40px' }}>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            Products availability
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            Errors in the description or prices for Products
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            Errors in Your Order
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    We reserve the right to refuse or cancel Your Order if fraud
                    or an unauthorized or illegal transaction is suspected.
                </span>
            </p>
            <h3>
                <span
                    style={{
                        color: '#434343',
                        fontSize: '13.999999999999998pt',
                    }}
                >
                    Your Order Cancellation Rights
                </span>
            </h3>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Any Products you purchase can only be returned or refunded
                    in accordance with these Terms and Conditions.
                </span>
            </p>
            <br />
            <h3>
                <span
                    style={{
                        color: '#434343',
                        fontSize: '13.999999999999998pt',
                    }}
                >
                    Availability, Errors and Inaccuracies
                </span>
            </h3>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    We are constantly updating Our offerings of Products on the
                    Service. The Products available on Our Service may be
                    mispriced, described inaccurately, or unavailable, and We
                    may experience delays in updating information regarding our
                    Products on the Service and in Our advertising on other
                    websites.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    We cannot and do not guarantee the accuracy or completeness
                    of any information, including prices, product images,
                    specifications, availability, and services. We reserve the
                    right to change or update information and to correct errors,
                    inaccuracies, or omissions at any time without prior notice.
                </span>
            </p>
            <br />
            <h3>
                <span
                    style={{
                        color: '#434343',
                        fontSize: '13.999999999999998pt',
                    }}
                >
                    Prices Policy
                </span>
            </h3>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    The Company reserves the right to revise its prices at any
                    time prior to accepting an Order.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    The prices quoted may be revised by the Company after
                    accepting an Order in the event of any occurrence affecting
                    delivery caused by government action, variation in customs
                    duties, increased shipping charges, higher foreign exchange
                    costs and any other matter beyond the control of the
                    Company. In that event, You will have the right to cancel
                    Your Order.
                </span>
            </p>
            <br />
            <h3>
                <span
                    style={{
                        color: '#434343',
                        fontSize: '13.999999999999998pt',
                    }}
                >
                    Payments
                </span>
            </h3>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Payment can be made through various payment methods we have
                    available, such as Visa, MasterCard, Affinity Card, American
                    Express cards or online payment methods (PayPal, for
                    example).
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Payment cards (credit cards or debit cards) are subject to
                    validation checks and authorization by Your card issuer. If
                    we do not receive the required authorization, We will not be
                    liable for any delay or non-delivery of Your Order.
                </span>
            </p>
            <br />
            <h3>
                <span
                    style={{
                        color: '#434343',
                        fontSize: '13.999999999999998pt',
                    }}
                >
                    Promotions
                </span>
            </h3>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Any Promotions made available through the Service may be
                    governed by rules that are separate from these Terms.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    If You participate in any Promotions, please review the
                    applicable rules as well as our Privacy policy. If the rules
                    for a Promotion conflict with these Terms, the Promotion
                    rules will apply.
                </span>
            </p>
            <br />
            <h2>
                <span style={{ fontSize: '16pt' }}>Intellectual Property</span>
            </h2>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    The Service and its original content (excluding Content
                    provided by You or other users), features and functionality
                    are and will remain the exclusive property of the Company
                    and its licensors.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    The Service is protected by copyright, trademark, and other
                    laws of both the Country and foreign countries.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Our trademarks and trade dress may not be used in connection
                    with any product or service without the prior written
                    consent of the Company.
                </span>
            </p>
            <br />
            <h2>
                <span style={{ fontSize: '16pt' }}>Your Feedback to Us</span>
            </h2>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    You assign all rights, title and interest in any Feedback
                    You provide the Company. If for any reason such assignment
                    is ineffective, You agree to grant the Company a
                    non-exclusive, perpetual, irrevocable, royalty free,
                    worldwide right and license to use, reproduce, disclose,
                    sub-license, distribute, modify and exploit such Feedback
                    without restriction.
                </span>
            </p>
            <br />
            <h2>
                <span style={{ fontSize: '16pt' }}>
                    Links to Other Websites
                </span>
            </h2>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Our Service may contain links to third-party web sites or
                    services that are not owned or controlled by the Company.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    The Company has no control over, and assumes no
                    responsibility for, the content, privacy policies, or
                    practices of any third-party web sites or services. You
                    further acknowledge and agree that the Company shall not be
                    responsible or liable, directly or indirectly, for any
                    damage or loss caused or alleged to be caused by or in
                    connection with the use of or reliance on any such content,
                    goods or services available on or through any such web sites
                    or services.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    We strongly advise You to read the terms and conditions and
                    privacy policies of any third-party web sites or services
                    that You visit.
                </span>
            </p>
            <br />
            <h2>
                <span style={{ fontSize: '16pt' }}>Termination</span>
            </h2>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    We may terminate or suspend Your Account immediately,
                    without prior notice or liability, for any reason
                    whatsoever, including without limitation if You br/each
                    these Terms and Conditions.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Upon termination, your right to use the Service will cease
                    immediately. If You wish to terminate Your Account, you may
                    simply discontinue using the Service.
                </span>
            </p>
            <br />
            <h2>
                <span style={{ fontSize: '16pt' }}>
                    Limitation of Liability
                </span>
            </h2>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Notwithstanding any damages that You might incur, the entire
                    liability of the Company and any of its suppliers under any
                    provision of this Terms and Your exclusive remedy for all of
                    the foregoing shall be limited to the amount actually paid
                    by You through the Service or 100 USD if You haven&apos;t
                    purchased anything through the Service.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    To the maximum extent permitted by applicable law, in no
                    event shall the Company or its suppliers be liable for any
                    special, incidental, indirect, or consequential damages
                    whatsoever (including, but not limited to, damages for loss
                    of profits, loss of data or other information, for business
                    interruption, for personal injury, loss of privacy arising
                    out of or in any way related to the use of or inability to
                    use the Service, third-party software and/or third-party
                    hardware used with the Service, or otherwise in connection
                    with any provision of this Terms), even if the Company or
                    any supplier has been advised of the possibility of such
                    damages and even if the remedy fails of its essential
                    purpose.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Some states do not allow the exclusion of implied warranties
                    or limitation of liability for incidental or consequential
                    damages, which means that some of the above limitations may
                    not apply. In these states, each party&apos;s liability will
                    be limited to the greatest extent permitted by law.
                </span>
            </p>
            <br />
            <h2>
                <span style={{ fontSize: '16pt' }}>
                    &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer
                </span>
            </h2>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    The Service is provided to You &quot;AS IS&quot; and
                    &quot;AS AVAILABLE&quot; and with all faults and defects
                    without warranty of any kind. To the maximum extent
                    permitted under applicable law, the Company, on its own
                    behalf and on behalf of its Affiliates and its and their
                    respective licensors and service providers, expressly
                    disclaims all warranties, whether express, implied,
                    statutory or otherwise, with respect to the Service,
                    including all implied warranties of merchantability, fitness
                    for a particular purpose, title and non-infringement, and
                    warranties that may arise out of course of dealing, course
                    of performance, usage or trade practice. Without limitation
                    to the foregoing, the Company provides no warranty or
                    undertaking, and makes no representation of any kind that
                    the Service will meet Your requirements, achieve any
                    intended results, be compatible or work with any other
                    software, applications, systems or services, operate without
                    interruption, meet any performance or reliability standards
                    or be error free or that any errors or defects can or will
                    be corrected.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Without limiting the foregoing, neither the Company nor any
                    of the company&apos;s provider makes any representation or
                    warranty of any kind, express or implied: (i) as to the
                    operation or availability of the Service, or the
                    information, content, and materials or products included
                    thereon; (ii) that the Service will be uninterrupted or
                    error-free; (iii) as to the accuracy, reliability, or
                    currency of any information or content provided through the
                    Service; or (iv) that the Service, its servers, the content,
                    or e-mails sent from or on behalf of the Company are free of
                    viruses, scripts, trojan horses, worms, malware, timebombs
                    or other harmful components.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Some jurisdictions do not allow the exclusion of certain
                    types of warranties or limitations on applicable statutory
                    rights of a consumer, so some or all of the above exclusions
                    and limitations may not apply to You. But in such a case the
                    exclusions and limitations set forth in this section shall
                    be applied to the greatest extent enforceable under
                    applicable law.
                </span>
            </p>
            <br />
            <h2>
                <span style={{ fontSize: '16pt' }}>Governing Law</span>
            </h2>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    The laws of England and Wales, excluding its conflicts of
                    law rules, shall govern this Terms and Your use of the
                    Service. Your use of the Application may also be subject to
                    other local, state, national, or international laws.
                </span>
            </p>
            <br />
            <h2>
                <span style={{ fontSize: '16pt' }}>Disputes Resolution</span>
            </h2>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    If You have any concern or dispute about the Service, you
                    agree to first try to resolve the dispute informally by
                    contacting the Company.
                </span>
            </p>
            <br />
            <h2>
                <span style={{ fontSize: '16pt' }}>
                    For European Union (EU) Users
                </span>
            </h2>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    If You are a European Union consumer, you will benefit from
                    any mandatory provisions of the law of the country in which
                    you are resident in.
                </span>
            </p>
            <br />
            <h2>
                <span style={{ fontSize: '16pt' }}>
                    United States Legal Compliance
                </span>
            </h2>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    You represent and warrant that (i) You are not located in a
                    country that is subject to the United States government
                    embargo, or that has been designated by the United States
                    government as a &quot;terrorist supporting&quot; country,
                    and (ii) You are not listed on any United States government
                    list of prohibited or restricted parties.
                </span>
            </p>
            <br />
            <h2>
                <span style={{ fontSize: '16pt' }}>
                    Severability and Waiver
                </span>
            </h2>
            <br />
            <h3>
                <span
                    style={{
                        color: '#434343',
                        fontSize: '13.999999999999998pt',
                    }}
                >
                    Severability
                </span>
            </h3>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    If any provision of these Terms is held to be unenforceable
                    or invalid, such provision will be changed and interpreted
                    to accomplish the objectives of such provision to the
                    greatest extent possible under applicable law and the
                    remaining provisions will continue in full force and effect.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h3>
                <span
                    style={{
                        color: '#434343',
                        fontSize: '13.999999999999998pt',
                    }}
                >
                    Waiver
                </span>
            </h3>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    Except as provided herein, the failure to exercise a right
                    or to require performance of an obligation under this Terms
                    shall not effect a party&apos;s ability to exercise such
                    right or require such performance at any time thereafter nor
                    shall be the waiver of a br/each constitute a waiver of any
                    subsequent br/each.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h2>
                <span style={{ fontSize: '16pt' }}>
                    Changes to These Terms and Conditions
                </span>
            </h2>
            <br />
            <p>
                <span style={{ fontSize: '11pt' }}>
                    We reserve the right, at Our sole discretion, to modify or
                    replace these Terms at any time. If a revision is material
                    We will make reasonable efforts to provide at least 30
                    days&apos; notice prior to any new terms taking effect. What
                    constitutes a material change will be determined at Our sole
                    discretion.
                </span>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    By continuing to access or use Our Service after those
                    revisions become effective, you agree to be bound by the
                    revised terms. If You do not agree to the new terms, in
                    whole or in part, please stop using the website and the
                    Service.
                </span>
            </p>
            <br />
            <h2>
                <span style={{ fontSize: '16pt' }}>Contact Us</span>
            </h2>
            <p>
                <br />
            </p>
            <p>
                <span style={{ fontSize: '11pt' }}>
                    If you have any questions about these Terms and Conditions,
                    you can contact us:
                </span>
            </p>
            <p>
                <br />
            </p>
            <ul style={{ paddingLeft: '40px' }}>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            By visiting this page on our website: WWW.SNISER.COM
                        </span>
                    </p>
                </li>
                <li style={{ listStyleType: 'disc', fontSize: '11pt' }}>
                    <p>
                        <span style={{ fontSize: '11pt' }}>
                            By sending us an email:&nbsp;
                        </span>
                        <a href="mailto:SUPPORT@SNISER.COM">
                            <u>
                                <span
                                    style={{
                                        color: '#0000ff',
                                        fontSize: '11pt',
                                    }}
                                >
                                    SUPPORT@SNISER.COM
                                </span>
                            </u>
                        </a>
                        <span style={{ fontSize: '11pt' }}>&nbsp;</span>
                    </p>
                </li>
            </ul>
            <p>
                <br />
            </p>
            <p>
                <br />
            </p>
        </div>
    )
}

export default TermsAndConditions
