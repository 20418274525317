import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
} from '@mui/material'
import React, { useCallback, useState } from 'react'
import ProgressBar from './ProgressBar'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Controller, useFormContext } from 'react-hook-form'
import { inputStyle } from './PersonalInformation'
import ReCAPTCHA from 'react-google-recaptcha'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

function Payout({ setPrevStep, handleSubmit }) {
    const { control } = useFormContext()
    const [verify, setVerify] = useState(false)
    const [isConfirm, setIsConfirm] = useState(false)

    const onChaptchaChange = useCallback((value) => {
        if (value) {
            setVerify(true)
        }
    }, [])

    return (
        <Box sx={{ background: '#fff', p: '32px', borderRadius: '12px' }}>
            <ProgressBar progress={100} />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    mb: '28px',
                    mt: '24px',
                }}
            >
                <InfoOutlinedIcon />
                <Typography
                    sx={{
                        fontSize: '12px',
                        color: '#2A3538',
                        fontWeight: '400 !important',
                    }}
                >
                    paymentis made to you via PayPal. Please ensure that the
                    correct details areprovided to ensure that you receive
                    payments and royalties.
                </Typography>
            </Box>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Typography
                        variant="h4"
                        mb={'10px'}
                        fontSize={'14px'}
                        fontWeight={700}
                    >
                        Payment detail (Provide PayPal email account details to
                        send payments to)
                    </Typography>
                    <Controller
                        control={control}
                        name="payment"
                        rules={{
                            required: {
                                value: true,
                                message: 'This field is required',
                            },
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error },
                        }) => (
                            <TextField
                                sx={inputStyle}
                                placeholder="yourmail@example.com"
                                error={error}
                                value={value}
                                size="small"
                                fullWidth
                                onChange={onChange}
                                helperText={error?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Box
                mt="20px"
                maxWidth={'100%'}
                sx={{
                    overflow: 'hidden',
                    width: '100%',
                    display: 'flex',
                    justifyContent: { xs: 'start', sm: 'center' },
                }}
            >
                <ReCAPTCHA
                    sitekey="6LeTmHUnAAAAAPQpjmbC1c8daSxUgYte0Jy3M4Xc"
                    onChange={onChaptchaChange}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: '24px',
                    mb: '24px',
                }}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isConfirm}
                            onChange={(e) => setIsConfirm(e.target.checked)}
                        />
                    }
                    sx={{
                        '.MuiFormControlLabel-label': {
                            fontWeight: 600,
                            fontSize: '14px !important',
                        },
                    }}
                    label={
                        <Typography sx={{ fontSize: '14px !important' }}>
                            I agree to the{' '}
                            <a
                                href="/upload-term-conditions"
                                target="_blank"
                                style={{ fontWeight: 700, fontSize: '14px' }}
                            >
                                Term and Conditions Policy
                            </a>
                        </Typography>
                    }
                />
            </Box>
            <Box>
                <Button
                    sx={{
                        background: '#6FDA44',
                        padding: '10px 32px',
                        fontWeight: '700',
                        color: '#2A3538',
                        borderRadius: '12px',
                        width: '100%',
                    }}
                    disabled={!isConfirm || !verify}
                    type="button"
                    onClick={handleSubmit}
                >
                    Next <ArrowRightAltIcon />
                </Button>
            </Box>
            <Box>
                <Button
                    sx={{
                        padding: '10px 32px',
                        fontWeight: '700',
                        color: '#2A3538',
                        borderRadius: '12px',
                        width: '100%',
                    }}
                    type="button"
                    onClick={setPrevStep}
                >
                    <KeyboardBackspaceIcon />
                    Prev
                </Button>
            </Box>
        </Box>
    )
}

export default Payout
