import CloseIcon from '@mui/icons-material/Close'
import {
    Box,
    CircularProgress,
    Dialog,
    Divider,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { GC_BUCKET_URL, LISTING } from '../../utils/constant'
import { CancelButton, OkayButton } from '../Styled'
import { inputStyle, required } from '../Styled/style'

const ListStatModal = ({
    status,
    nft,
    pending,
    priceModalOpen,
    toggelModal,
    completeList,
    updateList,
    cancelList,
}) => {
    const navigate = useNavigate()
    const [price, setPrice] = useState('')

    const modalTitle = (status) => {
        switch (status) {
            case LISTING.START:
                return 'Quick list'
            case LISTING.EDIT:
                return 'Edit listing'
            case LISTING.APPROVING:
                return 'Approve listing'
            case LISTING.CANCELING:
                return 'Cancel listing'
            default:
                break
        }
    }

    const priceSubTitle = (status) => {
        switch (status) {
            case LISTING.START:
                return 'Set a price'
            case LISTING.EDIT:
                return 'Set new price'
            default:
                break
        }
    }

    const priceDesc = (status) => {
        switch (status) {
            case LISTING.START:
                return `You will not be able to change the price after listing. If you'd like to change the price, you will need to create a new listing.`
            case LISTING.EDIT:
                return `If you want to increase the price, you will be prompted to cancel all of your existing listings first. This will cost gas.`
            default:
                break
        }
    }

    const okayBtnLabel = (status) => {
        switch (status) {
            case LISTING.START:
                return `Complete listing`
            case LISTING.EDIT:
            case LISTING.APPROVING:
            case LISTING.CANCELING:
                return `Continue`
            default:
                break
        }
    }

    const walletGuide = (status) => {
        switch (status) {
            case LISTING.APPROVING:
            case LISTING.CANCELING:
                return (
                    <Box mt="16px">
                        <Typography>Go to your wallet</Typography>
                        <Typography variant="overline">
                            You'll be asked to review and confirm this listing
                            from your wallet.
                        </Typography>
                    </Box>
                )
            default:
                return ''
        }
    }

    // console.log('list state modal', status)

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth="xs"
                open={priceModalOpen}
                onClose={() => toggelModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    '& .css-k3rfoq-MuiPaper-root-MuiDialog-paper': {
                        borderRadius: '15px',
                    },
                }}
            >
                <Box p="2px">
                    <IconButton
                        aria-label="delete"
                        sx={{ float: 'right' }}
                        onClick={toggelModal}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                {status === LISTING.LIST_FINISH ||
                status === LISTING.EDIT_FINISH ? (
                    <Box
                        textAlign="center"
                        position="relative"
                        justifyContent="center"
                    >
                        <img
                            src={GC_BUCKET_URL + nft?.image}
                            alt="nft"
                            style={{ width: '240px', margin: '0 auto' }}
                        />

                        <Typography p="20px 0 10px" variant="h2">
                            Your item has been listed!
                        </Typography>
                        <Typography>
                            {nft.title} has been listed for sale.
                        </Typography>

                        <OkayButton onClick={(_) => navigate(`/nft/${nft.id}`)}>
                            View listing
                        </OkayButton>
                    </Box>
                ) : (
                    <Box p="0px 20px 20px">
                        <Typography variant="h2" textAlign="center">
                            {' '}
                            {modalTitle(status)}{' '}
                        </Typography>

                        <Stack
                            mt={5}
                            mb={2}
                            flexDirection={'row'}
                            justifyContent="space-between"
                            alignItems={'center'}
                        >
                            <Stack
                                flexDirection={'row'}
                                alignItems={'center'}
                                position="relative"
                                sx={{ color: 'grey.500' }}
                            >
                                <img
                                    src={GC_BUCKET_URL + nft?.image}
                                    alt="nft"
                                    style={{ width: '120px', height: '80px' }}
                                />
                                <Typography ml="5px" variant="h4">
                                    {nft?.title}
                                </Typography>
                                {(status === LISTING.APPROVING ||
                                    status === LISTING.CANCELING) && (
                                    <CircularProgress
                                        color="inherit"
                                        sx={{
                                            position: 'absolute',
                                            marginLeft: '35px',
                                        }}
                                    />
                                )}
                            </Stack>

                            <Stack flexDirection={'column'} alignItems="end">
                                <Typography variant="overline">
                                    Listing price
                                </Typography>
                                <Typography variant="h4">
                                    {price} USDT
                                </Typography>
                            </Stack>
                        </Stack>

                        <Divider></Divider>

                        <Box paddingY="10px">
                            <Typography variant="h4">
                                {priceSubTitle(status)}
                            </Typography>
                            <Typography variant="overline">
                                {priceDesc(status)}
                            </Typography>
                        </Box>

                        {(status === LISTING.START ||
                            status === LISTING.EDIT) && (
                            <Box py="10px">
                                {status === LISTING.START && (
                                    <Typography
                                        marginBottom={'10px'}
                                        variant="h4"
                                        fontFamily="'Montserrat', sans-serif"
                                    >
                                        Starting price
                                    </Typography>
                                )}
                                <TextField
                                    sx={inputStyle}
                                    onChange={(e) => {
                                        console.log('value', e)
                                        if (!isNaN(e.target.value)) {
                                            setPrice(e.target.value)
                                        }
                                    }}
                                    value={price}
                                    placeholder="10 USDT"
                                    size="small"
                                    fullWidth
                                />
                            </Box>
                        )}

                        {status === LISTING.START && (
                            <>
                                <Stack
                                    paddingY="10px"
                                    direction={'row'}
                                    justifyContent="space-between"
                                >
                                    <Typography>Listing price</Typography>
                                    <Typography>{price} USDT</Typography>
                                </Stack>
                                <Divider></Divider>
                                <Stack
                                    paddingY="10px"
                                    direction={'row'}
                                    justifyContent="space-between"
                                >
                                    <Typography>Marketplace fee</Typography>
                                    <Typography>10%</Typography>
                                </Stack>
                                <Divider></Divider>
                                <Stack
                                    paddingY="10px"
                                    direction={'row'}
                                    justifyContent="space-between"
                                >
                                    <Typography>Royalty fee</Typography>
                                    <Typography>15%</Typography>
                                </Stack>
                            </>
                        )}

                        {!pending && (
                            <Stack
                                flexDirection={'row'}
                                justifyContent={'center'}
                            >
                                {status === LISTING.EDIT && (
                                    <CancelButton
                                        onClick={(_) => cancelList(nft)}
                                    >
                                        Cancel all listing
                                    </CancelButton>
                                )}
                                <OkayButton
                                    disabled={
                                        !price ||
                                        status === LISTING.APPROVING ||
                                        status === LISTING.CANCELING
                                    }
                                    onClick={(_) => {
                                        status === LISTING.START
                                            ? completeList(price)
                                            : updateList(price)
                                    }}
                                >
                                    {okayBtnLabel(status)}
                                </OkayButton>
                            </Stack>
                        )}

                        {walletGuide(status)}
                    </Box>
                )}
            </Dialog>
        </div>
    )
}

export default ListStatModal
