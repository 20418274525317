import React, { createContext, useState } from 'react'
import { useUser, useToken, useSaleNfts } from './hooks'
import { Toastify } from './connectivityAssets/Toastify'

export const AppContext = createContext()
export const AppContextProvider = ({ children }) => {
    //model connection
    const [connectModal, setConnectModal] = useState(false)
    const [isAlertModal, setAlertModal] = useState(false)

    // toast state
    const [alertState, setAlertState] = useState({
        open: false,
        message: '',
        severity: undefined,
    })

    // window width
    const [width, setWidth] = useState(window.innerWidth)
    const [tabIndex, setTabIndex] = useState(0)
    const { token, setToken } = useToken()
    const { user, setUser } = useUser(token)
    const [wallet, setWallet] = useState()

    const toggleConnectModal = () => {
        setConnectModal(!connectModal)
    }

    const isMobile = width < 768

    return (
        <AppContext.Provider
            value={{
                isMobile,
                connectModal,
                toggleConnectModal,
                isAlertModal,
                setAlertModal,
                setConnectModal,
                tabIndex,
                setTabIndex,
                userData: user,
                setUserData: setUser,
                wallet,
                setWallet,
                setToken,
                setAlertState,
            }}
        >
            <Toastify setAlertState={setAlertState} alertState={alertState} />
            {children}
        </AppContext.Provider>
    )
}
