import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
} from '@mui/material'

const ConfirmModal = ({ title, message, open, confirm, cancel }) => {
    return (
        <Dialog
            open={open}
            onClose={cancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={cancel}>
                    Cancel
                </Button>
                <Button color="error" onClick={confirm} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmModal
