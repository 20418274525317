/* eslint-disable camelcase */
import { Close, Edit, Upgrade } from '@mui/icons-material'
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../Context'
import { post, update, remove } from '../../utils/fetchApis'
import MyButton from '../Buttonone'
import { clearStorage, setStorage } from '../../utils/helper'
import { GC_BUCKET_URL } from '../../utils/constant'
import * as moment from 'moment'

const dialogInputFields = [
    {
        type: 'text',
        placeholder: 'First name',
        name: 'firstName',
    },
    {
        type: 'text',
        placeholder: 'Last name',
        name: 'lastName',
    },
    {
        type: 'number',
        placeholder: 'Phone number',
        name: 'phoneNumber',
    },
    {
        type: 'text',
        placeholder: 'Address',
        name: 'address',
    },
    {
        type: 'text',
        placeholder: 'Business name',
        name: 'username',
    },
]

const MyProfileContent = () => {
    const navigate = useNavigate()

    const { setTabIndex, setUserData, userData, setAlertState } =
        useContext(AppContext)

    const inputFields = [
        {
            label: 'Id',
            type: 'number',
            value: userData?.id,
            disable: true,
        },
        {
            label: 'Name',
            type: 'text',
            value: `${userData?.firstName} ${userData?.lastName}`,
        },
        {
            label: 'Email',
            type: 'email',
            value: userData?.email,
        },
        {
            label: 'Phone',
            type: 'number',
            value: userData?.phoneNumber,
        },
        {
            label: 'Business Name',
            type: 'text',
            value: userData?.username,
        },
        {
            label: 'Address',
            type: 'text',
            value: userData?.address,
        },
    ]

    const getAccountStatusLabel = (status) => {
        switch (status.toLowerCase()) {
            case 'active':
                return 'Active'
            case 'cancelled':
                return 'Cancelled'
            case 'approval_pending':
                return 'Pending'
        }
    }

    const userObj = [
        {
            name: 'USER ID',
            value: userData?.id,
        },
        {
            name: 'member since',
            value: moment(userData?.createdAt).format('LLL'),
        },
        {
            name: 'account status',
            value: userData?.subscription
                ? getAccountStatusLabel(userData?.subscription?.status)
                : 'Active',
        },
    ]

    const [userNewData, setUserNewData] = useState({
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        phoneNumber: userData?.phoneNumber,
        address: userData?.address,
        username: userData?.username,
    })

    const [newImg, setNewImg] = useState()
    const [open, setOpen] = React.useState(false)
    const [deleteConfirm, setDeleteConfirm] = React.useState(false)
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    const handleDeleteConfirmClose = () => {
        setDeleteConfirm(false)
    }
    const handleUserDataChange = (e) => {
        setUserNewData({ ...userNewData, [e.target.name]: e.target.value })
    }

    const alertFunction = (data) => {
        if (data?.status === '200' || data?.status === 'true') {
            return
        } else {
            return setAlertState({
                open: true,
                message: data?.error,
                severity: 'error',
            })
        }
    }

    const handleUploadImg = async () => {
        try {
            const { data, success, message } = await update(
                'users/update',
                { photo: newImg },
                true
            )
            if (!success) {
                setAlertState({
                    open: false,
                    message,
                    severity: 'error',
                })
            }

            if (data) {
                setAlertState({
                    open: true,
                    message: 'Your photo is saved!',
                    severity: 'success',
                })
                setUserData(data)
                setStorage('user', data)
                setNewImg(null)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleSubmitUserData = async () => {
        try {
            const { success, data, message } = await update(
                'users/update',
                {
                    firstName: userNewData?.firstName,
                    lastName: userNewData?.lastName,
                    phoneNumber: userNewData?.phoneNumber,
                    address: userNewData?.address,
                    username: userNewData?.username,
                },
                true
            )

            if (!success) {
                setAlertState({
                    open: false,
                    message,
                    severity: 'error',
                })
            }

            if (data) {
                setUserData(data)
                setStorage('user', data)
                setOpen(false)
                setUserNewData({
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    address: '',
                    username: '',
                })
                setDeleteConfirm(false)
                setAlertState({
                    open: true,
                    message: 'Changes are saved!',
                    severity: 'success',
                })
            }
        } catch (error) {
            setAlertState({
                open: true,
                message: error,
                severity: 'error',
            })
            console.error(error)
        }
    }
    const handleDeleteUser = async () => {
        try {
            const { success, data, message } = await remove(`users`)

            if (success) {
                setTimeout(() => {
                    setUserData(null)
                    clearStorage()
                    navigate('/')
                }, 1000)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // console.log('profile', userData)
    return (
        <>
            {/* -------------------------EDIT PROFILE START----------------------- */}
            <Dialog
                fullWidth
                maxWidth="xs"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <IconButton
                        onClick={handleClose}
                        sx={{ float: 'right' }}
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                    >
                        <Close />
                    </IconButton>
                    {dialogInputFields.map((item, i) => (
                        <TextField
                            key={i}
                            fullWidth
                            sx={{
                                borderRadius: '7px',
                                my: 1,
                                backgroundColor: '#F6F6F6',
                            }}
                            name={item?.name}
                            onChange={handleUserDataChange}
                            defaultValue={userData[item?.name]}
                            label={item.placeholder}
                            type={item.type}
                        />
                    ))}
                    <MyButton
                        sx={{ width: '100%', py: 1, mt: 1.5 }}
                        onClick={handleSubmitUserData}
                    >
                        Save changes
                    </MyButton>
                </DialogContent>
            </Dialog>
            {/* -------------------------EDIT PROFILE END----------------------- */}
            {/* -------------------------Delete Confirmation START----------------------- */}
            <Dialog
                fullWidth
                maxWidth="xs"
                open={deleteConfirm}
                onClose={handleDeleteConfirmClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <IconButton
                        onClick={handleDeleteConfirmClose}
                        sx={{ float: 'right' }}
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                    >
                        <Close />
                    </IconButton>
                    <Typography variant="h3">Are You Sure ?</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'space-between',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <MyButton
                            sx={{ width: '48%', py: 1, mt: 1.5 }}
                            onClick={handleDeleteUser}
                        >
                            Yes
                        </MyButton>
                        <MyButton
                            sx={{ width: '48%', py: 1, mt: 1.5 }}
                            onClick={handleDeleteConfirmClose}
                        >
                            No
                        </MyButton>
                    </Box>
                </DialogContent>
            </Dialog>
            {/* ------------------------- END----------------------- */}

            <Box display={'flex'} alignItems={'center'}>
                <Typography variant="h1" fontFamily="'Montserrat', sans-serif">
                    My Profile
                </Typography>
                <MyButton
                    onClick={handleClickOpen}
                    startIcon={<Edit />}
                    sx={{
                        ml: 5,
                        px: '20px',
                        fontFamily: "'Montserrat', sans-serif",
                    }}
                >
                    Edit
                </MyButton>
            </Box>
            <Grid container spacing={3} mt={1}>
                <Grid item xs={12} md={4} lg={4}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems="center"
                        rowGap={'20px'}
                    >
                        <Typography
                            variant="h4"
                            fontFamily="'Montserrat', sans-serif"
                        >
                            PHOTO
                        </Typography>

                        <Avatar
                            sx={{
                                width: '100%',
                                height: '100%',
                            }}
                            src={
                                !newImg
                                    ? `${GC_BUCKET_URL}${userData?.photo}`
                                    : URL.createObjectURL(newImg)
                            }
                            alt="user"
                        />

                        {!newImg ? (
                            <MyButton
                                component="label"
                                sx={{
                                    fontFamily: "'Montserrat', sans-serif",
                                    width: '100%',
                                }}
                            >
                                <input
                                    accept="image/*"
                                    type="file"
                                    hidden
                                    onChange={(e) =>
                                        setNewImg(e.target.files[0])
                                    }
                                />
                                Change Photo
                            </MyButton>
                        ) : (
                            <MyButton onClick={handleUploadImg}>Save</MyButton>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} md={8} lg={8} mt={3}>
                    {inputFields.map((item, i) => {
                        return (
                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                key={i}
                                mt={2}
                            >
                                <Typography
                                    width="20%"
                                    fontWeight={700}
                                    fontFamily="'Montserrat', sans-serif"
                                >
                                    {item.label}
                                </Typography>
                                <TextField
                                    sx={{
                                        borderRadius: '7px',
                                        width: '80%',
                                        ml: { xs: 1, md: 3 },
                                        backgroundColor: '#F6F6F6',
                                    }}
                                    value={item.value}
                                    type={item.type}
                                />
                            </Box>
                        )
                    })}
                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        flexDirection={{
                            xs: 'column',
                            md: 'row',
                        }}
                        rowGap={'20px'}
                        alignItems={'center'}
                        mt={2}
                    >
                        <Typography
                            variant="h4"
                            fontFamily="'Montserrat', sans-serif"
                        >
                            Membership Plan
                        </Typography>
                        <Button
                            sx={{
                                background: '#F6F6F6;',
                                px: '20px',
                                fontFamily: "'Montserrat', sans-serif",
                            }}
                        >
                            {userData?.plan?.name}
                        </Button>
                        <MyButton
                            onClick={() => setTabIndex(2)}
                            startIcon={<Upgrade />}
                            sx={{
                                px: '20px',
                                fontFamily: "'Montserrat', sans-serif",
                            }}
                        >
                            Upgrade{' '}
                        </MyButton>
                    </Box>
                </Grid>
            </Grid>
            <Box>
                <Grid container spacing={3} rowSpacing={3} mt={2}>
                    {userObj.map(({ name, value }, i) => {
                        return (
                            <Grid item xs={12} md={3} key={i}>
                                <Typography
                                    variant="h4"
                                    textTransform={'uppercase'}
                                    fontFamily="'Montserrat', sans-serif"
                                >
                                    {name}
                                </Typography>
                                <Typography
                                    mt={2}
                                    textTransform={'uppercase'}
                                    color={'black'}
                                    fontFamily="'Montserrat', sans-serif"
                                >
                                    {value}
                                </Typography>
                            </Grid>
                        )
                    })}
                    <Grid item xs={12} md={3}>
                        <Typography
                            variant="h4"
                            textTransform={'uppercase'}
                            fontFamily="'Montserrat', sans-serif"
                        >
                            Close Account
                        </Typography>
                        <Typography
                            mt={2}
                            textTransform={'uppercase'}
                            color="red"
                            fontFamily="'Montserrat', sans-serif"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => setDeleteConfirm(true)}
                        >
                            Delete Account
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default MyProfileContent
