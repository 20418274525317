import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function UploadSuccess() {
    const navigate = useNavigate()

    return (
        <Box
            sx={{
                background: '#fff',
                p: '32px',
                px: '24px',
                borderRadius: '12px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: '20px',
                    mt: '63px',
                }}
            >
                <TaskAltOutlinedIcon
                    sx={{ fontSize: '80px', color: '#6FDA44' }}
                />
            </Box>
            <Typography
                variant="h2"
                sx={{
                    textAlign: 'center',
                    fontSize: '28px !important',
                    color: '#2A3538',
                    mb: '20px',
                }}
            >
                Thank you for uploading to our site
            </Typography>
            <Typography
                sx={{
                    textAlign: 'center',
                    fontSize: '20px !important',
                    color: '#2A3538',
                    mb: '63px',
                    fontWeight: '400 !important',
                }}
            >
                A member of our team will review and publish it within 24 hours.
            </Typography>

            <Box>
                <Button
                    sx={{
                        padding: '10px 32px',
                        fontWeight: '700',
                        color: '#2A3538',
                        borderRadius: '12px',
                        width: '100%',
                        backgroundColor: '#6FDA44',
                        gap: '4px',
                    }}
                    type="button"
                    onClick={() => navigate('/')}
                >
                    <KeyboardBackspaceIcon />
                    Return to Homepage
                </Button>
            </Box>
        </Box>
    )
}

export default UploadSuccess
