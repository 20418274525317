import { Box, Button, Dialog, DialogContent, Slide } from '@mui/material'
import React, { useContext } from 'react'
import { polygon, polygonMumbai, polygonAmoy } from 'wagmi/chains'
import { DEV_MODE } from './utils/constant'
import { AppContext } from './Context'
import { switchChain } from '@wagmi/core'
import { config } from './utils/wagiConfig'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})
function NetworkChange({ open, setOpen }) {
    const { setAlertState } = useContext(AppContext)

    const handleClose = () => {
        setOpen(false)
    }
    const networkHandler = async () => {
        try {
            await switchChain(config, {
                chainId: DEV_MODE ? polygonAmoy.id : polygon.id,
            })
            setOpen(false)
        } catch (error) {
            // console.log(error)
            // console.log(JSON.stringify(error));
        }
    }
    return (
        <div>
            <div className="modal__main__container">
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    sx={{
                        '.css-1uf0yja-MuiPaper-root-MuiDialog-paper': {
                            borderRadius: '10px',
                        },
                    }}
                >
                    <DialogContent
                        sx={{ backgroundColor: '#6FDA44' }}
                        className="dialoge__content__section"
                    >
                        <Box component="h3" color="text.primary">
                            <Box component="span" color="red" fontSize="30px">
                                Error!
                            </Box>{' '}
                            You are on wrong network please switch your network.
                        </Box>
                        <Box align="center">
                            <Button
                                variant="button"
                                sx={{ py: 1.5, width: '180px', color: '#fff' }}
                                onClick={networkHandler}
                            >
                                Switch Network
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
}

export default NetworkChange
