import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Dashboard,
    ManageAccounts,
    Payment,
    Person,
    Upgrade,
    Upload,
} from '@mui/icons-material'
import {
    Avatar,
    Badge,
    Box,
    Button,
    Container,
    Grid,
    Paper,
    Tab,
    Tabs,
    Typography,
    styled,
} from '@mui/material'
import { googleLogout } from '@react-oauth/google'
import PropTypes from 'prop-types'
import { AppContext } from '../Context'
import edit from '../assets/editicon.png'
import {
    DashboardContent,
    Management,
    MyProfileContent,
    TransactionsContent,
    UpgradePlan,
    UploadContent,
} from '../components'
import { GC_BUCKET_URL } from '../utils/constant'

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 30,
    height: 30,
    border: `2px solid ${theme.palette.background.paper}`,
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    }
}

const AdminDashboard = () => {
    const { tabIndex, setTabIndex, userData, setUserData } =
        useContext(AppContext)
    const navigate = useNavigate()

    let dashboardNames = [
        {
            name: 'Dashboard',
            icon: Dashboard,
        },
        {
            name: 'My Profile',
            icon: Person,
        },
        {
            name: 'Upgrade Plan',
            icon: Upgrade,
        },
        {
            name: 'Upload content',
            icon: Upload,
        },
        {
            name: 'Transactions',
            icon: Payment,
        },
    ]

    if (userData?.role === 'admin') {
        dashboardNames.push({
            name: 'Admin',
            icon: ManageAccounts,
        })
    }

    const handleChange = (_event, newValue) => {
        setTabIndex(newValue)
    }
    const handleLogOut = () => {
        googleLogout()
        localStorage.clear()
        sessionStorage.clear()
        setUserData(null)
        navigate('/')
    }

    console.log(userData)
    return (
        <>
            <Container
                maxWidth="lg"
                sx={{
                    my: 4,
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={3}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 1,
                                display: 'flex',
                                rowGap: '5px',
                                flexDirection: 'column',
                                borderRadius: '10px',
                            }}
                        >
                            <Box
                                display={'flex'}
                                justifyContent={'center'}
                                mt={2}
                            >
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    badgeContent={
                                        <SmallAvatar alt="edit" src={edit} />
                                    }
                                >
                                    <Avatar
                                        alt="Remy Sharp"
                                        src={GC_BUCKET_URL + userData?.photo}
                                        sx={{
                                            width: 180,
                                            height: 180,
                                            margin: 'auto',
                                        }}
                                    />
                                </Badge>
                            </Box>
                            <Typography
                                fontSize={'1.2rem'}
                                fontWeight={700}
                                textAlign="center"
                            >
                                {userData?.first_name?.slice(0, 14)}
                            </Typography>
                            <Typography
                                fontSize={'1rem'}
                                fontWeight={400}
                                textAlign="center"
                            >
                                {userData?.email}
                            </Typography>
                            <Button
                                disableRipple
                                sx={{
                                    color: '#6FDA44',
                                    '&:hover': {
                                        backgroundColor: '#6FDA44',
                                        color: '#fff',
                                    },
                                }}
                                onClick={handleLogOut}
                            >
                                Logout
                            </Button>
                            <Tabs
                                orientation="vertical"
                                indicatorColor=""
                                aria-label="icon position tabs example"
                                variant="scrollable"
                                allowScrollButtonsMobile
                                value={tabIndex}
                                onChange={handleChange}
                                sx={{
                                    width: '100%',
                                    '& .MuiTabs-indicator': {
                                        display: 'none',
                                    },
                                }}
                            >
                                {dashboardNames.map((item, index) => (
                                    <Tab
                                        key={index}
                                        label={item.name}
                                        icon={<item.icon />}
                                        iconPosition="start"
                                        {...a11yProps(index)}
                                        sx={{
                                            justifyContent: 'flex-start',
                                            backgroundColor: '#F6F6f6',
                                            borderRadius: '10px',
                                            columnGap: '10px',
                                            my: '10px',
                                            minHeight: '0px',
                                            '& .MuiTab-wrapper': {
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                gap: '10px',
                                            },

                                            '&.Mui-selected': {
                                                backgroundColor: '#6FDA44',
                                                color: '#fff',
                                                borderRadius: '10px',
                                            },
                                            fontFamily:
                                                "'Montserrat', sans-serif",
                                            fontSize: '16px',
                                            fontWeight: 700,
                                        }}
                                    />
                                ))}
                            </Tabs>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={8} md={9} lg={9}>
                        <TabPanel value={tabIndex} index={0}>
                            <DashboardContent />
                        </TabPanel>

                        <TabPanel value={tabIndex} index={1}>
                            <MyProfileContent />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={2}>
                            <UpgradePlan />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={3}>
                            <UploadContent />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={4}>
                            <TransactionsContent />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={5}>
                            <Management />
                        </TabPanel>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default AdminDashboard
