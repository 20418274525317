import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import {
    Box,
    Button,
    Container,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import PricingMobile from '../assets/pricing-plan-mobile.png'
import Pricing from '../assets/pricing-plan.png'
import useDocumentTitle from '../useDocumentTitle'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

const ContentData = [
    {
        title: 'ARTIST',
        description:
            'This plan is perfectly tailored for newcomers to Sniser, offering a comprehensive introduction to our services and our distinctive business approach. To get started, individuals will require a crypto wallet with sufficient Polygon tokens to mint NFTs.',
        price: 'Free',
        items: [
            {
                value: 'Artist account',
                active: true,
            },
            {
                value: 'Upload maximum of 10 media files',
                active: true,
            },
            {
                value: 'Time limited upload',
                active: true,
            },
            {
                value: 'Limited NFT support',
                active: true,
            },
            {
                value: 'Priority listing of your content on our website',
                active: false,
            },
            {
                value: 'Crypto wallet is not needed to mint, assistance will be provided',
                active: false,
            },
        ],
    },
    {
        title: 'RECORD LABEL',
        description:
            " This plan caters specifically to users or labels aiming to upload larger quantities of NFTs, offering enhanced benefits within the Sniser platform. It strikes the optimal balance between cost-effectiveness and value, providing users with the most advantageous package. To utilize this plan's features, a crypto wallet with sufficient Polygon tokens is needed to mint NFTs.",
        price: '$9',
        time: '/month',
        items: [
            {
                value: 'Record Label account',
                active: true,
            },
            {
                value: 'Upload maximum of 100 media files',
                active: true,
            },
            {
                value: 'Full NFT support',
                active: true,
            },
            {
                value: 'Assistance with image selection',
                active: true,
            },
            {
                value: 'Email support for users',
                active: true,
            },
            {
                value: 'Priority listing of your content on our website',
                active: true,
            },
            {
                value: 'Crypto wallet is not needed to mint, assistance will be provided',
                active: false,
            },
        ],
    },
    {
        title: 'MAJOR LABEL',
        description:
            " This plan is crafted for users who have already reaped the rewards of our service and are actively leveraging our platform to upload content. With this subscription, we offer comprehensive assistance, including full support for minting NFTs, even if the user doesn't possess a crypto wallet.",
        price: '$19',
        time: '/month',
        items: [
            {
                value: 'Major Label account',
                active: true,
            },
            {
                value: 'Upload maximum of 1.000 media files',
                active: true,
            },
            {
                value: 'Full NFT support',
                active: true,
            },
            {
                value: 'Assistance with image selection',
                active: true,
            },
            {
                value: 'Email support for users',
                active: true,
            },
            {
                value: 'Priority listing of your content on our website',
                active: true,
            },
            {
                value: 'Crypto wallet is not needed to mint, assistance will be provided',
                active: true,
            },
        ],
    },
]

function Advertise() {
    useDocumentTitle(
        'Sniser provides NFT protected content to ensure artist payment.'
    )
    const matches = useMediaQuery('(max-width:600px)')

    const navigate = useNavigate()

    return (
        <>
            <Box sx={{ backgroundColor: '#F4F5F5' }}>
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexDirection: { xs: 'column-reverse', md: 'row' },
                            paddingY: { xs: '39px', sm: '63px' },
                        }}
                    >
                        <Box sx={{ maxWidth: '477px' }}>
                            <Typography
                                variant="h2"
                                sx={{
                                    mb: '20px',
                                    fontSize: '32px !important',
                                    color: '#2A3538',
                                    textTransform: 'uppercase',
                                }}
                            >
                                Advertise
                            </Typography>
                            <Typography
                                sx={{
                                    mb: '31px',
                                    fontWeight: '400 !important',
                                }}
                            >
                                Our marketplace allows you to show what your
                                content is all about. With our subscription
                                packages, you can alter the amount of
                                non-fungible tokens (NFT) that are posted on in
                                our marketplace. Choose the one that reflects
                                how popular you believe your content is going to
                                be to your audience. You can start with our free
                                service and upgrade as required.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}
                            >
                                <Button
                                    sx={{
                                        background: '#2A3538',
                                        fontWeight: 700,
                                        color: '#ffffff',
                                        textTransform: 'capitalize',
                                        padding: '10px 40px',
                                    }}
                                    onClick={() => navigate('/sign-up')}
                                >
                                    Sign Up
                                </Button>
                                <ArrowOutwardIcon
                                    sx={{ color: '#2A3538', fontSize: '24px' }}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: { xs: '345px', md: '444px' },
                                height: { xs: 'auto', md: '400px' },
                                mb: { xs: '23px', md: 0 },
                            }}
                        >
                            <img
                                src={matches ? PricingMobile : Pricing}
                                alt=""
                                style={{ objectFit: 'cover' }}
                            />
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box
                sx={{
                    paddingTop: { xs: '40px', md: '116px' },
                    paddingBottom: { xs: '40px', md: '160px' },
                }}
            >
                <Container>
                    <Grid container spacing={'25px'}>
                        {ContentData.map((item, index) => (
                            <Grid key={index.toString()} item xs={12} md={4}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            height: {
                                                xs: index === 1 ? '44px' : 0,
                                                md: '44px',
                                            },
                                            borderTopLeftRadius: '8px',
                                            borderTopRightRadius: '8px',
                                            background:
                                                index === 1 ? '#2A3538' : '',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {index === 1 && (
                                            <Typography
                                                sx={{
                                                    textAlign: 'center',
                                                    color: '#ffffff',
                                                    fontWeight: 700,
                                                }}
                                            >
                                                POPULAR
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            borderRadius: '8px',
                                            background:
                                                index === 1
                                                    ? '#6FDA44'
                                                    : '#2A3538',
                                            pt: '32px',
                                            paddingX: '24px',
                                            borderTopRightRadius:
                                                index === 1 ? 0 : '8px',
                                            borderTopLeftRadius:
                                                index === 1 ? 0 : '8px',
                                            mb: 'auto',
                                            height: '100%',
                                            paddingBottom: '32px',
                                        }}
                                    >
                                        <Typography
                                            variant="h3"
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: '20px',
                                                textAlign: 'center',
                                                color:
                                                    index === 1
                                                        ? '#2A3538'
                                                        : '#ffffff',
                                                mb: '12px',
                                            }}
                                        >
                                            {item.title}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontWeight: '400 !important',
                                                textAlign: 'center',
                                                color:
                                                    index === 1
                                                        ? '#2A3538'
                                                        : '#ffffff',
                                                minHeight: '240px',
                                                mb: '24px',
                                            }}
                                        >
                                            {item.description}
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                textAlign: 'center',
                                                color:
                                                    index === 1
                                                        ? '#2A3538'
                                                        : '#ffffff',
                                                fontSize: '32px',
                                                mb: '24px',
                                            }}
                                        >
                                            {item.price}
                                            <Typography
                                                variant="span"
                                                sx={{
                                                    fontSize: '16px',
                                                    ml: '8px',
                                                }}
                                            >
                                                {item.time}
                                            </Typography>
                                        </Typography>
                                        <Button
                                            sx={{
                                                width: '100%',
                                                borderRadius: '4px',
                                                paddingY: '12px',
                                                background:
                                                    index !== 1
                                                        ? '#6FDA44'
                                                        : '#2A3538',
                                                fontSize: '14px',
                                                fontWeight: 700,
                                                lineHeight: '17px',
                                                color:
                                                    index === 1
                                                        ? '#ffffff'
                                                        : '#2A3538',
                                                mb: '32px',
                                                textTransform: 'capitalize',
                                            }}
                                            onClick={() => navigate('/sign-up')}
                                        >
                                            Subscribe
                                        </Button>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '24px',
                                            }}
                                        >
                                            {item.items.map(
                                                (item, childIndex) => (
                                                    <Box
                                                        key={childIndex.toString()}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            gap: '12px',
                                                        }}
                                                    >
                                                        {item.active ? (
                                                            <CheckCircleIcon
                                                                sx={{
                                                                    color:
                                                                        index ===
                                                                        1
                                                                            ? '#2A3538'
                                                                            : '#6FDA44',
                                                                }}
                                                            />
                                                        ) : (
                                                            <CancelIcon
                                                                sx={{
                                                                    opacity:
                                                                        index ===
                                                                        1
                                                                            ? '50%'
                                                                            : '40%',
                                                                    color:
                                                                        index ===
                                                                        1
                                                                            ? '#2A3538'
                                                                            : '#ffffff',
                                                                }}
                                                            />
                                                        )}
                                                        <Typography
                                                            fontWeight={700}
                                                            fontSize={'14px'}
                                                            sx={{
                                                                color:
                                                                    index === 1
                                                                        ? '#2A3538'
                                                                        : '#ffffff',
                                                                opacity:
                                                                    item.active
                                                                        ? 1
                                                                        : '40%',
                                                            }}
                                                        >
                                                            {item.value}
                                                        </Typography>
                                                    </Box>
                                                )
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
            <Box
                sx={{
                    backgroundColor: '#6FDA44',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    py: { xs: '40px', md: '80px' },
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        mb: { xs: '8px', sm: '12px' },
                        fontSize: {
                            xs: '24px !important',
                            sm: '32px !important',
                        },
                        textAlign: 'center',
                        color: '#2A3538',
                    }}
                >
                    INFORMATION
                </Typography>
                <Typography
                    sx={{
                        color: '#2A3538',
                        textAlign: 'center',
                        maxWidth: '813px',
                        mb: { xs: '20px', md: '16px' },
                    }}
                >
                    All subscriptions entail recurring payments processed
                    through Moonpay, which will be reflected on your statements
                    as "Sniser." To terminate a paid subscription, just switch
                    your plan to the "artist" tier. For additional details,
                    please refer to our website's terms and conditions page.
                </Typography>
                <Button
                    sx={{
                        background: '#2A3538',
                        fontWeight: 700,
                        color: '#ffffff',
                        textTransform: 'capitalize',
                        padding: '12px 36px',
                        fontSize: '14px',
                    }}
                    onClick={() => navigate('/terms-and-conditions')}
                >
                    Terms and Conditions
                </Button>
            </Box>
        </>
    )
}

export default Advertise
