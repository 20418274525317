import { useCallback, useContext, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
    Button,
    Divider,
    Input,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Stack,
    SvgIcon,
    TableFooter,
    TablePagination,
} from '@mui/material'
import VerifiedIcon from '@mui/icons-material/Verified'
import ContentCopy from '@mui/icons-material/ContentCopy'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Paper from '@mui/material/Paper'
import { useAdminNfts } from 'src/hooks'
import { update } from 'src/utils/fetchApis'
import { AppContext } from 'src/Context'
import { GC_BUCKET_URL, NFT_STATUS } from 'src/utils/constant'
import * as moment from 'moment'

const NftManagement = () => {
    const { setAlertState } = useContext(AppContext)
    const [pagination, setPagination] = useState({
        page: 0,
        size: 5,
        search: '',
    })
    const { nftList, count, refetch } = useAdminNfts({ pagination })
    const [anchorEl, setAnchorEl] = useState(null)
    const [selected, setSelected] = useState(-1)

    const open = Boolean(anchorEl)

    const handleClick = (event, idx) => {
        setAnchorEl(event.currentTarget)
        setSelected(idx)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setSelected(-1)
    }

    const handleCopy = (nft) => {
        const url = `${window.location.origin}/nft/${nft.id}`
        navigator.clipboard.writeText(url)

        setAlertState({
            open: true,
            message: 'Copied!',
            severity: 'info',
        })
    }

    const handleUpdate = async (nft, status) => {
        const { success, message } = await update(`nfts/${nft.id}`, {
            status,
        })

        if (!success) {
            setAlertState({
                open: true,
                message,
                serverity: 'error',
            })
            return
        }

        setAlertState({
            open: true,
            message:
                status === 'verify'
                    ? 'Success to verify nft'
                    : 'Success to hide nft',
            serverity: 'success',
        })

        refetch(pagination)
    }

    const handleChangePage = (event, newPage) => {
        setPagination({ ...pagination, page: newPage })
    }

    const handleChangeRowsPerPage = (event) => {
        setPagination({ ...pagination, size: event.target.value, page: 0 })
    }

    // console.log(nftList)

    return (
        <>
            <Stack direction="row" className="my-2">
                <Input
                    placeholder="Search Nft"
                    value={pagination.search}
                    onChange={(e) =>
                        setPagination({ ...pagination, search: e.target.value })
                    }
                />

                {/* <Button sx={{marginLeft: 'auto'}} onClick={handleAdd}>Add plan</Button> */}
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Owner</TableCell>
                            <TableCell>Wallet</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Minted At</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {nftList.map((nft, idx) => {
                            const owner = nft.wallet.userWallets[0].user
                            return (
                                <TableRow
                                    key={nft.id}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell>
                                        <img
                                            src={`${GC_BUCKET_URL}${nft?.image}`}
                                            alt="nft"
                                            style={{
                                                width: '70px',
                                                height: 'auto',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {nft.title} #{nft.tokenId}
                                    </TableCell>
                                    <TableCell>{nft.price}</TableCell>
                                    <TableCell>{`${owner.firstName} ${owner.lastName}`}</TableCell>
                                    <TableCell>
                                        {nft.wallet.address.substr(0, 10)}...
                                    </TableCell>
                                    <TableCell>{nft.status}</TableCell>
                                    <TableCell>
                                        {moment(nft.createdAt).format('LLL')}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={(evt) =>
                                                handleClick(evt, idx)
                                            }
                                        >
                                            <SvgIcon>
                                                {/* { THREE_DOT_VERTICAL } */}
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="black"
                                                    className="bi bi-three-dots-vertical"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                                </svg>
                                            </SvgIcon>
                                        </Button>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={idx === selected}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby':
                                                    'basic-button',
                                            }}
                                        >
                                            <MenuItem
                                                onClick={(_) => handleCopy(nft)}
                                            >
                                                <ListItemIcon>
                                                    <ContentCopy fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    Copy link
                                                </ListItemText>
                                            </MenuItem>
                                            {nft.status === NFT_STATUS.MINT && (
                                                <MenuItem
                                                    onClick={(_) =>
                                                        handleUpdate(
                                                            nft,
                                                            'verify'
                                                        )
                                                    }
                                                >
                                                    <ListItemIcon>
                                                        <VerifiedIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        Verify
                                                    </ListItemText>
                                                </MenuItem>
                                            )}
                                            {nft.status !==
                                                NFT_STATUS.INACTIVE && (
                                                <MenuItem
                                                    onClick={(_) =>
                                                        handleUpdate(
                                                            nft,
                                                            'inactive'
                                                        )
                                                    }
                                                >
                                                    <ListItemIcon>
                                                        <VisibilityOffIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        Hide
                                                    </ListItemText>
                                                </MenuItem>
                                            )}
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                    <TableFooter>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            count={count}
                            rowsPerPage={pagination.size}
                            page={pagination.page}
                            slotProps={{
                                select: {
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                },
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        ></TablePagination>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    )
}

export default NftManagement
