import './Layout.scss'

import {
    CloseSharp,
    Dashboard,
    ManageAccounts,
    Payment,
    Person,
    Upgrade,
    Upload,
} from '@mui/icons-material'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import PersonIcon from '@mui/icons-material/Person'
import {
    Avatar,
    Badge,
    Box,
    Button,
    Container,
    Menu,
    MenuItem,
    styled,
    Tab,
    Tabs,
    Typography,
} from '@mui/material'
import { disconnect } from '@wagmi/core'
import axios from 'axios'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setAuthHeader } from 'src/utils/fetchApis'
import { useAccount } from 'wagmi'

import { AppContext } from '../Context'
import { useToken } from '../hooks'
import { GC_BUCKET_URL } from '../utils/constant'
import { config } from '../utils/wagiConfig'
import ConnectionModal from './Modal/ConnectModal'

const dashboardNames = [
    {
        name: 'Dashboard',
        icon: Dashboard,
    },
    {
        name: 'My Profile',
        icon: Person,
    },
    {
        name: 'Upgrade Plan',
        icon: Upgrade,
    },
    {
        name: 'Upload content',
        icon: Upload,
    },
    {
        name: 'Transactions',
        icon: Payment,
    },
    {
        name: 'Admin',
        icon: ManageAccounts,
    },
    {
        name: 'Logout',
        icon: CloseSharp,
    },
]

const MyButton = styled(Button)({
    backgroundColor: '#6FDA44',
    color: 'white',
    borderRadius: ' 10px',
    '&:hover': {
        backgroundColor: '#F6F6f6',
        color: 'black',
        transition: '1s',
    },
})

const Header = () => {
    const navigate = useNavigate()

    const { token } = useToken()
    const { isConnected, address } = useAccount()
    const [fixed, setFixed] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)
    const {
        tabIndex,
        setTabIndex,
        setUserData,
        userData,
        setWallet,
        toggleConnectModal,
    } = useContext(AppContext)
    const [anchorEl, setAnchorEl] = useState(null)

    const isLogged = useMemo(
        () => userData && Object.keys(userData).length !== 0,
        [userData]
    )
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        }
    }

    const handleLogOut = () => {
        setAnchorEl(null)
        localStorage.clear()
        sessionStorage.clear()
        disconnect(config)
        setUserData(null)
        navigate('/')
        axios.defaults.headers.common['Authorization'] = null
    }

    const handleChange = (name: string, index: number) => {
        if (name === 'Logout') {
            handleLogOut()

            return
        }
        setTabIndex(index)
        navigate('/myaccount')
        setAnchorEl(null)
    }

    const renderRightActions = () => {
        return (
            <>
                <MenuItem sx={{ m: 0, p: 0, pl: '14px' }}>
                    <button
                        style={{
                            backgroundColor: 'transparent',
                            textTransform: 'capitalize',
                            color: '#ffffff',
                            fontWeight: 500,
                            fontFamily: "'Montserrat', sans-serif",
                            padding: 0,
                        }}
                        className="nav-link h-btn"
                        onClick={() => {
                            navigate('/shop')
                            setOpenMenu(false)
                        }}
                    >
                        Explore Content
                    </button>
                </MenuItem>
                <MenuItem sx={{ m: 0, p: 0, pl: '14px' }}>
                    <Button
                        sx={{
                            border: '1px solid #ffffff',
                            borderRadius: '4px',
                            textTransform: 'initial',
                            fontFamily: "'Montserrat', sans-serif",
                            color: '#ffffff',
                            paddingX: '10px',
                            fontWeight: 500,
                        }}
                        onClick={() => {
                            navigate('/advertise')
                            setOpenMenu(false)
                        }}
                    >
                        Advertise <ArrowOutwardIcon />
                    </Button>
                </MenuItem>
                {isConnected ? (
                    <MenuItem>
                        <Box>
                            <MyButton
                                onClick={() => {
                                    disconnect(config)
                                    setOpenMenu(false)
                                }}
                                sx={{ width: '140px' }}
                            >
                                {address?.slice(0, 4) +
                                    '.... ' +
                                    address?.slice(-4)}{' '}
                            </MyButton>
                        </Box>
                    </MenuItem>
                ) : (
                    <MenuItem sx={{ m: 0, p: 0, pl: '14px' }}>
                        <Button
                            onClick={() => {
                                toggleConnectModal(true)
                                setOpenMenu(false)
                            }}
                            sx={{
                                border: '1px solid #6FDA44',
                                borderRadius: '4px',
                                textTransform: 'initial',
                                fontFamily: "'Montserrat', sans-serif",
                                color: '#6FDA44',
                                fontWeight: 500,
                            }}
                        >
                            Connect Wallet <ArrowOutwardIcon />
                        </Button>
                    </MenuItem>
                )}
                <Box
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            height: '35px',
                            width: '1px',
                            marginX: '20px',
                            background: '#F4F5F5',
                        }}
                    />
                </Box>

                {isLogged ? (
                    <MenuItem sx={{ pl: { xs: '14px', md: 0 } }}>
                        <Button
                            sx={{
                                minWidth: '150px',
                                border: '1px solid #6FDA44',
                                borderRadius: '4px',
                                textTransform: 'initial',
                                fontFamily: "'Montserrat', sans-serif",
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                            }}
                            id="basic-button"
                            aria-controls={'basic-menu'}
                            aria-haspopup="true"
                            aria-expanded={'true'}
                            onClick={handleClick}
                        >
                            <PersonIcon
                                sx={{ color: '#6FDA44', fontSize: '20px' }}
                            />
                            Hi, {userData?.username}
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            sx={{ top: { xs: '-195px', md: 0 } }}
                        >
                            <Box
                                sx={{
                                    p: 1,
                                    display: 'flex',
                                    rowGap: '5px',
                                    flexDirection: 'column',
                                    borderRadius: '10px',
                                }}
                            >
                                <Box
                                    display={'flex'}
                                    justifyContent={'center'}
                                    mt={2}
                                >
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={
                                                GC_BUCKET_URL + userData?.photo
                                            }
                                            sx={{
                                                width: 150,
                                                height: 150,
                                            }}
                                        />
                                    </Badge>
                                </Box>
                                <Typography
                                    fontSize={'1.2rem'}
                                    fontWeight={700}
                                    textAlign="center"
                                >
                                    {userData?.first_name?.slice(0, 14)}
                                </Typography>
                                <Typography
                                    fontSize={'1rem'}
                                    fontWeight={400}
                                    textAlign="center"
                                >
                                    {userData?.email}
                                </Typography>
                            </Box>

                            <Tabs
                                orientation="vertical"
                                aria-label="icon position tabs example"
                                variant="scrollable"
                                allowScrollButtonsMobile
                                value={tabIndex}
                                sx={{
                                    width: '100%',
                                    '& .MuiTabs-indicator': {
                                        display: 'none',
                                    },
                                    px: 1,
                                }}
                            >
                                {dashboardNames.map((item, index) =>
                                    item.name === 'Admin' &&
                                    userData.role === 'user' ? (
                                        <></>
                                    ) : (
                                        <Tab
                                            key={index}
                                            label={item.name}
                                            icon={<item.icon />}
                                            iconPosition="start"
                                            {...a11yProps(index)}
                                            sx={{
                                                justifyContent: 'flex-start',
                                                backgroundColor: '#F6F6f6',
                                                borderRadius: '10px',
                                                columnGap: '1px',
                                                my: '2px',
                                                minHeight: '0px',
                                                '& .MuiTab-wrapper': {
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                        'flex-start',
                                                    gap: '10px',
                                                },

                                                '&.Mui-selected': {
                                                    backgroundColor: '#6FDA44',
                                                    color: '#fff',
                                                    borderRadius: '10px',
                                                },
                                                fontFamily:
                                                    "'Montserrat', sans-serif",
                                                fontSize: '16px',
                                                fontWeight: 700,
                                            }}
                                            onClick={() =>
                                                handleChange(item.name, index)
                                            }
                                        />
                                    )
                                )}
                            </Tabs>
                        </Menu>
                    </MenuItem>
                ) : (
                    <>
                        <MenuItem
                            sx={{ m: 0, p: 0, pl: { xs: '14px', md: 0 } }}
                        >
                            <MyButton
                                onClick={() => navigate('/sign-up')}
                                sx={{
                                    color: '#2A3538',
                                    fontWeight: 500,
                                    textTransform: 'initial',
                                    width: '119px',
                                    fontFamily: "'Montserrat', sans-serif",
                                    borderRadius: '4px',
                                }}
                            >
                                Sign up
                            </MyButton>
                        </MenuItem>
                        <MenuItem sx={{ m: 0, p: 0, pl: '14px' }}>
                            <Button
                                onClick={() => navigate('/login')}
                                sx={{
                                    width: '108px',
                                    border: '1px solid #6FDA44',
                                    borderRadius: '4px',
                                    textTransform: 'initial',
                                    fontFamily: "'Montserrat', sans-serif",
                                }}
                            >
                                Log in
                            </Button>
                        </MenuItem>
                    </>
                )}
            </>
        )
    }

    const toShop = (value) => {
        navigate({ pathname: '/shop', search: `?filter=${value}` })
    }

    useEffect(() => {
        const onScroll = async (event: any) => {
            const { scrollTop } = event.target.scrollingElement
            if (scrollTop > 160) {
                setFixed(true)
            } else {
                setFixed(false)
            }
        }

        document.addEventListener('scroll', onScroll)
        return () => {
            document.removeEventListener('scroll', onScroll)
        }
    }, [])

    useEffect(() => {
        if (token) {
            setAuthHeader(token)
        }
    }, [token])

    useEffect(() => {
        // console.log(address, userData)
        if (isConnected) {
            if (!userData) {
                disconnect(config)
            } else {
                const wallet = userData.wallets.find(
                    (w) => w.wallet.address === address
                )
                setWallet(wallet?.wallet)
            }
        }
    }, [userData, isConnected])

    // console.log('user', userData)
    return (
        <>
            <ConnectionModal />
            <header className={`navbar-area ${fixed && 'is-sticky'}`}>
                <Container>
                    <Box className="main-navbar">
                        <Box sx={{ padding: '16px 0' }}>
                            <nav className="navbar navbar-expand-md">
                                <a href="/">
                                    <img
                                        src="/assets/images/logo-header.jpeg"
                                        alt=""
                                        style={{ maxWidth: '150px' }}
                                    />
                                </a>
                                <ul
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '16px',
                                        marginLeft: '10px',
                                        color: '#fff',
                                        fontSize: '16px',
                                    }}
                                >
                                    <MenuItem
                                        sx={{
                                            fontFamily:
                                                "'Montserrat', sans-serif",
                                            paddingX: 1,
                                        }}
                                        onClick={(_) => toShop('video')}
                                    >
                                        Video
                                    </MenuItem>
                                    <MenuItem
                                        sx={{
                                            fontFamily:
                                                "'Montserrat', sans-serif",
                                            paddingX: 1,
                                        }}
                                        onClick={(_) => toShop('audio')}
                                    >
                                        Audio
                                    </MenuItem>
                                    <MenuItem
                                        sx={{
                                            fontFamily:
                                                "'Montserrat', sans-serif",
                                            paddingX: 1,
                                            color: '#fff',
                                        }}
                                        onClick={(_) => toShop('original')}
                                    >
                                        Original
                                    </MenuItem>
                                    <MenuItem
                                        sx={{
                                            fontFamily:
                                                "'Montserrat', sans-serif",
                                            paddingX: 1,
                                            color: '#fff',
                                        }}
                                        onClick={(_) => toShop('resale')}
                                    >
                                        Resale
                                    </MenuItem>
                                </ul>
                                <div
                                    className="collapse navbar-collapse mean-menu"
                                    id="navbarSupportedContent"
                                >
                                    <ul className="navbar-nav">
                                        {renderRightActions()}
                                    </ul>
                                </div>
                            </nav>
                        </Box>
                    </Box>
                </Container>

                <div className="main-responsive-nav">
                    <div className="container">
                        <div className="main-responsive-menu mean-container">
                            <div className="mean-bar">
                                {openMenu ? (
                                    <button
                                        className="meanmenu-reveal meanclose"
                                        style={{
                                            right: '0px',
                                            left: 'auto',
                                            textAlign: 'center',
                                            textIndent: '0px',
                                            fontSize: '18px',
                                            zIndex: 1001,
                                        }}
                                        onClick={() => setOpenMenu(false)}
                                    >
                                        X
                                    </button>
                                ) : (
                                    <button
                                        className="meanmenu-reveal"
                                        style={{
                                            right: 0,
                                            left: 'auto',
                                            zIndex: 1001,
                                        }}
                                        onClick={() => setOpenMenu(true)}
                                    >
                                        <span>
                                            <span>
                                                <span></span>
                                            </span>
                                        </span>
                                    </button>
                                )}

                                <nav className="mean-nav">
                                    <ul
                                        className={`navbar-nav ${openMenu ? 'd-flex' : 'd-none'}`}
                                    >
                                        {userData && (
                                            <MenuItem
                                                sx={{ color: '#6FDA44' }}
                                                onClick={() => {
                                                    navigate('/myaccount')
                                                    setOpenMenu(false)
                                                }}
                                            >
                                                Dashboard
                                            </MenuItem>
                                        )}
                                        {userData && (
                                            <MenuItem>
                                                <MyButton
                                                    onClick={handleLogOut}
                                                >
                                                    LogOut
                                                </MyButton>
                                            </MenuItem>
                                        )}
                                        {renderRightActions()}
                                    </ul>
                                </nav>
                            </div>

                            <div
                                className="logo"
                                style={{ zIndex: 1000, width: '92%' }}
                            >
                                <a href="/">
                                    <img
                                        style={{
                                            width: '150px',
                                            height: '46px',
                                            zIndex: 100,
                                        }}
                                        src="/assets/images/logo-header.jpeg"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header
