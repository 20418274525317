import { useRef, useEffect } from 'react'

function Video({
    url,
    image,
    className,
    handleOnPlayMedia,
    handleOnPauseMedia,
}) {
    const videoRef = useRef()

    useEffect(() => {
        videoRef.current?.load()
    }, [url])

    // console.log('nft media', image)
    return (
        <video
            className={className}
            ref={videoRef}
            controls="controls"
            autoPlay
            onPlay={handleOnPlayMedia}
            onPause={handleOnPauseMedia}
        >
            <source src={url} type="video/mp4" />
        </video>
    )
}

export default Video
