import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    TextareaAutosize,
    Typography,
    styled,
} from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Controller, useFormContext } from 'react-hook-form'
import Ticon from '../assets/Ticon.png'
import { inputStyle } from './PersonalInformation'
import ProgressBar from './ProgressBar'

const BoxSelectFileStyled = styled(Box)({
    padding: '31px 10px',
    borderRadius: '8px',
    border: '1px dashed #BDBDBD',
    cursor: 'pointer',
    backgroundColor: '#F6F6F6',
    transition: '0.5s linear',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
        border: '1px solid #6FDA44',
    },
})

function FileUpload({
    setActiveStep,
    setPrevStep,
    setDataImage,
    setDataLibrary,
    setDataPreview,
}) {
    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext()
    const [image, setImage] = useState('')
    const [libraryFile, setLibraryFile] = useState([])
    const [previewFile, setPreviewFile] = useState('')
    const fileType = watch('fileType')

    const onDrop = useCallback(
        (acceptedFiles) => {
            setImage(...acceptedFiles)
            setDataImage(...acceptedFiles)
        },
        [setDataImage]
    )

    const dropZone = useDropzone({
        onDrop: onDrop,
        accept: {
            'image/*': [],
        },
    })

    const onLibraryDrop = useCallback(
        (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                let _files = []
                for (let i = 0; i < acceptedFiles.length; i++) {
                    const date = new Date()
                    const name =
                        `${date.getMilliseconds()}${date.getSeconds()}${date.getMinutes()}${Math.random()}`.replace(
                            '.',
                            '0'
                        )
                    let newFile = new File([acceptedFiles[i]], name)

                    Object.defineProperty(newFile, 'size', {
                        value: acceptedFiles[i]?.size,
                        writable: false,
                    })

                    Object.defineProperty(newFile, 'displayName', {
                        value: acceptedFiles[i]?.path,
                        writable: false,
                    })

                    _files.push(newFile)
                }
                const newLibraryFile = [...libraryFile, ..._files]
                setLibraryFile(newLibraryFile)
                setDataLibrary(newLibraryFile)
            }
        },
        [libraryFile, setDataLibrary]
    )

    const libraryDropZone = useDropzone({
        onDrop: onLibraryDrop,
        multiple: true,
        accept:
            watch('fileType') === 'Video'
                ? { 'video/*': [] }
                : { 'audio/*': [] },
    })

    const onPreviewDrop = useCallback(
        (acceptedFiles) => {
            setPreviewFile(...acceptedFiles)
            setDataPreview(...acceptedFiles)
        },
        [setDataPreview]
    )

    const previewDropZone = useDropzone({
        onDrop: onPreviewDrop,
        multiple: false,
        accept:
            watch('fileType') === 'Video'
                ? { 'video/*': [] }
                : { 'audio/*': [] },
    })

    return (
        <Box sx={{ background: '#fff', p: '32px', borderRadius: '12px' }}>
            <ProgressBar progress={66} />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    mb: '28px',
                    mt: '24px',
                }}
            >
                <InfoOutlinedIcon />
                <Typography
                    sx={{
                        fontSize: '12px',
                        color: '#2A3538',
                        fontWeight: '400 !important',
                    }}
                >
                    Upload the content that you want to sell on our website. It
                    will be published once we have verified it, and it meets our
                    guidelines.
                </Typography>
            </Box>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Typography
                        variant="h4"
                        mb={'10px'}
                        fontSize={'14px'}
                        fontWeight={700}
                    >
                        NFT Image (the image that you would like to associate
                        with your NFT 556*330 pixels)
                    </Typography>
                    <Box className="container">
                        <BoxSelectFileStyled
                            {...dropZone?.getRootProps({
                                className: 'dropzone',
                            })}
                        >
                            <input {...dropZone?.getInputProps()} />
                            <CloudUploadOutlinedIcon
                                sx={{
                                    color: '#2A3538',
                                    px: '5px',
                                    fontSize: '30px',
                                }}
                            />
                            <Typography
                                textAlign={'center'}
                                fontFamily="'Montserrat', sans-serif"
                                fontSize={'12px'}
                            >
                                {" Drag 'n' drop some files here, or "}
                                <a
                                    style={{
                                        fontWeight: 700,
                                        textDecoration: 'underline',
                                        fontSize: '12px',
                                    }}
                                >
                                    Browser
                                </a>
                            </Typography>
                        </BoxSelectFileStyled>

                        {image && (
                            <aside>
                                <img
                                    height={200}
                                    width={200}
                                    style={{ marginTop: '10px' }}
                                    src={URL.createObjectURL(image)}
                                    alt=""
                                />
                                <Typography
                                    mt="10px"
                                    variant="h4"
                                    fontFamily="'Montserrat', sans-serif"
                                >
                                    Files
                                </Typography>
                                <ul>
                                    <li>
                                        {image?.path} - {image?.size} bytes
                                    </li>
                                </ul>
                            </aside>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h4"
                        mb={'10px'}
                        fontSize={'14px'}
                        fontWeight={700}
                    >
                        Quantity (How many NFTs would you like to sell for this
                        media)
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '12px',
                        }}
                    >
                        <Controller
                            control={control}
                            name="quantity"
                            rules={{
                                required: {
                                    value: true,
                                    message: 'This field is required',
                                },
                            }}
                            render={({
                                field: { value, onChange },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    sx={inputStyle}
                                    onChange={(e) => {
                                        if (!isNaN(e.target.value)) {
                                            onChange(e.target.value)
                                        }
                                    }}
                                    value={value}
                                    error={error}
                                    size="small"
                                    fullWidth
                                    helperText={error?.message}
                                />
                            )}
                        />
                        <Box
                            sx={{
                                borderRadius: '8px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '6px',
                                border: '1px solid #a9aeaf',
                                padding: '8px 12px',
                            }}
                        >
                            <img src={Ticon} alt="" width={'24px'} />
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '12px',
                                    color: '#2A3538',
                                }}
                            >
                                USDT
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h4"
                        mb={'10px'}
                        fontSize={'14px'}
                        fontWeight={700}
                    >
                        Description (How would you describe the file you are
                        uploading?)
                    </Typography>

                    <Box
                        sx={{
                            border: errors['desc']
                                ? '1px solid red'
                                : '2px solid #BDBDBD',
                            transition: '0.5s linear',
                            '&:hover': {
                                border: '2px solid #6FDA44',
                            },
                            borderRadius: '7px',
                        }}
                    >
                        <Controller
                            name="desc"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'This field is required',
                                },
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextareaAutosize
                                    style={{
                                        width: '100%',
                                        height: '80px',
                                        border: 'none',
                                        overflow: 'auto',
                                        outline: 'none',
                                        WebkitBoxShadow: 'none',
                                        MozBoxShadow: 'none',
                                        boxShadow: 'none',
                                        resize: 'none',
                                        padding: '11px',
                                    }}
                                    onChange={onChange}
                                    value={value}
                                    placeholder="Write the description that will appear next to the presentation image."
                                    size="medium"
                                />
                            )}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h4"
                        mb={'10px'}
                        fontSize={'14px'}
                        fontWeight={700}
                    >
                        File type
                    </Typography>
                    <FormControl size="small" fullWidth>
                        <Controller
                            control={control}
                            name="fileType"
                            render={({ field: { value, onChange } }) => (
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={value}
                                    onChange={onChange}
                                    sx={{ gap: '16px' }}
                                >
                                    <Box
                                        sx={{
                                            border: `1px solid ${fileType === 'Audio' ? '#6FDA44' : '#a9aeaf'}`,
                                            borderRadius: '8px',
                                            p: '0 12px',
                                            width: { xs: '100%', md: '48%' },
                                        }}
                                    >
                                        <FormControlLabel
                                            value="Audio"
                                            control={<Radio />}
                                            label="Audio"
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            border: `1px solid ${fileType === 'Video' ? '#6FDA44' : '#a9aeaf'}`,
                                            borderRadius: '8px',
                                            p: '0 12px',
                                            width: { xs: '100%', md: '48%' },
                                        }}
                                    >
                                        <FormControlLabel
                                            value="Video"
                                            control={<Radio />}
                                            label="Video"
                                        />
                                    </Box>
                                </RadioGroup>
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h4"
                        mb={'10px'}
                        fontSize={'14px'}
                        fontWeight={700}
                    >
                        Library File (The content that users will be able to see
                        / hear)
                    </Typography>
                    <Box className="container">
                        <BoxSelectFileStyled
                            {...libraryDropZone?.getRootProps({
                                className: 'dropzone',
                            })}
                        >
                            <input {...libraryDropZone?.getInputProps()} />
                            <CloudUploadOutlinedIcon
                                sx={{
                                    color: '#2A3538',
                                    px: '5px',
                                    fontSize: '30px',
                                }}
                            />
                            <Typography textAlign={'center'} fontSize={'12px'}>
                                {" Drag 'n' drop some files here, or "}
                                <a
                                    style={{
                                        fontWeight: 700,
                                        textDecoration: 'underline',
                                        fontSize: '12px',
                                    }}
                                >
                                    Browser
                                </a>
                            </Typography>
                        </BoxSelectFileStyled>
                        {!!libraryFile.length && (
                            <aside>
                                <Typography mt="10px" variant="h4">
                                    Files
                                </Typography>
                                {libraryFile?.map((file, i) => (
                                    <ul key={i}>
                                        <li>
                                            {file?.displayName?.slice(0, 25)} -{' '}
                                            {Number(file?.size) / 1000} KB
                                        </li>
                                    </ul>
                                ))}
                            </aside>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h4"
                        mb={'10px'}
                        fontSize={'14px'}
                        fontWeight={700}
                    >
                        Preview File (Provide a short introduction to your file)
                    </Typography>
                    <Box className="container">
                        <BoxSelectFileStyled
                            {...previewDropZone?.getRootProps({
                                className: 'dropzone',
                            })}
                        >
                            <input {...previewDropZone?.getInputProps()} />
                            <CloudUploadOutlinedIcon
                                sx={{
                                    color: '#2A3538',
                                    px: '5px',
                                    fontSize: '30px',
                                }}
                            />
                            <Typography textAlign={'center'} fontSize={'12px'}>
                                {" Drag 'n' drop some files here, or "}
                                <a
                                    style={{
                                        fontWeight: 700,
                                        textDecoration: 'underline',
                                        fontSize: '12px',
                                    }}
                                >
                                    Browser
                                </a>
                            </Typography>
                        </BoxSelectFileStyled>

                        {previewFile && (
                            <aside>
                                <Typography mt="10px" variant="h4">
                                    Files
                                </Typography>
                                <ul>
                                    <li>
                                        {previewFile?.displayName?.slice(0, 25)}{' '}
                                        - {Number(previewFile?.size) / 1000} KB
                                    </li>
                                </ul>
                            </aside>
                        )}
                    </Box>
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        mt: '24px',
                        pl: '40px',
                    }}
                >
                    <Button
                        sx={{
                            borderRadius: '8px',
                            padding: '10px 32px',
                            fontWeight: '700',
                            color: '#2A3538',
                            textTransform: 'capitalize',
                            border: '2px solid #6FDA44',
                        }}
                        onClick={setPrevStep}
                    >
                        <KeyboardBackspaceIcon />
                        Prev
                    </Button>
                    <Button
                        sx={{
                            borderRadius: '8px',
                            background: '#6FDA44',
                            padding: '10px 32px',
                            fontWeight: '700',
                            color: '#2A3538',
                            textTransform: 'capitalize',
                        }}
                        onClick={setActiveStep}
                    >
                        Next <ArrowRightAltIcon />
                    </Button>
                </Box>
            </Grid>
        </Box>
    )
}

export default FileUpload
