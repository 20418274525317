/* eslint-disable camelcase */
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Box, Container, Grid, Hidden, Stack, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useCallback, useContext, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../Context'
import logoimg from '../assets/logoimg.png'
import CustomInput from '../components/CustomInput'
import useDocumentTitle from '../useDocumentTitle'
import { GreenButton } from '../components/Styled'
import { get, post, setAuthHeader } from '../utils/fetchApis'
import { setStorage, getStorage } from '../utils/helper'

export default function Login() {
    useDocumentTitle(
        'View exclusive NFT protected content | Find files from upcoming artists'
    )
    const matches = useMediaQuery('(max-width:600px)')
    const [verify, setVerify] = useState(true)
    const { setToken, setUserData, setAlertState } = useContext(AppContext)
    const navigate = useNavigate()
    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
        ishuman: false,
    })

    const handleLoginChange = useCallback(
        (event) => {
            setLoginData({
                ...loginData,
                [event.target.name]: event.target.value,
            })
        },
        [loginData]
    )

    const onChaptchaChange = useCallback(
        (value) => {
            if (value) {
                setLoginData({ ...loginData, ishuman: true })
                setVerify(true)
            }
        },
        [loginData]
    )

    const handleBack = useCallback(() => {
        navigate('/')
    }, [navigate])

    const handleLoginSubmit = useCallback(
        async (e) => {
            e.preventDefault()
            if (!loginData.ishuman) return setVerify(false)

            const loginRes = await post('auth/login', {
                email_address: loginData?.email,
                password: loginData?.password,
            })

            if (!loginRes.success) {
                console.log('login result', loginRes)
                setAlertState({
                    open: false,
                    message: loginRes.message,
                    severity: 'error',
                })
            } else {
                setAuthHeader(loginRes.data.access_token)

                setToken(loginRes.data.access_token)
                setStorage('token', loginRes.data.access_token)
                setStorage('refresh_token', loginRes.data.refresh_token)
                setAlertState({
                    open: true,
                    message: 'You signed in successfully!',
                    severity: 'success',
                })

                setVerify(false)
                setLoginData({ email: '', password: '', ishuman: false })
                navigate('/')
            }
        },
        [
            loginData?.email,
            loginData.ishuman,
            loginData?.password,
            navigate,
            setUserData,
        ]
    )

    return (
        <Container>
            <Box
                sx={{
                    '& .css-k3rfoq-MuiPaper-root-MuiDialog-paper': {
                        borderRadius: '15px',
                    },
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        justifyContent: 'center',
                        display: 'flex',
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: '100%', md: '50%' },
                            m: { xs: 0, md: 1 },
                        }}
                    >
                        <Hidden mdUp>
                            <Box
                                display="flex"
                                mt={1}
                                sx={{ cursor: 'pointer' }}
                                onClick={handleBack}
                            >
                                <ChevronLeftIcon />
                                <Typography fontWeight="bold">back</Typography>
                            </Box>
                        </Hidden>
                        <Box
                            mx={4}
                            mt={{ xs: 0, md: 4 }}
                            mb={{ xs: 1, md: 3 }}
                            align="center"
                            component="form"
                            onSubmit={handleLoginSubmit}
                            sx={{
                                textAlign: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={logoimg}
                                alt="logoimg"
                                srcSet=""
                                style={{
                                    width: '100%',
                                    maxWidth: matches ? '100px' : '150px',
                                }}
                            />
                            <Typography variant="h5" fontWeight="600">
                                Welcome back!
                            </Typography>
                            <Typography fontWeight={'400 !important'}>
                                Connect with your audience through NFT`s!
                            </Typography>
                            <Grid container spacing={2} my={2}>
                                <Grid item xs={12}>
                                    <CustomInput
                                        required
                                        type="email"
                                        placeholder="Email*"
                                        name="email"
                                        onChange={(e) => handleLoginChange(e)}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <CustomInput
                                        required
                                        type="password"
                                        placeholder="Password*"
                                        name="password"
                                        onChange={(e) => handleLoginChange(e)}
                                    />
                                </Grid>
                            </Grid>
                            <Box
                                maxWidth={'100%'}
                                sx={{
                                    overflow: 'hidden',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: {
                                        xs: 'start',
                                        sm: 'center',
                                    },
                                }}
                            >
                                <ReCAPTCHA
                                    sitekey="6LeTmHUnAAAAAPQpjmbC1c8daSxUgYte0Jy3M4Xc"
                                    onChange={onChaptchaChange}
                                />
                            </Box>
                            {!verify && (
                                <Box sx={{ color: 'red', fontSize: 12 }}>
                                    *Please verify you are not a robot
                                </Box>
                            )}

                            <GreenButton
                                type="submit"
                                sx={{ width: '100%', my: { xs: 1, md: 3 } }}
                            >
                                log in
                            </GreenButton>
                            <Typography
                                color="primary"
                                sx={{
                                    fontSize: '14px',
                                    cursor: 'pointer',
                                    fontWeight: '400 !important',
                                }}
                                onClick={() => navigate('/forgotPassword')}
                            >
                                Forgot your password?
                            </Typography>
                            <hr
                                style={{
                                    width: '150px',
                                    marginTop: '8px',
                                    marginBottom: '8px',
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: '400 !important',
                                }}
                            >
                                Don`t have an account
                            </Typography>
                            <Typography
                                color="primary"
                                sx={{
                                    cursor: 'pointer',
                                    fontWeight: '400 !important',
                                }}
                                onClick={() => navigate('/sign-up')}
                            >
                                Sign Up Now!
                            </Typography>
                        </Box>
                    </Box>
                </Stack>
            </Box>
        </Container>
    )
}
