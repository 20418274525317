import { styled, Button } from '@mui/material'

export const HomeButton = styled(Button)({
    textAlign: 'center',
    background: '#F6F6F6',
    textTransform: 'capitalize',
    padding: '8px 18px',
    borderRadius: '7px',
    width: '100%',
    '&:hover': {
        backgroundColor: '#6FDA44',
        color: 'white',
    },
})

export const GreenButton = styled(Button)({
    backgroundColor: '#6FDA44',
    color: 'white',
    width: '100%',
    '&:hover': {
        backgroundColor: '#6FDA44',
    },
    fontSize: '16px',
    fontWeight: '700',
    maxWidth: '300px',
})

export const OkayButton = styled(Button)({
    backgroundColor: '#6FDA44',
    color: 'white',
    borderRadius: '10px',
    width: '100%',
    '&:hover': {
        backgroundColor: '#6FDA44',
    },
    fontSize: '16px',
    fontWeight: '700',
    maxWidth: '300px',
    marginTop: '20px',
    marginBottom: '20px',
})

export const CancelButton = styled(Button)({
    backgroundColor: 'red',
    color: 'white',
    borderRadius: '10px',
    width: '100%',
    '&:hover': {
        backgroundColor: 'red',
    },
    fontSize: '16px',
    fontWeight: '700',
    maxWidth: '300px',
    marginTop: '20px',
    marginBottom: '20px',
    marginRight: '20px',
})

export const ViewButton = styled(Button)({
    border: '2px solid #6FDA44',
    color: '#6FDA44',
    borderRadius: '10px',
    width: '100%',
    fontSize: '16px',
    fontWeight: '700',
    maxWidth: '300px',
    marginBottom: '20px',
})
