export function repeatElements(array, x) {
    return Array(x).fill(array).flat()
}

export const waitForTx = async (provider, hash) => {
    while (!(await provider.getTransactionReceipt(hash))) {
        sleep(5000)
    }
}

const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

export const getComparator = (orderBy, order) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function descendingComparator(a, b, orderBy) {
    if (Number(b[orderBy]) < Number(a[orderBy])) {
        return -1
    }
    if (Number(b[orderBy]) > Number(a[orderBy])) {
        return 1
    }
    return 0
}

export const setStorage = (key, value) => {
    if (typeof value === 'object') {
        localStorage.setItem(key, JSON.stringify(value))
    } else {
        localStorage.setItem(key, value)
    }
}

export const clearStorage = () => {
    localStorage.clear()
}

export const getStorage = (key) => {
    return localStorage.getItem(key)
}
