import { DEV_MODE } from '../../utils/constant'

export const mintContractAddr = !DEV_MODE
    ? '0xBd4e62f4c6885a260e460072D85AdB9a114a6BB4'
    : '0x9c1539cC80b267e45D0D762E33C7779CAEcE22b8'
export const USDCContractAddr = !DEV_MODE
    ? '0xc2132D05D31c914a87C6611C10748AEb04B58e8F'
    : '0x41E94Eb019C0762f9Bfcf9Fb1E58725BfB0e7582'
export const marketplaceContractAddr = !DEV_MODE
    ? '0xB53840141ea1C5Ca23DC9720cf42d42506217F33'
    : '0x59d31da327ea624f8Eea89B4D60f33769b941043'
