import './Layout.scss'

import { Box, Container, Divider, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer className="footer-area pt-100 pb-50">
            <Container>
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="footer-logo">
                                <a href="/">
                                    <img src="/assets/images/logo.svg" alt="" />
                                </a>
                            </div>
                            <div className="footer-widgets">
                                <p>
                                    Sniser provides an innovative
                                    <br /> solution to protect artists
                                    creativity using blockchain technology.
                                </p>
                            </div>
                            <ul className="social mt-20">
                                <li>
                                    <a
                                        href="https://www.instagram.com/sniser_nfts/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src="/assets/images/instagram.png"
                                            style={{ width: '24px' }}
                                            alt=""
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.facebook.com/Sniserman/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src="/assets/images/facebook.png"
                                            style={{ width: '24px' }}
                                            alt=""
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.linkedin.com/in/sniser-ltd-b0a9a6205/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src="/assets/images/twitter.png"
                                            style={{ width: '24px' }}
                                            alt=""
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://twitter.com/Sniser4"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src="/assets/images/linkedin.png"
                                            style={{ width: '24px' }}
                                            alt=""
                                        />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Site Links</h3>
                            <ul className="quick-links">
                                <li>
                                    <Link to="/" rel="noopener noreferrer">
                                        Homepage
                                    </Link>
                                </li>
                                {/* <li>
                  <Link to="/whitepaper" rel="noopener noreferrer">
                    How it works
                  </Link>
                </li> */}
                                {/* <li>
                  <Link to="/artist-service">Artist service</Link>
                </li> */}
                                <li>
                                    <Link to="/faq" rel="noopener noreferrer">
                                        FAQs
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/about-us"
                                        rel="noopener noreferrer"
                                    >
                                        About Us
                                    </Link>
                                </li>
                                {/* <li>
                  <Link to="/finance" rel="noopener noreferrer">
                    Contact Us
                  </Link>
                </li> */}
                                {/* <li>
                  <Link to="/business-service" rel="noopener noreferrer">
                    Business Service
                  </Link>
                </li> */}
                                <li>
                                    <Link
                                        to="/privacy-policy"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/cookie-policy"
                                        rel="noopener noreferrer"
                                    >
                                        Cookie Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/account-deletion"
                                        rel="noopener noreferrer"
                                    >
                                        Account Deletion
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/terms-and-conditions"
                                        rel="noopener noreferrer"
                                    >
                                        Terms and Conditions
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/upload"
                                        rel="noopener noreferrer"
                                    >
                                        Upload
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/advertising"
                                        rel="noopener noreferrer"
                                    >
                                        Advertising
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/view-content"
                                        rel="noopener noreferrer"
                                    >
                                        How to view NFT
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/faq" rel="noopener noreferrer">
                                        FAQ
                                    </Link>
                                </li>
                                <li style={{ display: 'none' }}>
                                    <Link
                                        to="/advertise"
                                        rel="noopener noreferrer"
                                    >
                                        FAQ
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Corporate Information</h3>
                            <ul className="quick-links">
                                <li>
                                    <a href="#">
                                        Noak Industries Ltd trading as Sniser
                                    </a>
                                </li>
                                <li>
                                    <a href="#">12 Peckworth Close</a>
                                </li>
                                <li>
                                    <a href="#">Hitchin</a>
                                </li>
                                <li>
                                    <a href="#">Bedfordshire</a>
                                </li>
                                <li>
                                    <a href="#">SG16 6PG</a>
                                </li>
                                <li>
                                    <a href="#">support@sniser.com</a>
                                </li>
                                <li>
                                    <a href="#">
                                        Registered in England and Wales
                                        (15369546)
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Divider sx={{ background: '#fff', my: '20px' }} />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography color={'#fff'} fontWeight={'400 !important'}>
                        Copyright NOAK Industries Ltd 2024. All Rights Reserved
                    </Typography>
                    <Typography color={'#fff'} fontWeight={'400 !important'}>
                        support@sniser.com
                    </Typography>
                </Box>
            </Container>
        </footer>
    )
}
export default Footer
