import { ethers } from 'ethers'
import { Box, Tabs, Tab, Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import UserManagement from '../Management/User'
import PlanManagement from '../Management/Plan'
import MediaManagement from '../Management/Media'
import FaqManagement from '../Management/Faq'
import NftManagement from '../Management/Nft'
import { useState } from 'react'

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    )
}

const Management = () => {
    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Box>
            <Typography variant="h2" fontFamily="'Montserrat', sans-serif">
                Management
            </Typography>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="User" {...a11yProps(0)} />
                    <Tab label="Nft" {...a11yProps(1)} />
                    <Tab label="Plan" {...a11yProps(2)} />
                    <Tab label="Faq" {...a11yProps(3)} />
                    {/* <Tab label="Media" {...a11yProps(4)} /> */}
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <UserManagement />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <NftManagement />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <PlanManagement />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <FaqManagement />
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={4}>
            <MediaManagement/>
        </CustomTabPanel> */}
        </Box>
    )
}

export default Management
