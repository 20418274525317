import styled from '@emotion/styled'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import {
    Box,
    Button,
    Container,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAccount } from 'wagmi'

import Home1 from '../assets/home-1.png'
import Home2 from '../assets/home-2.png'
import CookieNotifyModal from '../components/Modal/CookieNotifyModal'
import { AppContext } from '../Context'
import useDocumentTitle from '../useDocumentTitle'

const ButtonPrimary = styled(Button)({
    background: '#6FDA44',
    fontWeight: 700,
    color: '#2A3538',
    padding: '12px 36px',
    textTransform: 'capitalize',
    fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
        backgroundColor: '#90E26F',
        color: 'black',
        transition: '1s',
    },
})

const ButtonLink = styled(Link)({
    color: '#6FDA44',
    fontWeight: 500,
    fontSize: '16px',
    fontFamily: "'Montserrat', sans-serif",
    flex: 1,
    '&:hover': {
        color: '#90E26F',
    },
})

const data = [
    {
        heading: 'For Viewers',
        title: 'What are NFTs and why purchase one?',
        description:
            'A NFT is a digital asset that is unique. It can be used for several purposes. Sniser uses NFT as a password to view protected information like videos and audio content. Purchasing one from us will enable you to view exclusive audio and video content. Our technology is based on Polygon Ethereum network, which has one of the lowest gas fees for minting and purchasing our NFTs.',
        buttonText: 'Buy now',
        image: Home1,
        url: '/why-purchase',
    },
    {
        heading: 'For Creators',
        title: 'Why upload your content here?',
        description:
            'Upload your content here because our service works different to other streaming service. You do not gain revenue from receiving a percentage of advertising revenue; instead, you a percentage of the revenue that comes when users purchase your user linked NFT. You don’t need any knowledge of blockchain technology to upload content here or receive payments. ',
        buttonText: 'Learn More',
        image: Home2,
        url: '/nft-advertise',
    },
]

const bottomData = [
    {
        title: 'What is Sniser?',
        description:
            'Sniser is a platform that allows people to harness the power of blockchain technology to better protect their creative content.',
        url: '/what-is-sniser',
    },
    {
        title: 'How to purchase an NFT?',
        description:
            'Load Tether on Polygon network into your crypto wallet and purchase a wide variety of NFT and linked content.',
        url: '/purchase-an-nft',
    },
    {
        title: 'Purchase the Sniser token.',
        description:
            'Our dividend token will allow you to invest in new and upcoming artist, as well as us purchasing rights from existing artists. In exchange for investment, you will receive a percentage of the profits from NFTs sales. ',
        url: '/coin',
    },
]

const Home = () => {
    const [isShowVideo, setShowVideo] = useState(false)
    const matches = useMediaQuery('(max-width:900px)')
    const navigate = useNavigate()
    const [showNotify, setShowNotify] = useState(false)
    const { isConnected, address } = useAccount()
    const { isMobile, toggleConnectModal, setAlertModal } =
        useContext(AppContext)
    const [anchorEl, setAnchorEl] = useState(null)

    const open = Boolean(anchorEl)

    useDocumentTitle(
        'View exclusive NFT protected content | Upload Videos, Audio and Audiobooks'
    )

    const checkConnect = () => {
        if (!isConnected) {
            if (isMobile) setAlertModal(true)
            else toggleConnectModal(true)
        }
    }

    return (
        <>
            <Box
                sx={{
                    background: '#6FDA44',
                    color: '#2A3538',
                    py: { xs: '38px', md: '60px' },
                }}
            >
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: { xs: '20', md: '25px' },
                            flexDirection: { xs: 'column', md: 'row' },
                        }}
                    >
                        <Box sx={{ flex: 1 }}>
                            <Box
                                sx={{
                                    bgcolor: '#2A3538',
                                    width: '100%',
                                    height: { xs: '230px', md: '376px' },
                                    borderRadius: '8px',
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {isShowVideo ? (
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src="https://www.youtube.com/embed/mOg3je5criI?autoplay=1"
                                        title="Sniser introduction"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen
                                    ></iframe>
                                ) : (
                                    <>
                                        <Typography
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: '32px',
                                                color: '#fff',
                                            }}
                                        >
                                            Watch{' '}
                                            <Typography
                                                sx={{
                                                    fontWeight: 700,
                                                    fontSize: '32px',
                                                    color: '#6FDA44',
                                                }}
                                                component={'span'}
                                            >
                                                Me!
                                            </Typography>
                                        </Typography>
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setShowVideo(true)
                                            }}
                                        >
                                            {matches ? (
                                                <img
                                                    src="/assets/images/play-icon-mobile.svg"
                                                    alt=""
                                                />
                                            ) : (
                                                <img
                                                    src="/assets/images/play-icon.svg"
                                                    alt=""
                                                />
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                right: {
                                                    xs: '19px',
                                                    md: '32px',
                                                },
                                                bottom: {
                                                    xs: '19px',
                                                    md: '32px',
                                                },
                                            }}
                                        >
                                            <img
                                                src="/assets/images/logo-header.jpeg"
                                                alt=""
                                                style={{
                                                    maxWidth: '150px',
                                                    height: '36px',
                                                }}
                                            />
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography
                                sx={{
                                    mb: { xs: '4px', md: '8px' },
                                    color: '#2A3538',
                                    fontWeight: 700,
                                    fontSize: { xs: '32px', md: '40px' },
                                }}
                            >
                                What is Sniser?
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { xs: '16px', md: '20px' },
                                    lineHeight: { xs: '24px', md: '30px' },
                                    color: '#2A3538',
                                }}
                            >
                                Sniser is a unique platform offering exclusive
                                video and audio content. To access this content
                                you must first purchase a non-fungible token
                                (NFT). They act as keys, unlocking the ability
                                to view and enjoy content from various artists.
                                Our NFTs can be resold after users have watched
                                the content. If the content linked to an NFT
                                becomes more in demand, the NFT's value will
                                increase.
                            </Typography>
                            <Box sx={{ mt: 'auto' }}>
                                <Button
                                    sx={{
                                        bgcolor: '#2A3538',
                                        padding: '16px 24px',
                                        color: '#ffffff',
                                        fontWeight: 700,
                                        textTransform: 'capitalize',
                                        lineHeight: '17px',
                                        fontSize: '14px',
                                        borderRadius: '4px',
                                    }}
                                    onClick={() => navigate('/shop')}
                                >
                                    Explore Content
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box sx={{ background: '#fff', paddingY: '48px' }}>
                <Container>
                    <Grid container spacing={4} sx={{ gridAutoRows: '1fr' }}>
                        {data.map((item, index) => (
                            <Grid item xs={12} lg={6} key={index.toString()}>
                                <Box
                                    sx={{
                                        background: '#2A3538',
                                        borderRadius: '8px',
                                        paddingLeft: {
                                            xs: 0,
                                            sm: '23px',
                                        },
                                        display: 'flex',
                                        alignItems: 'stretch',
                                        height: '100%',
                                        flexDirection: {
                                            xs: 'column-reverse',
                                            sm: 'row',
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            paddingTop: '23px',
                                            paddingRight: '23px',
                                            paddingBottom: '24px',
                                            flex: '1',
                                            alignItems: 'stretch',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingLeft: {
                                                xs: '23px',
                                                sm: 0,
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                border: '1px solid #fff',
                                                borderRadius: '4px',
                                                padding: '4px 8px',
                                                width: '104px',
                                                fontSize: '14px',
                                                color: '#fff',
                                                mb: '17px',
                                            }}
                                        >
                                            {item.heading}
                                        </Box>
                                        <Typography
                                            sx={{
                                                color: '#fff',
                                                fontSize: '24px',
                                                mb: '4px',
                                            }}
                                        >
                                            {item.title}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: '#fff',
                                                fontSize: '16px',
                                                mb: '31px',
                                                fontWeight: '400 !important',
                                            }}
                                        >
                                            {item.description}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '10px',
                                                alignItems: 'center',
                                                marginTop: 'auto',
                                            }}
                                        >
                                            <ButtonPrimary
                                                onClick={() =>
                                                    navigate(item.url)
                                                }
                                            >
                                                {item.buttonText}
                                            </ButtonPrimary>
                                            <ArrowOutwardIcon color="primary" />
                                            ,
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            flex: '0 0 auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                            alignSelf: 'stretch',
                                            width: {
                                                xs: '100%',
                                                sm: 'auto',
                                            },
                                            flexShrink: 0,
                                        }}
                                    >
                                        <img
                                            src={item.image}
                                            alt=""
                                            style={{
                                                width: '100%',
                                                height: matches
                                                    ? '300px'
                                                    : 'auto',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
            <Box sx={{ background: '#2A3538', paddingY: '47px' }}>
                <Container>
                    <Grid container spacing={4}>
                        {bottomData.map((item, index) => (
                            <Grid item xs={12} md={4} key={index}>
                                <Box
                                    sx={{
                                        border: '1px solid #6FDA44',
                                        borderRadius: '8px',
                                        padding: '23px 24px 30px 24px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: '20px',
                                            color: '#6FDA44',
                                        }}
                                    >
                                        {item.title}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            color: '#fff',
                                            mb: '20px',
                                            fontWeight: '400 !important',
                                        }}
                                    >
                                        {item.description}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            mt: 'auto',
                                        }}
                                    >
                                        <ButtonLink to={item.url}>
                                            Click to view more
                                        </ButtonLink>
                                        <ArrowOutwardIcon color="primary" />,
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
            <button
                id="btn-trigger"
                onClick={() => setShowNotify(true)}
                className="d-none"
            >
                Trigger Modal
            </button>

            {!!showNotify && (
                <CookieNotifyModal
                    open={showNotify}
                    setShowNotify={() => setShowNotify(!showNotify)}
                />
            )}
        </>
    )
}

export default Home
