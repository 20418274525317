import { useCallback, useContext, useEffect, useState } from 'react'

import { AppContext } from '../Context'
import { get, post } from '../utils/fetchApis'

function useNft(nft_id: string) {
    const [nft, setNft] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const { setAlertState } = useContext(AppContext)

    const getNft = useCallback(async () => {
        try {
            setIsLoading(true)
            const { data, success, message } = await get(`nfts/${nft_id}`)

            if (!success) {
                setAlertState({
                    open: true,
                    message,
                    severity: 'error',
                })
            }

            const order = data.orders[0]
            setNft({
                ...data,
                ...(order && {
                    order,
                    price: order.price,
                }),
            })
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }, [nft_id])

    useEffect(() => {
        getNft()
    }, [nft_id])

    const refetch = () => {
        getNft()
    }

    return { nft, isLoading, execute: refetch }
}

export default useNft
