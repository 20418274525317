import { useCallback, useEffect, useState } from 'react'

import { mintContractAddr } from '../connectivityAssets/mintContract/addresses'
import { get, post } from '../utils/fetchApis'

function useAdminNfts({ pagination }) {
    const [nftList, setNftList] = useState([])
    const [count, setCount] = useState()
    const [loading, setLoading] = useState(true)

    const getNfts = useCallback(async (pagination) => {
        const { page, size, search } = pagination
        const { success, data, message } = await get('nfts', {
            contract_address: mintContractAddr,
            search,
            page: page + 1,
            size,
            isAdmin: true,
        })

        if (!success) {
            console.log('useSaleNfts error', message)
            return
        }
        const nfts = data.items.map((it) => ({
            ...it,
            price: it.orders.length ? it.orders[0].price : '',
        }))
        setNftList(nfts)
        setCount(data.totalResults)
        setLoading(false)
    }, [])

    useEffect(() => {
        getNfts(pagination)
    }, [pagination])

    const refetch = (pagination) => {
        getNfts(pagination)
    }

    return { nftList, count, loading, refetch }
}

export default useAdminNfts
