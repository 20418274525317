import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import { Box, Button, Container, Typography } from '@mui/material'
import AboutUsImage1 from '../assets/illustration-social-media-concept.jpg'
import AboutUsImage2 from '../assets/personal-site-concept-illustration.jpg'
import AboutUsImage3 from '../assets/collab-concept-illustration.jpg'
import AboutUsImage from '../assets/diversity-concept-illustration.jpg'
import useDocumentTitle from '../useDocumentTitle'

const ContentData = [
    {
        title: '',
        description:
            'Sniser exists to increase the value of digital assets. Whether in audio or visual format, we seek to allow fans and investors to better appreciate the dedication of artists. Although streaming has increased access to different forms of creative content, maybe big tech has allowed free streaming to diminish the value of creative media and its form. ',
        imageUrl: AboutUsImage1,
    },
    {
        title: '',
        description:
            'As an innovative start-up, we seek to create a platform that better serves the interests of artists, whilst still allowing fans (and investors) to appreciate created media. We do not seek to challenge traditional forms of streaming by asking artists to only publish content with us. Rather, it is our intention to allow artists to publish unique content with us that can only be viewed via ownership of a non fungible token (NFT). ',
        imageUrl: AboutUsImage2,
    },
    {
        title: '',
        description:
            'Founded in 2022, we have evolved from a platform that focused on crypto assets to one that is based on creative media and NFTs. Our short but productive time in this space has allowed us to distinguish between short term trends and the areas that can demonstrate longevity. Indeed, an enduring future exists best when blockchain technology works with existing systems.',
        imageUrl: AboutUsImage3,
    },
]

function AboutUs() {
    useDocumentTitle(
        'Sniser provides NFT protected content to ensure artist payment.'
    )

    return (
        <>
            <Box sx={{ backgroundColor: '#6FDA44' }}>
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexDirection: { xs: 'column-reverse', md: 'row' },
                            paddingY: { xs: '39px', sm: '63px' },
                        }}
                    >
                        <Box sx={{ maxWidth: '477px' }}>
                            <Typography
                                variant="h2"
                                sx={{
                                    mb: '20px',
                                    fontSize: '32px !important',
                                    color: '#2A3538',
                                }}
                            >
                                ABOUT US
                            </Typography>
                            <Typography
                                sx={{
                                    mb: '31px',
                                    fontWeight: '400 !important',
                                }}
                            >
                                We provide smart and innovative means for people
                                to understand and interact with blockchain
                                technology. Despite the numerous references to
                                the supposed failings to blockchain technology
                                and NFTs, we believe that this comes from
                                misinformation. Our relationship with you is one
                                that will be based on transparency, referring to
                                the positive impact of blockchain technology and
                                guarding you against potential threats.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}
                            >
                                <Button
                                    sx={{
                                        background: '#2A3538',
                                        fontWeight: 700,
                                        color: '#ffffff',
                                        textTransform: 'capitalize',
                                        padding: '10px 40px',
                                    }}
                                >
                                    Contact Us
                                </Button>
                                <ArrowOutwardIcon
                                    sx={{ color: '#2A3538', fontSize: '24px' }}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: { xs: '345px', md: '600px' },
                                height: { xs: 'auto', md: '400px' },
                                mb: { xs: '23px', md: 0 },
                            }}
                        >
                            <img
                                src={AboutUsImage}
                                alt=""
                                style={{ objectFit: 'cover' }}
                            />
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box sx={{ paddingBottom: { xs: '63px', md: '99px' } }}>
                {ContentData?.map((item, index) => (
                    <Box
                        key={index.toString()}
                        sx={{
                            paddingY: { xs: '23px', md: '52px' },
                            display: 'flex',
                            gap: '62px',
                            flexDirection: {
                                xs: 'column',
                                md: index % 2 === 0 ? 'row' : 'row-reverse',
                            },
                            backgroundColor:
                                index % 2 === 0
                                    ? 'transparent'
                                    : 'rgba(2, 3, 3, 0.05)',
                        }}
                    >
                        <Container>
                            <Box
                                key={index.toString()}
                                sx={{
                                    paddingY: { xs: '23px', md: '40px' },
                                    display: 'flex',
                                    gap: '62px',
                                    flexDirection: {
                                        xs: 'column',
                                        md:
                                            index % 2 === 0
                                                ? 'row'
                                                : 'row-reverse',
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        width: { xs: '345px', md: '450px' },
                                        height: 'auto',
                                        mb: { xs: '23px', md: 0 },
                                        flexShrink: 0,
                                    }}
                                >
                                    <img
                                        src={item.imageUrl}
                                        alt=""
                                        style={{ objectFit: 'cover' }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: '64px',
                                            color: '#6FDA44',
                                        }}
                                    >
                                        {index + 1}.
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '32px',
                                            color: '#2A3538',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        {item.title}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: '400 !important',
                                            color: '#2A3538',
                                        }}
                                    >
                                        {item.description}
                                    </Typography>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                ))}
            </Box>
        </>
    )
}

export default AboutUs
