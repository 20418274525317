import { yupResolver } from '@hookform/resolvers/yup'
import { isAxiosError } from 'axios'
import { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import { AppContext } from '../../Context'
import { useModal } from '../../modal-context'
import { post } from '../../utils/fetchApis'
import { setStorage } from '../../utils/helper'

const schema = yup.object().shape({
    f_name: yup.string().required(),
    l_name: yup.string().required(),
    business_name: yup.string().required(),
    address: yup.string().required(),
    phone: yup.string().min(10).max(10).required(),
    email: yup.string().email('Email invalid').required('Email is required'),
    password: yup.string().min(8).max(32).required(),
    cnfrmpassword: yup
        .string()
        .required('Confirm passwords is required')
        .oneOf([yup.ref('password'), ''], 'Passwords must match'),
})

const SignUpModal = ({ open, close }) => {
    const { setModalLogin, setModalSignUp } = useModal()
    const [isLoading, setIsLoading] = useState(false)
    const { setAlertState } = useContext(AppContext)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const onSubmitHandler = async (data) => {
        setIsLoading(true)

        try {
            await post('auth/register', {
                f_name: data?.fname,
                l_name: data?.lname,
                email: data?.email,
                phone: data?.phone,
                address: data?.address,
                password: data?.password,
                business_name: data?.business_name,
            })

            setIsLoading(false)
            setStorage('token', data.access_token)
            toast.success('Registration Successfully !!')
            setModalSignUp(false)
            setModalLogin(true)
        } catch (e: any) {
            let message = 'something wrong happened'
            if (isAxiosError(e)) {
                message = e.response?.data.message
            } else if (e.code) {
                message = e.reason ?? e.message
            }

            setAlertState({
                open: true,
                message,
                severity: 'error',
            })
        }
    }

    return (
        <Modal show={open} onHide={close} size="lg">
            <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={close}
            >
                <span aria-hidden="true">
                    <span className="back">
                        <i
                            className="fa fa-chevron-left"
                            aria-hidden="true"
                        ></i>
                        &nbsp;Back
                    </span>
                    <span className="x">x</span>
                </span>
            </button>
            <Modal.Body>
                <div className="row">
                    <div className="modal-right-col">
                        <div className="sniser-logo">
                            <img
                                src="https://sniser.com/images/sniser-logo.png"
                                alt=""
                            />
                        </div>

                        <h2 className="title">Welcome to Sniser!</h2>

                        <p className="desc">
                            Connect with your audience through NFTs!
                        </p>

                        <form
                            className="login-form signup-form"
                            onSubmit={handleSubmit(onSubmitHandler)}
                        >
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="f_name"
                                            {...register('f_name', {
                                                required: true,
                                            })}
                                            className={`form-control ${errors?.f_name && 'invalid'}`}
                                            placeholder="First Name*"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="l_name"
                                            {...register('l_name')}
                                            className={`form-control ${errors?.l_name && 'invalid'}`}
                                            placeholder="Last Name*"
                                        />
                                    </div>
                                </div>
                                {errors?.l_name && (
                                    <div className="col text-red-500 text-left mb-[10px]">
                                        Last name is required
                                    </div>
                                )}
                                {errors?.f_name && (
                                    <div className="col text-red-500 text-left mb-[10px]">
                                        First name is required
                                    </div>
                                )}
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="business_name"
                                            {...register('business_name')}
                                            className={`form-control ${errors?.business_name && 'invalid'}`}
                                            placeholder="Business Name*"
                                        />
                                    </div>
                                    {errors?.business_name && (
                                        <div className="col text-red-500 text-left mb-[10px]">
                                            Business name is required
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            id="email"
                                            {...register('email')}
                                            className={`form-control ${errors?.email && 'invalid'}`}
                                            placeholder="Email*"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="number"
                                            id="phone"
                                            {...register('phone')}
                                            className={`form-control ${errors?.phone && 'invalid'}`}
                                            placeholder="Phone*"
                                        />
                                    </div>
                                </div>
                                {(errors?.email || errors?.phone) && (
                                    <div className="col text-red-500 text-left mb-[10px]">
                                        {errors?.email ? 'Email' : 'Phone'} is
                                        required
                                    </div>
                                )}
                                {errors?.email && (
                                    <div className="col text-red-500 text-left mb-[10px]">
                                        {errors?.email?.message as string}
                                    </div>
                                )}
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="address"
                                            placeholder="Address*"
                                            {...register('address')}
                                            className={`form-control ${errors?.address && 'invalid'}`}
                                        />
                                    </div>
                                </div>
                                {errors?.address && (
                                    <div className="col text-red-500 text-left mb-[10px]">
                                        Address is required
                                    </div>
                                )}
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            id="password"
                                            placeholder="Password*"
                                            {...register('password')}
                                            className={`form-control ${errors?.password && 'invalid'}`}
                                        />
                                    </div>
                                </div>

                                <input
                                    type="hidden"
                                    name="user_type"
                                    value="free"
                                />

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            id="cnfrmpassword"
                                            placeholder="Confirm Password*"
                                            {...register('cnfrmpassword')}
                                            className={`form-control ${errors?.cnfrmpassword && 'invalid'}`}
                                        />
                                    </div>
                                </div>
                                {errors?.password && (
                                    <div className="col text-red-500 text-left mb-[10px]">
                                        Password is required
                                    </div>
                                )}
                                {errors?.cnfrmpassword && (
                                    <div className="col text-red-500 text-left mb-[10px]">
                                        {
                                            errors?.cnfrmpassword
                                                ?.message as string
                                        }
                                    </div>
                                )}
                            </div>

                            <div className="form-group">
                                <input
                                    type="submit"
                                    name="submit"
                                    className="login-btn"
                                    value="Join us"
                                    disabled={isLoading}
                                />
                            </div>
                            <div className="form-group">
                                <p>
                                    Already have an account? <br />
                                    <button
                                        className="btnShowsSignUp"
                                        onClick={() => {
                                            setModalLogin(true)
                                            close()
                                        }}
                                    >
                                        Log in
                                    </button>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SignUpModal
