import { Box, Button, Paper, Typography, useMediaQuery } from '@mui/material'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useAccount } from 'wagmi'
import { AppContext } from '../Context'
import icon from '../assets/Ticon.png'
import { GC_BUCKET_URL, NFT_STATUS, ORDER_STATUS } from '../utils/constant'

const NftListItem = ({ nft, list, buy }) => {
    const matches = useMediaQuery('(max-width:900px)')

    const { isConnected, address } = useAccount()
    const { toggleConnectModal, wallet } = useContext(AppContext)

    const soldOrders =
        nft.orders && nft.orders.filter((o) => o.status == ORDER_STATUS.SOLD)

    const hot = nft.status === NFT_STATUS.SALE && !soldOrders
    const resale =
        nft.status === NFT_STATUS.SALE && soldOrders && soldOrders.length

    return (
        <Paper
            elevation={3}
            sx={{
                borderRadius: '10px',
                background: '#FFFFF',
                overflow: 'hidden',
            }}
            style={{ position: 'relative' }}
        >
            <Link
                to={`/nft/${nft.id}`}
                style={{
                    textDecoration: 'none',
                    color: 'black',
                }}
            >
                {(!!hot || !!resale) && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: -30,
                            background: hot ? '#6FDA44' : 'blue',

                            width: '130px',
                            transform: 'rotate(40deg)',
                            zIndex: 1000,
                            py: '3px',
                        }}
                    >
                        {' '}
                        <Typography
                            textAlign={'center'}
                            ml="25px"
                            color={'white'}
                            fontWeight={'700'}
                        >
                            {hot ? 'Hot' : 'Resale'}
                        </Typography>{' '}
                    </Box>
                )}

                <img
                    src={GC_BUCKET_URL + nft?.image}
                    alt="nft"
                    style={{
                        width: '100%',
                        height: matches ? '150px' : '250px',
                    }}
                />

                <Box px={{ xs: '6px', md: '12px' }}>
                    <Typography variant="overline" fontSize="14px">
                        {nft?.title} #{nft?.tokenId}
                    </Typography>

                    {nft.status === NFT_STATUS.SALE ? (
                        <Box display="flex" alignItems={'center'}>
                            <img src={icon} width="20px" alt="seen icon" />
                            <Typography
                                variant="overline"
                                fontSize="14px"
                                ml="6px"
                                fontWeight={'600'}
                            >
                                {' '}
                                {nft.price} USDT
                            </Typography>
                        </Box>
                    ) : (
                        <Box height="37px"></Box>
                    )}

                    {soldOrders && soldOrders.length ? (
                        <Box display="flex" alignItems={'center'}>
                            <Typography
                                mt="0"
                                variant="overline"
                                fontSize="12px"
                            >
                                {`Last sale: ${soldOrders[soldOrders.length - 1].price} USDT`}
                            </Typography>
                        </Box>
                    ) : (
                        <Box height="31px"></Box>
                    )}
                    {/* { order && <Box mt="10px" display="flex" alignItems={"center"}>
          <img src={icon} width="35px" alt="seen icon" />
          <Typography
            fontSize={{ xs: "14px", md: "18px" }}
            fontWeight={"600"}
            px={"10px"}
          >
            {ethers.utils.formatUnits(order['price'], 6)}
          </Typography>
          <Typography fontSize={{ xs: "12px", md: "16px" }}>USDT</Typography>
        </Box>} 
        
        { order && <Stack flexDirection={'row'}>
            <Typography fontSize="14px" fontWeight="600">
              Order ID:
            </Typography>
            <Typography fontSize="14px" pl={"5px"}>
              {parseInt(order['id'])}
            </Typography>
        </Stack>} */}

                    <Box display="flex" gap={2} mt={2}>
                        <Typography>Type:</Typography>
                    </Box>
                </Box>
            </Link>

            <Box
                sx={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 10,
                }}
            >
                <Button
                    my={2}
                    fontSize={{ xs: '13px', md: '18px' }}
                    sx={{
                        background: resale ? 'blue' : '#6FDA44',
                        '&:hover': {
                            backgroundColor: '#6FDA44',
                        },
                        color: 'white',
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: '700',
                    }}
                    onClick={(_) => {
                        if (!isConnected) {
                            toggleConnectModal(true)
                        } else {
                            if (nft.walletId !== wallet?.id) {
                                buy(nft)
                            } else {
                                list(nft)
                            }
                        }
                    }}
                >
                    {nft.walletId === wallet?.id
                        ? nft.status === NFT_STATUS.SALE
                            ? 'Edit list'
                            : 'List for sale'
                        : 'Buy NFT'}
                </Button>
            </Box>
        </Paper>
    )
}

export default NftListItem
