import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Typography,
} from '@mui/material'
import FagImage from '../assets/hinking-cuate.png'
import Loading from '../connectivityAssets/Loading'
import useFaqList from 'src/hooks/useFaq'
import useDocumentTitle from '../useDocumentTitle'
import { useState } from 'react'

function Faq() {
    useDocumentTitle(
        'Sniser provides NFT protected content to ensure artist payment.'
    )

    const [pagination, setPagination] = useState({
        page: 1,
        size: 20,
        search: '',
    })
    const { faqList, isLoading } = useFaqList(pagination)

    console.log('faq', isLoading)
    return (
        <>
            {isLoading && <Loading isLoading={isLoading} />}
            <Box sx={{ backgroundColor: '#F4F5F5' }}>
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: { xs: 'column-reverse', md: 'row' },
                            paddingY: { xs: '39px', sm: '63px' },
                            gap: { xs: '40px', sm: '80px' },
                        }}
                    >
                        <Box sx={{ maxWidth: '560px' }}>
                            <Typography
                                variant="h2"
                                sx={{
                                    mb: '20px',
                                    fontSize: '32px !important',
                                    color: '#2A3538',
                                    textTransform: 'uppercase',
                                    textAlign: 'center',
                                }}
                            >
                                Frequently asked questions
                            </Typography>
                            <Typography
                                sx={{
                                    mb: '31px',
                                    fontWeight: '400 !important',
                                    fontSize: '18px',
                                    maxWidth: '380px',
                                }}
                            >
                                You have questions and we've got most of the
                                answers. We have listed the most common
                                questions asked by our audience and members,
                                plus links to other parts of our website. Here
                                is the place to get the answers that you need,
                                fast.
                            </Typography>
                        </Box>
                        <Box sx={{ mb: { xs: '23px', md: 0 } }}>
                            <Box
                                component={'img'}
                                src={FagImage}
                                alt=""
                                sx={{
                                    objectFit: 'cover',
                                    height: { xs: 'auto', md: '100%' },
                                }}
                            />
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box
                sx={{
                    paddingBottom: { xs: '63px', md: '99px' },
                    paddingTop: '80px',
                }}
            >
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '12px',
                        }}
                    >
                        {faqList?.map((item, index) => (
                            <Accordion
                                key={index.toString()}
                                sx={{
                                    borderRadius: '12px !important',
                                    border: '1px solid #2A3538 !important',
                                    boxShadow: 'none',
                                }}
                            >
                                <AccordionSummary
                                    sx={{
                                        fontWeight: 700,
                                        color: '#2A3538',
                                        fontSize: '20px',
                                        marginY: '14px',
                                        lineHeight: '30px',
                                        '& .MuiAccordionSummary-content': {
                                            margin: 0,
                                        },
                                        '&.Mui-expanded': {
                                            '& .MuiAccordionSummary-content': {
                                                borderBottom:
                                                    '1px solid rgba(42, 53, 56,0.2)',
                                            },
                                        },
                                    }}
                                    expandIcon={<ArrowDropDownIcon />}
                                >
                                    {item?.title}
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{ color: '#2A3538' }}
                                    dangerouslySetInnerHTML={{
                                        __html: item?.description,
                                    }}
                                />
                            </Accordion>
                        ))}
                    </Box>
                </Container>
            </Box>
            <Box
                sx={{
                    backgroundColor: '#6FDA44',
                    paddingY: '80px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '20px',
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        textTransform: 'uppercase',
                        color: '#2A3538',
                        fontSize: '32px !important',
                        textAlign: 'center',
                    }}
                >
                    Didn’t find an answer to your question?
                </Typography>
                <Typography
                    sx={{
                        fontWeight: '400 !important',
                        textAlign: 'center',
                        color: '#2A3538',
                        maxWidth: '527px',
                    }}
                >
                    If you have a question that you believe we have not fully
                    answered, please click the tab to contact us with your
                    question. A member of our team will get back to you with 3
                    working days.
                </Typography>
                <Button
                    sx={{
                        background: '#2A3538',
                        mt: '25px',
                        padding: '11px 40px',
                        color: '#ffffff',
                        fontWeight: 700,
                        fontSize: '14px',
                        textTransform: 'capitalize',
                        fontFamily: "'Montserrat', sans-serif",
                        '&:hover': {
                            background: '#313E42',
                        },
                    }}
                >
                    Contact Us
                </Button>
            </Box>
        </>
    )
}

export default Faq
