import { useCallback, useContext, useEffect, useState } from 'react'

import { get, post, setAuthHeader } from '../utils/fetchApis'
import { getStorage, setStorage } from '../utils/helper'

function useUser(token) {
    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    // const { setAllNfts, setAlertState } = useContext(AppContext);

    const getUser = useCallback(async () => {
        setIsLoading(true)
        const { data, success, message } = await post('auth/me')

        if (!success) {
            if (message?.toLowerCase() === 'expired') {
                const token = getStorage('refresh_token')
                setAuthHeader(token)

                const refreshTokenRes = await get('auth/refresh_token', {
                    token,
                })
                setStorage('token', refreshTokenRes.data.access_token)
                setStorage('refresh_token', refreshTokenRes.data.refresh_token)
                setAuthHeader(refreshTokenRes.data.access_token)
                refetch()
            }
        }

        setUser(data)
        setStorage('user', data)
        setIsLoading(false)
    }, [])

    useEffect(() => {
        if (token) {
            getUser()
        }
    }, [token])

    const refetch = () => {
        getUser()
    }

    return { user, setUser, isLoading }
}

export default useUser
