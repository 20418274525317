/* eslint-disable quotes */

import Guide1 from '../assets/1.jpeg'
import Guide2 from '../assets/2.jpeg'
import Guide3 from '../assets/3.jpg'
import Guide4 from '../assets/4.jpeg'
import Guide5 from '../assets/5.jpeg'
import Guide6 from '../assets/6.jpeg'
import useDocumentTitle from '../useDocumentTitle'
import { Box, Container, Typography } from '@mui/material'
// import Guide1 from "../assets/count1.png";

const data = [
    {
        content:
            'Go to the marketplace and select the NFT that you would like to purchase',
        imgSrc: Guide1,
    },
    {
        content:
            'Press the buy now button and you will be taken to the individual NFT page',
        imgSrc: Guide2,
    },
    {
        content:
            'Make the payment for the NFT with Tether on the polygon network',
        imgSrc: Guide3,
    },
    {
        content:
            'The NFT will appear in the your wallet and can be used to view content',
        imgSrc: Guide4,
    },
    {
        content:
            'To view content select the link button and your crypto browser will allow access',
        imgSrc: Guide5,
    },
    {
        content: 'You can now view the content linked to your NFT',
        imgSrc: Guide6,
    },
]

const Guide = () => {
    useDocumentTitle('Sniser Guideline')

    return (
        <>
            <Container>
                {data.map((item, parentIndex) => (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '2px',
                                mt: '20px',
                            }}
                        >
                            <Box
                                component={'span'}
                                sx={{ color: '#6fda44', fontSize: '20px' }}
                            >{`${parentIndex + 1}. `}</Box>
                            <Typography
                                component={'p'}
                                sx={{
                                    color: '#2a3539',
                                    fontSize: '24px',
                                    fontWeight: '400 !important',
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: item.content,
                                }}
                            />
                        </Box>

                        {item.imgSrc && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <img
                                    src={item.imgSrc}
                                    alt=""
                                    style={{
                                        marginBottom: '48px',
                                        height: '360px',
                                        width: '320px',
                                        maxWidth: '100%',
                                    }}
                                />
                            </div>
                        )}
                    </>
                ))}
            </Container>
        </>
    )
}

export default Guide
