/* eslint-disable camelcase */
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    Stack,
    SvgIcon,
    Typography,
    styled,
} from '@mui/material'
import { ethers } from 'ethers'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import { useAccount } from 'wagmi'
import { AppContext } from '../../Context'
import Ticon from '../../assets/ticon-circle.png'
import Loading from '../../connectivityAssets/Loading'
import { marketplaceContractAddr } from '../../connectivityAssets/mintContract/addresses'
import { LISTING, NFT_STATUS, ORDER_STATUS } from '../../utils/constant'
import NftItemCard from '../NftItemCard'
import MediaItemCard from '../MediaItemCard'
import { WalletConnectAlertModal, ListStatModal } from '../Modal'

import {
    approveNFT,
    approveUSDC,
    buyNFT,
    cancelItemForSale,
    getAllowanceUSDC,
    getNewOrderId,
    getUSDCBalance,
    putItemForSale,
    getApprovedNFT,
    updateItemForSale,
} from '../../utils/contract'
import { post, get, update } from '../../utils/fetchApis'
import ConnectionModal from '../Modal/ConnectModal'
import useNft from '../../hooks/useNFT'
import { getEthersSigner } from '../../utils/ether'
import { config } from '../../utils/wagiConfig'
import { GC_BUCKET_URL, FILE_TYPE } from '../../utils/constant'
import { isAxiosError } from 'axios'
import MyButton from '../Buttonone'
import { audioSVG, shareSVG, videoSVG, favoriteSVG } from '../SVG'

const StyledLink = styled(Typography)({
    fontWeight: 700,
    transition: '0.4s',
    cursor: 'pointer',
    borderBottom: 'none !important',
    textDecoration: 'none !important',
})

const Social = styled(Button)({
    background: '#fff',
    border: '1px solid #666',
    borderRadius: ' 10px',
})

const LoadMore = styled(Button)({
    background: '#2A3538',
    color: 'white',
    borderRadius: ' 6px',
    padding: '4px 16px',
    width: '200px',
    ':hover': {
        backgroundColor: '#2A3538',
    },
})

const NFTDetail = () => {
    const params = useParams()
    const videoRef = useRef()
    const navigate = useNavigate()
    const { isConnected, address } = useAccount()
    const {
        isMobile,
        wallet,
        connectModal,
        setAlertModal,
        setAlertState,
        toggleConnectModal,
    } = useContext(AppContext)

    const [pagination, setPagination] = useState({
        page: 1,
        size: 4,
    })
    const [status, setStatus] = useState(LISTING.START)
    const [priceModalOpen, setPriceModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [viewPreview, setViewPreview] = useState(false)
    const [user, setUser] = useState()

    const { nft, execute, isLoading } = useNft(params?.id)

    const breadCrumbs = [
        { label: 'Home', url: '/' },
        { label: 'Account', url: '/myaccount' },
        { label: nft?.title, active: true },
    ]

    const toggelModal = () => {
        setPriceModalOpen(!priceModalOpen)
    }

    useEffect(() => {
        videoRef.current?.load()
        if (nft.wallet?.userWallets?.length) {
            setUser(nft.wallet.userWallets[0].user)
        }
    }, [nft])

    const completeList = async (price) => {
        try {
            const signer = await getEthersSigner(config)
            setStatus(LISTING.APPROVING)

            const approved = await getApprovedNFT(nft.tokenId)
            if (approved !== marketplaceContractAddr) {
                await approveNFT(signer, nft.tokenId)
            }

            await putItemForSale(
                signer,
                nft.tokenId,
                ethers.utils.parseUnits(price, 6)
            )

            const orderId = await getNewOrderId()

            const params = {
                marketplaceSaleId: parseInt(orderId) - 1,
                marketplaceAddress: marketplaceContractAddr,
                tokenId: nft.tokenId,
                price,
                status: ORDER_STATUS.SALE,
                walletId: wallet.id,
            }

            await post(`orders${nft.id}/create`, params)
            await execute()
            setStatus(LISTING.LIST_FINISH)
        } catch (e) {
            let message = 'something wrong happened'
            if (isAxiosError(e)) {
                message = e.response.data.message
            } else if (e.code) {
                message = e.reason ?? e.message
            }

            setAlertState({
                open: true,
                message,
                severity: 'error',
            })

            setPriceModalOpen(!priceModalOpen)
        }
    }

    const updateList = async (price) => {
        try {
            const signer = await getEthersSigner(config)
            setStatus(LISTING.APPROVING)

            await updateItemForSale(
                signer,
                nft.order.marketplaceSaleId,
                ethers.utils.parseUnits(price, 6)
            )
            const params = {
                price,
            }

            await post(`orders/${nft.order.id}`, params)
            await execute()

            setStatus(LISTING.EDIT_FINISH)
        } catch (e) {
            let message = 'something wrong happened'
            if (isAxiosError(e)) {
                message = e.response.data.message
            } else if (e.code) {
                message = e.reason ?? e.message
            }

            setAlertState({
                open: true,
                message,
                severity: 'error',
            })
            setPriceModalOpen(!priceModalOpen)
        }
    }

    const cancelList = async (nft) => {
        try {
            const signer = await getEthersSigner(config)
            setStatus(LISTING.CANCELING)
            const order = nft.orders[0]

            await cancelItemForSale(signer, order.marketplaceSaleId)
            const params = {
                status: ORDER_STATUS.CANCEL,
            }

            await update(`orders/${order.id}`, params)
            await execute()

            setPriceModalOpen(false)
        } catch (e) {
            let message = 'Something wrong happened'
            if (isAxiosError(e)) {
                message = e.response.data.message
            } else if (e.code) {
                message = e.reason ?? e.message
            }

            setAlertState({
                open: true,
                message,
                severity: 'error',
            })
            setPriceModalOpen(!priceModalOpen)
        }
    }

    //====================== buy transaction=========================
    const buyHandler = async () => {
        try {
            setLoading(true)
            const signer = await getEthersSigner(config)
            const nftPrice = ethers.utils.parseUnits(nft.price.toString(), 6)
            const usdcBalance = await getUSDCBalance(signer)

            // console.log(parseInt(nftPrice), parseInt(usdcBalance))
            if (parseInt(nftPrice) > parseInt(usdcBalance)) {
                setLoading(false)
                setAlertState({
                    open: true,
                    message: 'Low USDT Balance',
                    severity: 'error',
                })
                return
            }

            const allowance = await getAllowanceUSDC(signer)

            if (nftPrice.gte(allowance)) {
                await approveUSDC(signer, nftPrice)
            }

            await buyNFT(signer, nft.order.marketplaceSaleId, nftPrice)
            const params = {
                status: ORDER_STATUS.SOLD,
                walletId: wallet?.id,
                marketplaceAddress: marketplaceContractAddr,
            }

            await update(`orders/${nft.order.id}`, params)
            await execute()

            setLoading(false)

            setAlertState({
                open: true,
                message: 'Purchased NFT successfully!',
            })
        } catch (e) {
            let message = 'Something wrong happened'
            if (isAxiosError(e)) {
                message = e.response.data.message
            } else if (e.code) {
                message = e.reason ?? e.message
            }

            setAlertState({
                open: true,
                message,
                severity: 'error',
            })
            setLoading(false)
        }
    }

    const onPurchase = () => {
        if (!isConnected) {
            console.log('on purchase', isConnected)
            setAlertModal(true)
            return
        }

        if (nft.walletId !== wallet?.id) {
            buyHandler()
        } else {
            setStatus(
                nft.status === NFT_STATUS.SALE ? LISTING.EDIT : LISTING.START
            )
            setPriceModalOpen(true)
        }
    }

    const onViewContent = () => {
        navigate(`/view-content/${nft.id}`)
    }

    const handleList = async () => {
        setStatus(nft.status === NFT_STATUS.SALE ? LISTING.EDIT : LISTING.START)
        setPriceModalOpen(true)
    }

    const BreadCrumb = () => (
        <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            backgroundColor="#fff"
            style={{
                zIndex: '1000px',
            }}
            height="80px"
            width="100%"
        >
            {priceModalOpen && (
                <ListStatModal
                    status={status}
                    nft={nft}
                    priceModalOpen={priceModalOpen}
                    completeList={(price) => completeList(price)}
                    updateList={(price) => updateList(price)}
                    cancelList={cancelList}
                    toggelModal={toggelModal}
                />
            )}
            <Container maxWidth="lg">
                <Stack
                    direction="row"
                    justifyContent="cneter"
                    alignItems="center"
                >
                    {breadCrumbs.map((it, idx) => (
                        <Stack direction="row" alignItems="center">
                            <Link to={it.url}>
                                <Typography
                                    mx={{ md: 3, xs: 1 }}
                                    py={2}
                                    fontSize={{ md: '16px', xs: '12px' }}
                                    sx={{
                                        fontWeight: it.active ? 600 : 400,
                                        color: 'black',
                                    }}
                                >
                                    {it.label}
                                </Typography>
                            </Link>
                            {idx !== breadCrumbs.length - 1 && (
                                <ArrowForwardIosIcon sx={{ width: '16px' }} />
                            )}
                        </Stack>
                    ))}
                </Stack>
            </Container>
        </Box>
    )

    // console.log('nft detail', viewPreview)
    return (
        <>
            <WalletConnectAlertModal></WalletConnectAlertModal>
            <BreadCrumb />
            <Box>
                {loading ||
                    (isLoading && <Loading isLoading={loading || isLoading} />)}
                <>
                    {priceModalOpen && (
                        <ListStatModal
                            status={status}
                            nft={nft}
                            priceModalOpen={priceModalOpen}
                            toggelModal={toggelModal}
                            completeList={(price) => completeList(price)}
                            updateList={(price) => updateList(price)}
                            cancelList={cancelList}
                        />
                    )}
                    <ConnectionModal
                        connectModal={connectModal}
                        toggleConnectModal={toggleConnectModal}
                    />
                    <Box sx={{ backgroundColor: '#fff' }}>
                        <Container maxWidth="lg">
                            <Grid container my={{ md: 10 }}>
                                <Grid item md={6} xs={12}>
                                    {!viewPreview ? (
                                        <img
                                            src={`${GC_BUCKET_URL}${nft?.image}`}
                                            alt="nft"
                                            style={{
                                                borderRadius: '8px',
                                                width: '100%',
                                                height: isMobile
                                                    ? '350px'
                                                    : '500px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={(_) =>
                                                setViewPreview(true)
                                            }
                                        />
                                    ) : (
                                        <MediaItemCard
                                            preview={true}
                                            nft={nft}
                                        />
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    pl={{ md: 5 }}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Stack alignItems="center" direction="row">
                                        <Typography variant="h1">
                                            {nft.title}
                                        </Typography>
                                        {nft.price && (
                                            <Stack
                                                alignItems="center"
                                                direction="row"
                                                ml="auto"
                                            >
                                                <img
                                                    src={Ticon}
                                                    width="24px"
                                                    alt="seen icon"
                                                />
                                                <Typography
                                                    variant="h5"
                                                    ml="6px"
                                                >{`${nft.price} USDT`}</Typography>
                                            </Stack>
                                        )}
                                    </Stack>

                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={1}
                                        mt={2}
                                    >
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            sx={{ border: '1px solid #999' }}
                                            p={1}
                                        >
                                            <SvgIcon sx={{ width: '14px' }}>
                                                {nft.fileType === 'audio'
                                                    ? audioSVG()
                                                    : videoSVG()}
                                            </SvgIcon>
                                            <Typography
                                                variant="body2"
                                                ml={1}
                                                sx={{
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {nft.fileType}
                                            </Typography>
                                        </Stack>
                                        <Box
                                            sx={{ border: '1px solid #999' }}
                                            p={1}
                                        >
                                            <Typography variant="body2">
                                                # {nft.id}
                                            </Typography>
                                        </Box>
                                    </Stack>

                                    <Typography variant="subtitle1" mt={6}>
                                        Description
                                    </Typography>

                                    <Typography mt={1} variant="body2">
                                        {nft.description}
                                    </Typography>

                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        mt={4}
                                    >
                                        <Typography variant="subtitle1" mr={2}>
                                            Tags :
                                        </Typography>
                                        {nft.tags?.map((t) => (
                                            <Box
                                                sx={{
                                                    border: '1px solid #999',
                                                }}
                                                p={1}
                                                mr={1}
                                            >
                                                <Typography variant="body2">
                                                    # {t}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Stack>

                                    <Stack
                                        mt="auto"
                                        alignItems="center"
                                        direction="row"
                                    >
                                        {nft?.creator && (
                                            <Stack
                                                alignItems="center"
                                                direction="row"
                                            >
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={`${GC_BUCKET_URL}${nft?.creator?.photo}`}
                                                    sx={{
                                                        width: 50,
                                                        height: 50,
                                                    }}
                                                />
                                                <Box ml={1}>
                                                    <Typography variant="subtitle1">
                                                        Creator
                                                    </Typography>
                                                    <Typography variant="body2">{`${nft?.creator?.firstName} ${nft?.creator?.lastName}`}</Typography>
                                                </Box>
                                            </Stack>
                                        )}

                                        {user && (
                                            <Stack
                                                alignItems="center"
                                                direction="row"
                                                ml={4}
                                            >
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={`${GC_BUCKET_URL}${user?.photo}`}
                                                    sx={{
                                                        width: 50,
                                                        height: 50,
                                                    }}
                                                />
                                                <Box ml={1}>
                                                    <Typography variant="subtitle1">
                                                        Owner
                                                    </Typography>
                                                    <Typography variant="body2">{`${user?.firstName} ${user?.lastName}`}</Typography>
                                                </Box>
                                            </Stack>
                                        )}
                                    </Stack>

                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        mt={3}
                                        spacing={3}
                                    >
                                        <MyButton
                                            my={2}
                                            fontSize={{
                                                xs: '13px',
                                                md: '18px',
                                            }}
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: '#6FDA44',
                                                },
                                                color: 'white',
                                                width: '300px',
                                            }}
                                            onClick={(_) => {
                                                if (!isConnected) {
                                                    toggleConnectModal(true)
                                                } else {
                                                    if (
                                                        nft.walletId ===
                                                        wallet?.id
                                                    ) {
                                                        handleList(nft)
                                                    }
                                                }
                                            }}
                                        >
                                            {nft.status === NFT_STATUS.SALE
                                                ? 'Edit list'
                                                : 'List for sale'}
                                        </MyButton>
                                        <MyButton
                                            sx={{ width: '300px' }}
                                            onClick={(_) => {
                                                if (!isConnected) {
                                                    toggleConnectModal(true)
                                                } else {
                                                    onPurchase()
                                                }
                                            }}
                                        >
                                            {address === nft.wallet?.address
                                                ? 'View Content'
                                                : 'Purchase Now'}
                                        </MyButton>
                                        {/* <Social sx={{ml: 'auto'}}><SvgIcon sx={{width: '16px'}}>{favoriteSVG()}</SvgIcon></Social>
                          <Social sx={{ml: '8px'}} onClick={onShare}><SvgIcon sx={{width: '16px'}}>{shareSVG()}</SvgIcon></Social> */}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </>
            </Box>
        </>
    )
}

export default NFTDetail
