import { ethers } from 'ethers'

import {
    marketplaceContractAddr,
    mintContractAddr,
    USDCContractAddr,
} from '../connectivityAssets/mintContract/addresses'
import marketplaceABI from '../connectivityAssets/mintContract/marketplaceABI.json'
import mintABI from '../connectivityAssets/mintContract/mintABI.json'
import USDCABI from '../connectivityAssets/mintContract/tokenAbi.json'
import { CREATER_ROYALTY, DEV_MODE, RPC } from './constant'
import { waitForTx } from './helper'

let mintContract = new ethers.Contract(
    mintContractAddr,
    mintABI,
    new ethers.providers.JsonRpcProvider(!DEV_MODE ? RPC.POLYGON : RPC.AMOY)
)
let marketplaceContract = new ethers.Contract(
    marketplaceContractAddr,
    marketplaceABI,
    new ethers.providers.JsonRpcProvider(!DEV_MODE ? RPC.POLYGON : RPC.AMOY)
)
let usdcContract = new ethers.Contract(
    USDCContractAddr,
    USDCABI,
    new ethers.providers.JsonRpcProvider(!DEV_MODE ? RPC.POLYGON : RPC.AMOY)
)

// get USDC balance
export const getUSDCBalance = async (signer) => {
    return await usdcContract.balanceOf(await signer.getAddress())
}

// get allowed balance
export const getAllowanceUSDC = async (signer) => {
    return await usdcContract.allowance(
        await signer.getAddress(),
        marketplaceContractAddr
    )
}

// get allowance nft
export const getApprovedNFT = async (token_id) => {
    return await mintContract.getApproved(token_id)
}

// get token id
export const getTokenId = async () => {
    return await mintContract.tokenId()
}

// get nft owner
export const getNFTOwner = async (nft_id) => {
    return await mintContract.ownerOf(nft_id)
}

// get order id
export const getNewOrderId = async () => {
    return await marketplaceContract.getNewItemId()
}

// mint new nft
export const mintNFT = async (signer, uris) => {
    const mintContractR = new ethers.Contract(mintContractAddr, mintABI, signer)
    const { hash } = await mintContractR.mintWithRoyalty(uris, CREATER_ROYALTY)
    await waitForTx(signer.provider, hash)

    return hash
}

// approve marketplace contract to transer usdc
export const approveUSDC = async (signer, amount) => {
    const usdcContractR = new ethers.Contract(USDCContractAddr, USDCABI, signer)
    const { hash } = await usdcContractR.approve(
        marketplaceContractAddr,
        amount
    )
    await waitForTx(signer.provider, hash)

    return hash
}

// approve marketplace contract to transfer nft
export const approveNFT = async (signer, nft_id) => {
    const mintContractR = new ethers.Contract(mintContractAddr, mintABI, signer)
    const { hash } = await mintContractR.approve(
        marketplaceContractAddr,
        nft_id
    )
    await waitForTx(signer.provider, hash)

    return hash
}

// list nft to marketplace
export const putItemForSale = async (signer, nft_id, price) => {
    const marketplaceContractR = new ethers.Contract(
        marketplaceContractAddr,
        marketplaceABI,
        signer
    )
    const { hash } = await marketplaceContractR.putItemForSale(
        await signer.getAddress(),
        mintContractAddr,
        nft_id,
        price
    )
    await waitForTx(signer.provider, hash)

    return hash
}

// edit list to update price
export const updateItemForSale = async (signer, id, price) => {
    const marketplaceContractR = new ethers.Contract(
        marketplaceContractAddr,
        marketplaceABI,
        signer
    )
    const { hash } = await marketplaceContractR.updateItemForSale(
        await signer.getAddress(),
        id,
        price
    )
    await waitForTx(signer.provider, hash)

    return hash
}

// cancel list
export const cancelItemForSale = async (signer, id) => {
    const marketplaceContractR = new ethers.Contract(
        marketplaceContractAddr,
        marketplaceABI,
        signer
    )
    const { hash } = await marketplaceContractR.cancelItemForSale(
        await signer.getAddress(),
        id
    )
    await waitForTx(signer.provider, hash)

    return hash
}

// buy nft
export const buyNFT = async (signer, order_id, price) => {
    const marketplaceContractR = new ethers.Contract(
        marketplaceContractAddr,
        marketplaceABI,
        signer
    )
    const { hash } = await marketplaceContractR.buyItem(
        await signer.getAddress(),
        order_id,
        price
    )
    await waitForTx(signer.provider, hash)

    return hash
}

// get orders of owner
export const getOrdersByOwner = async (address) => {
    return await marketplaceContract.getItemsBySeller(address)
}

// get all orders
export const allOrders = async () => {
    return await marketplaceContract.itemsForSale()
}
