import { Box, Button, Grid, TextField, Typography, styled } from '@mui/material'
import React, { useMemo } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Controller, useFormContext } from 'react-hook-form'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import ProgressBar from './ProgressBar'

export const inputStyle = {
    flex: 1,
    Input: {
        padding: '10px 14px',
        borderRadius: '8px',
        '&::placeholder': {
            textOverflow: 'ellipsis !important',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '21px',
            color: '#2A3538',
        },
    },
    backgroundColor: '#fff',
}

export const StyledLabel = styled(Typography)(() => ({
    color: '#2A3538',
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 700,
    fontSize: '14px',
}))

function PersonalInformation({ setActiveStep }) {
    const { control, watch } = useFormContext()

    const firstName = watch('firstName')
    const lastName = watch('lastName')
    const email = watch('email')

    const isActive = useMemo(() => {
        return !!firstName && !!lastName && !!email
    }, [email, firstName, lastName])

    return (
        <Box sx={{ background: '#fff', p: '32px', borderRadius: '12px' }}>
            <ProgressBar progress={isActive ? 33 : 0} />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    mb: '28px',
                    mt: '24px',
                }}
            >
                <InfoOutlinedIcon />
                <Typography
                    sx={{
                        fontSize: '12px',
                        color: '#2A3538',
                        fontWeight: '400 !important',
                    }}
                >
                    This information will enable us to inform you once your
                    content is uploaded to our site.
                </Typography>
            </Box>
            <Grid container spacing={5}>
                <Grid item xs={12} md={6}>
                    <StyledLabel mb={'6px'}>First Name</StyledLabel>
                    <Controller
                        control={control}
                        name="firstName"
                        rules={{
                            required: {
                                value: true,
                                message: 'This field is required',
                            },
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error },
                        }) => (
                            <TextField
                                sx={inputStyle}
                                placeholder="Your First Name"
                                error={error}
                                value={value}
                                size="small"
                                fullWidth
                                onChange={onChange}
                                helperText={error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <StyledLabel mb={'6px'}>Last Name</StyledLabel>
                    <Controller
                        control={control}
                        name="lastName"
                        rules={{
                            required: {
                                value: true,
                                message: 'This field is required',
                            },
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error },
                        }) => (
                            <TextField
                                sx={inputStyle}
                                placeholder="Your Last Name"
                                error={error}
                                value={value}
                                size="small"
                                fullWidth
                                onChange={onChange}
                                helperText={error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: '24px !important' }}>
                    <StyledLabel mb={'6px'}>Email</StyledLabel>
                    <Controller
                        control={control}
                        name="email"
                        rules={{
                            required: {
                                value: true,
                                message: 'This field is required',
                            },
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error },
                        }) => (
                            <TextField
                                sx={inputStyle}
                                placeholder="yourmail@example.com"
                                error={error}
                                value={value}
                                size="small"
                                fullWidth
                                onChange={onChange}
                                helperText={error?.message}
                            />
                        )}
                    />
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                        mt: '24px',
                    }}
                >
                    <Button
                        disabled={!isActive}
                        sx={{
                            borderRadius: '8px',
                            background: isActive ? '#6FDA44' : '#2A353833',
                            padding: '10px 32px',
                            fontWeight: '700',
                            color: isActive ? '#2A3538' : '#2A353866',
                            textTransform: 'capitalize',
                        }}
                        onClick={setActiveStep}
                    >
                        Next <ArrowRightAltIcon />
                    </Button>
                </Box>
            </Grid>
        </Box>
    )
}

export default PersonalInformation
