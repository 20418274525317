import { useCallback, useContext, useEffect, useState } from 'react'

import { AppContext } from '../Context'
import { get } from '../utils/fetchApis'

function usePlans({ pagination }) {
    const [loading, setLoading] = useState(false)
    const [plans, setPlans] = useState([])
    const [count, setCount] = useState([])
    const { setAlertState } = useContext(AppContext)
    const { page, size, search } = pagination

    const getPlans = useCallback(async () => {
        try {
            setLoading(true)
            const { success, data, message } = await get('subscription/plans', {
                page,
                size,
                search,
            })

            if (!success) {
                setAlertState({
                    open: true,
                    message,
                    severity: 'error',
                })
            }

            setCount(data.totalResults)
            setPlans(data.items)
        } catch (error) {
            console.log(error)
        }
    }, [pagination])

    const refetch = () => {
        getPlans()
    }

    useEffect(() => {
        getPlans()
    }, [pagination])

    return { plans, count, refetch, loading }
}

export default usePlans
