import './index.css'

import { CssBaseline, ThemeProvider } from '@mui/material'
// import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { hydrate, render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { WagmiProvider } from 'wagmi'

import App from './App'
import ScrollToTop from './components/ScrollToTop'
import { AppContextProvider } from './Context'
import { ModalProvider } from './modal-context'
import theme from './theme'
import { config } from './utils/wagiConfig'

// const { chains, provider } = configureChains(
//   [polygonMumbai, polygon],
//   [
//     publicProvider(),
//     jsonRpcProvider({
//       rpc: (chain) => ({
//         http: !DEV_MODE ? RPC.POLYGON! : RPC.MUMBAI!,
//       }),
//     }),
//   ]
// );

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: 1_000 * 60 * 60 * 24, // 24 hours
        },
    },
})

const root = document.getElementById('root') as HTMLElement
if (root?.hasChildNodes()) {
    hydrate(
        <React.StrictMode>
            <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                    {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID!}> */}
                    <AppContextProvider>
                        <BrowserRouter>
                            <ModalProvider>
                                <ThemeProvider theme={theme}>
                                    <CssBaseline />

                                    <App />
                                    <ScrollToTop />
                                </ThemeProvider>
                            </ModalProvider>
                        </BrowserRouter>
                    </AppContextProvider>
                    {/* </GoogleOAuthProvider> */}
                </QueryClientProvider>
            </WagmiProvider>
        </React.StrictMode>,
        root
    )
} else {
    render(
        <React.StrictMode>
            {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID!}> */}
            <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                    <AppContextProvider>
                        <BrowserRouter>
                            <ModalProvider>
                                <ThemeProvider theme={theme}>
                                    <CssBaseline />

                                    <App />
                                    <ScrollToTop />
                                </ThemeProvider>
                            </ModalProvider>
                        </BrowserRouter>
                    </AppContextProvider>
                </QueryClientProvider>
            </WagmiProvider>
            {/* </GoogleOAuthProvider> */}
        </React.StrictMode>,
        root
    )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
